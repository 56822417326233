import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { Box } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import assets from '../../../assets';
import colorConfigs from '../../../configs/colorConfigs';

interface ResultDeviceData {
  [key: string]: number
}

interface ResultSourceRadialGraphProps {
  data: ResultDeviceData
}


const ResultSourceRadialGraph: React.FC<ResultSourceRadialGraphProps> = ({ data }) => {
  // Identify the "reproccessed" key (case-insensitive)
  const reprocessedKey = Object.keys(data).find(
    (key) => key.toLowerCase() === 'reproccessed'
  )
  const reprocessedValue = reprocessedKey ? data[reprocessedKey] : 0

  // Get the remaining device keys
  const deviceKeys = Object.keys(data)
    .filter((key) => key.toLowerCase() !== 'reproccessed')
    .sort((a, b) => a.localeCompare(b))

  // Build final labels and series arrays: device slices first, then Reprocessed slice.
  const labels = [...deviceKeys, 'Reprocessed']
  const series = [...deviceKeys.map(key => data[key]), reprocessedValue]

  const options: ApexOptions = {
    chart: {
      type: 'pie',
      // width: 400,
      height: 350,
      toolbar: { show: false },
    },
    labels: labels,
    // You can adjust colors as needed; if more slices than colors, ApexCharts will cycle them.
    colors: [
      colorConfigs.buttons.bgInactiveSolid,
      colorConfigs.dashboard.dark_red,
      colorConfigs.dashboard.mid_grey,
      // Optionally add more colors for additional devices
    ],
    dataLabels: {
      enabled: true,
      style: { colors: ['#111'] },
      formatter: (val: number, opts) => {
        // Here opts.w.config.series is our series array.
        return opts.w.config.series[opts.seriesIndex] + ' results'
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        borderWidth: 0,
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -30,
        },
      },
    },
    stroke: {
      colors: ['#c0c0c0'],
    },
    legend: {
      labels: {
        colors: [
          colorConfigs.dashboard.dark_grey,
          colorConfigs.dashboard.dark_grey,
          colorConfigs.dashboard.dark_grey,
        ],
        useSeriesColors: false,
      },
    },
    tooltip: {
      y: {
        formatter: (val: any) => {
          return `${val} results`
        },
      },
    },
  }

  return (
    <Box data-testid="Box-5acv" sx={{}}>
      <div data-testid="div-3xzw" id="chart">
        <ReactApexChart
          type="pie"
          options={options}
          series={series}
          height={options.chart?.height}
          width={options.chart?.width}
        />
      </div>
      <div data-testid="div-hmuf" id="html-dist"></div>
    </Box>
  )
}

export default ResultSourceRadialGraph