import React, { useEffect, useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import Popup from "./Popup";
import ImageSelectorCard from "./ImageSelectorCard";
import RareButton from "../buttons/RareButton";
import { ImageItem } from "../../../types/types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../redux/features/auth/axiosAuthHelper";
import ResultsTableForMethods from "../tables/ResultsTableForMethods";
import ResultDynamicFilterBar from "../filterbars/ResultDynamicFilterBar";

interface ResultSelectorCardProps {
  onAccept?: (selectedImage: ImageItem | null) => void;
  dataset_id?: any;
  housing_id?: any;
}

const ResultSelectorCard: React.FC<ResultSelectorCardProps> = ({
  onAccept,
  dataset_id,
  housing_id,
}) => {

  // State for the first popup (choose result)
  const [resultListPopupOpen, setResultListPopupOpen] = useState(false);
  // State for the second popup (image selector)
  const [imageGridPopupOpen, setImageGridPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<ImageItem | undefined>(undefined);

  const openResultListPopup = () => setResultListPopupOpen(true);
  const closeResultListPopup = () => setResultListPopupOpen(false);

  const openResultPopup = () => {
    // Close the first popup and open the second one
    closeResultListPopup();
    setImageGridPopupOpen(true);
  };
  const closeResultPopup = () => setImageGridPopupOpen(false);

  const handleAccept = () => {
    if (selectedImage) {
      if (onAccept) {
        onAccept(selectedImage);
      }
      closeResultListPopup()
      closeResultPopup();
    }
  };

  const handleCancel = () => {
    closeResultPopup();
    openResultListPopup();
  };

  const handleResultRowClick = (row: any) => {
    setResult(row)
    setImageGridPopupOpen(true)
  }


  const location = useLocation();
  const navigate = useNavigate();
  const authData = useSelector((state: RootState) => state.auth);
  const [result, setResult] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [totalCountLoaded, setTotalCountLoaded] = useState<any>(0);
  const [noResultsForQuery, setNoResultsForQuery] = useState<any>(false);
  const [rows, setRows] = useState<any>([]);
  const [queryString, setQueryString] = useState<any>("");

  const handleRefreshButton = () => {
    setResult("")
    fetchData(queryString)
  }

  const fetchData = async (query = "") => {
    setIsLoading(true);
    const data: any[] = [];
    let downloadPageSize = 250;  // Set the batch size to 250
    let downloadOffset = 0;
    let total = 0;

    try {
      // Extract the limit from the query if it exists
      const urlParams = new URLSearchParams(query);
      const limitParam = urlParams.get('limit');

      // If the limit parameter exists and is a valid number, set the downloadPageSize
      if (limitParam && !isNaN(Number(limitParam))) {
        downloadPageSize = Number(limitParam);
      }

      let url = `results/results_with_images?${query}&skip=${downloadOffset}`;
      if (!urlParams.has('limit')) {
        url += `&limit=${downloadPageSize}`;
      }
      if (dataset_id) {
        url += `&dataset_id=${dataset_id}`;
      } else if (housing_id && !dataset_id) {
        url += `&housing_id=${housing_id}`;
      }
      console.log("URL SEANNN", url)
      // Fetch the first batch to get the total count
      const initialResponse = await api.get(url, {
        headers: {
          'Authorization': `Bearer ${authData.access}`
        }
      });
      if (initialResponse.data.results.length < 1) {
        console.log("No results for this query")
        setIsLoading(false);
        setNoResultsForQuery(true)
        setRows([]);
        return data
      } else {
        data.push(...initialResponse.data.results);
        if (limitParam && !isNaN(Number(limitParam))) {
          total = initialResponse.data.results.length
        } else {
          total = initialResponse.data.count;
        }
        downloadOffset += downloadPageSize;
        setTotalCount(total);
        setTotalCountLoaded(data.length);
        setIsLoading(false);
        setRows([...data]);
        setQueryString(query)
      }
      // Now fetch the remaining lots in the background
      while (data.length < total && !limitParam) {
        let url = query ? `results/results_with_images?${query}&limit=${downloadPageSize}&skip=${downloadOffset}` : `results/results_with_images?limit=${downloadPageSize}&skip=${downloadOffset}`
        if (dataset_id) {
          url += `&dataset_id=${dataset_id}`;
        } else if (housing_id && !dataset_id) {
          url += `&housing_id=${housing_id}`;
        }
        console.log("URL SEANNN2", url)
        const response = await api.get(url, {
          headers: {
            'Authorization': `Bearer ${authData.access}`
          }
        });

        data.push(...response.data.results);
        downloadOffset += downloadPageSize;
        setTotalCountLoaded(data.length);
        console.log(`Downloaded ${data.length} of ${total} results`);
      }

      // Final update after all lots are fetched
      setRows([...data]);
      console.log('Downloaded all results:', data);

      setTimeout(() => {
        setTotalCount(false)
      }, 6000);

    } catch (error) {
      console.error('Error fetching results:', error);
    } finally {
      setIsLoading(false);
      return data
    };
  }

  useEffect(() => {
    fetchData();
  }, [housing_id, dataset_id, authData.isAuthenticated]);


  return (
    <>
      <Tooltip title={housing_id ? "" : "Please Select a Housing"}>
        <Box>
          <RareButton
            data-testid="RareButton-wxbh"
            disabled={!housing_id && !dataset_id}
            variant="contained"
            onClick={openResultListPopup}
            sx={{}}
          >
            Select Result
          </RareButton>
        </Box>
      </Tooltip>

      {/* First Popup: Choose A Result */}
      {resultListPopupOpen && (
        <Popup
          isOpen={resultListPopupOpen}
          closeDialog={closeResultListPopup}
          width="lg"
          title={dataset_id ? "Select Result from Dataset" : "Select a Result"}
          actions={
            <>
              <RareButton
                data-testid="RareButton-cancel"
                onClick={closeResultListPopup}
                variant="outlined"
                sx={{ margin: "10px" }}
              >
                Cancel
              </RareButton>
            </>
          }
        >
          <Box sx={{ m: 0, p: 2, transition: 'padding-top 0.2s ease', height: "100%", overflowY: "auto", marginRight: 0, display: 'flex', flexDirection: 'column', overflowX: "hidden", maxWidth: `93vw`, minWidth: `100%` }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', boxShadow: 5, borderTopRightRadius: 3, borderTopLeftRadius: 3 }}>
              <Box sx={{
                borderRadius: 1,
                height: 'calc(100vh - 225px)',
                display: 'flex',
                flexDirection: 'column',
              }}>
                {!dataset_id && <ResultDynamicFilterBar
                  setIsLoading={setIsLoading}
                  handleRemoveAllFilters={() => { }}
                  fetchData={fetchData}
                  totalCount={totalCount}
                  totalCountLoaded={totalCountLoaded}
                />}
                <Box sx={{
                  flex: 1,
                  // maxHeight: 'calc(100vh - 295px)',
                  minHeight: 'calc(100vh - 295px)',
                  display: 'flex',
                  flexDirection: 'column',
                  borderTopRightRadius: 50,
                  borderTopLeftRadius: 0,
                  border: 'none',
                  boxShadow: 0,

                }}>
                  <ResultsTableForMethods
                    authData={authData}
                    rows={rows}
                    isLoading={isLoading}
                    handleResultRowClick={handleResultRowClick}
                    handleRefreshButton={handleRefreshButton}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Popup>
      )}

      {/* Second Popup: Image Selector */}
      {imageGridPopupOpen && (
        <Popup
          isOpen={imageGridPopupOpen}
          closeDialog={closeResultPopup}
          width="md"
          title="Select Image"
          actions={
            <>
              <RareButton
                data-testid="RareButton-cancel"
                onClick={handleCancel}
                variant="outlined"
                sx={{ margin: "10px" }}
              >
                Back
              </RareButton>
              <RareButton
                data-testid="RareButton-accept"
                onClick={handleAccept}
                disabled={!selectedImage}
                variant="success"
                sx={{ margin: "10px" }}
              >
                Accept
              </RareButton>
            </>
          }
        >
          {/* <Typography variant="h6" align="center">
            Select and Image from Result
          </Typography> */}
          <ImageSelectorCard
            images={result?.images ? result?.images : []}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            onDoubleClick={handleAccept}
          />
        </Popup>
      )}
    </>
  );
};

export default ResultSelectorCard;
