import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, Typography, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled, Tooltip, useMediaQuery } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { FeedbackSessionStorageKey, FeedbackSessionStorageModel, FeedbackTableParameterStorageService } from "../../../services/sessionStorage";
import colorConfigs from "../../../configs/colorConfigs";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import RareButton from "../buttons/RareButton";

const StyledMenu = styled((props: any) => (
  <Menu data-testid="Menu-el92"
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


interface FeedbackTableProps {
  rows: any[];
  isLoading: boolean;
  sorting: any;
  columnVisibility: any;
  columnFilters: any;
  pagination: any;
  globalFilter: any;
  showGlobalFilter: any;
  setShowGlobalFilter: (value: any) => void;
  handleRemoveAllFilters: () => void;
  setGlobalFilter: (value: string) => void;
  setSorting: (sorting: any[]) => void;
  setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
  setColumnFilters: (filters: any[]) => void;
  setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
  openEditPage: (detail: any) => void;
  handleRefreshButton: () => void;
}

const FeedbackTable: React.FC<FeedbackTableProps> = ({
  rows,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  openEditPage,
  handleRefreshButton
}) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      Header: () => <div data-testid="div-7yny" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Feedback<br />ID
      </div>,
    },
    {
      accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      header: 'Date',
      Header: () => <div data-testid="div-jxwd" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Date<br />&nbsp;
      </div>,
      Cell: ({ cell }) => (
        <div data-testid="div-n3wd" style={{ whiteSpace: 'pre-wrap' }}>
          {cell.getValue()?.toString().split(' ').join('\n')}
        </div>
      ),
    },
    {
      accessorKey: 'user_id',
      header: 'User ID',
      Header: () => <div data-testid="div-h87h" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        User<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'reader_feedback',
      header: "Reader Feedback",
      Header: () => <div data-testid="div-2z05" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Reader<br />Feedback
      </div>,
    },
    {
      accessorKey: 'test_feedback',
      header: 'Test Feedback',
      Header: () => <div data-testid="div-vs85" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Test<br />Feedback
      </div>,
    },
    {
      accessorKey: 'device_model',
      header: 'Device Model',
      Header: () => <div data-testid="div-ikib" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Device<br />Model
      </div>,

    },
    {
      accessorKey: 'device_uuid',
      header: 'Device UUID',
      Header: () => <div data-testid="div-hci6" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Device<br />UUID
      </div>,

    },
    {
      accessorKey: 'lot_number',
      header: 'Lot No',
      Header: () => <div data-testid="div-c0pq" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Lot<br />No
      </div>,

    },
    {
      accessorKey: 'organisation_id',
      header: 'Organisation ID',
      Header: () => <div data-testid="div-zldl" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Organisation<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'app_version',
      header: 'App Version',
      Header: () => <div data-testid="div-9d9o" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        App<br />Version
      </div>,

    },
    {
      accessorKey: 'os_version',
      header: 'OS Version',
      Header: () => <div data-testid="div-wglq" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        OS<br />Version
      </div>,

    },
    {
      accessorKey: 'result_uuid',
      header: 'Result UUID',
      Header: () => <div data-testid="div-w8b0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Result<br />UUID
      </div>,

    },
    {
      accessorKey: 'test_type',
      header: 'Test Type',
      Header: () => <div data-testid="div-hqif" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Test<br />Type
      </div>,

    },
  ]


  // Define the type for the keys for handling persistance of table settings in session storage
  type ResultSessionStorageKeyType = keyof FeedbackSessionStorageModel;

  // Generic handler function for persisting table parameters
  const handleParameterChange = <T,>(
    key: ResultSessionStorageKeyType,
    currentValue: T,
    setValueFunction: (value: T) => void,
    newValue: T | ((prevValue: T) => T)
  ) => {
    const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
    FeedbackTableParameterStorageService.set(key, updatedValue);
    setValueFunction(updatedValue);
  };

  // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
  const setSortingFunction = (newSortingValue: any) =>
    handleParameterChange(FeedbackSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
  const setPaginationChangeFunction = (newPaginationValue: any) =>
    handleParameterChange(FeedbackSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
  const setFilterChangeFunction = (newFilterChangeValue: any) =>
    handleParameterChange(FeedbackSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
  const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
    handleParameterChange(FeedbackSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
  const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
    handleParameterChange(FeedbackSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);



  return <MaterialReactTable
    columns={columns}
    data={rows}
    state={{
      isLoading,
      sorting,
      columnFilters,
      pagination,
      globalFilter,
      showGlobalFilter
    }}
    onSortingChange={setSortingFunction}
    onColumnFiltersChange={setFilterChangeFunction}
    onPaginationChange={setPaginationChangeFunction}
    onGlobalFilterChange={setGlobalFilterChangeFunction}
    onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
    defaultColumn={{
      minSize: 10,
      maxSize: 160,
      size: 10,
    }}
    enableColumnOrdering={true}
    positionActionsColumn="last"
    enableRowSelection
    selectAllMode="all"
    // enableRowActions
    initialState={{
      sorting,
      columnVisibility,
      columnFilters,
      pagination,
      globalFilter,
      density: 'compact',
      columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions'] },
    }}

    // 
    displayColumnDefOptions={{
      'mrt-row-actions': {
        header: "Actions", // Renames Actions Column
      },
    }}

    muiTopToolbarProps={({ table }) => ({
      sx: (theme: any) => ({
        ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
      }),
    })}

    muiTableHeadProps={{
      sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
    }}

    muiTableHeadCellProps={({ column }) => ({
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
        ...(column.id === 'mrt-row-select' && {
          maxWidth: '50px',
          width: '50px',
        }),
      }),
    })}

    muiFilterTextFieldProps={{
      sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
    }}

    muiTableBodyRowProps={({ row }) => ({
      onClick: (event) => {
        console.log(row.original);
        openEditPage(row)
      },
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
        backgroundColor:
          row.index % 2 === 0
            ? colorConfigs.tables.firstRow
            : colorConfigs.tables.secondRow,
      }),
    })}

    muiTableBodyProps={{
      sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
    }}

    muiPaginationProps={{
      rowsPerPageOptions: [250, 500, 1000],
      sx: {
        padding: 0,
        margin: 0,
        '& .MuiTablePagination-root': {
          padding: 0,
          margin: 0,
        },
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          minHeight: '0px',
        },
        '& .MuiTablePagination-selectLabel': {
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          margin: 0,
        },
      },
    }}

    muiTableBodyCellProps={({ cell }) => ({
      // TABLE BODY CELLS
      sx: {
      },
    })}

    muiBottomToolbarProps={{
      // BOTTOM TOOLBAR (Pagination)
      sx: {
        height: '41px',
        maxHeight: '41px',
        minHeight: '41px',
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: '#fff',
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        margin: 0,
        padding: 0,
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          paddingBottom: 0,
          paddingTop: 0,
        },
        '& .MuiBox-root': {
          p: 0,
        },
        '& .MuiIconButton-root': {
          color: colorConfigs.tables.headBg,
        },
        '& .MuiTablePagination-root': {
          margin: 0,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          color: colorConfigs.buttons.bg,
        },
        '& .MuiTablePagination-selectLabel': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          color: colorConfigs.buttons.bg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
      },
    }}

    renderTopToolbarCustomActions={({ table }) => {

      const handleArchiveResults = () => {
        table.getSelectedRowModel().flatRows.map(row => {
          //Add archive logic
        });
      };

      const handleExportRows = (rows: MRT_Row<any>[]) => {
        const excel_rows: string[] = [];
        const now = dayjs().format('YYYY-MM-DD_HH-mm-ss');
        const filename = `${now}_feedback.csv`;

        // Column headers
        excel_rows.push("ID,User Id,Timestamp,Reader Feedback,Test Feedback,App Version,Device Model,Device uuid,Lot Number,Organisation ID,OS Version,Resuld uuid,Test Type");

        rows.forEach(row => {
          const rowArray = [
            row.original.id,
            row.original.user_id,
            row.original.timestamp ? dayjs(row.original.timestamp).format('YYYY-MM-DD HH:mm:ss') : "",
            row.original.reader_feedback,
            row.original.test_feedback,
            row.original.app_version,
            row.original.device_model,
            row.original.device_uuid,
            row.original.lot_number,
            row.original.organisation_id,
            row.original.os_version,
            row.original.resuld_uuid,
            row.original.test_type,
          ].map(field => `"${field}"`);
          excel_rows.push(rowArray.join(','));
        });

        // Combine all rows into a single CSV string
        const csvContent = excel_rows.join('\n');

        // Trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // Check if the link has a parent node before removing it
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      };


      return (
        <Box data-testid="Box-nb1d" sx={{ display: 'flex', gap: '0.3rem', height: "40px", alignItems: 'flex-end' }}>
          <Tooltip data-testid="Tooltip-6xdh" title="View action on selected data" placement="top" arrow>
            <Box data-testid="Box-0vf3">
              <RareButton data-testid="RareButton-gm16"
                variant="outlined"
                onClick={handleClick}
                icon={<KeyboardArrowDownIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: isMobile ? 0 : 1, px: isMobile ? 1 : 2, fontSize: '14px' }}
              >
                {isMobile ? "" : "Bulk Actions"}
              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-3j3j" title="Refresh Data" placement="top" arrow>
            <Box data-testid="Box-5dma">
              <RareButton data-testid="RareButton-s1mm"
                variant="outlined"
                onClick={() => handleRefreshButton()}
                center={true}
                icon={<RefreshIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >

              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-7edg" title="Clear All Table Filters" placement="top" arrow>
            <Box data-testid="Box-o7ig">
              <RareButton data-testid="RareButton-ob50"
                variant="outlined"
                onClick={() => handleRemoveAllFilters()}
                icon={<FilterAltOffOutlinedIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >
              </RareButton>
            </Box>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem data-testid="MenuItem-oq7n" disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
              Export to Excel
            </MenuItem>
          </StyledMenu>
        </Box>
      );
    }}
  />
}

export default FeedbackTable;

