import { Card, Grid, Box, Button, Typography, InputLabel, Table, TableRow, TableCell, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Switch, Dialog, DialogContent, CircularProgress, Collapse, Tooltip, IconButton } from '@mui/material';
import React, { Component, useEffect, useState, useRef } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import sizeConfigs from '../../../configs/sizeConfigs';
import api from '../../../redux/features/auth/axiosAuthHelper';
import colorConfigs from '../../../configs/colorConfigs';
import fontConfigs from '../../../configs/fontConfigs';
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';
import { ResultDetailEditField, NotesField, TextAdornmentReadOnlyField, TextAdornmentNoStatusReadOnlyField, ReadOnlySwitch } from '../fields/fields';
import { Link as RouterLink }from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
interface ResultDetailAccordionProps {
    result: any;
    authData: any;
    expanded: any;
    setExpanded: (detail: string) => void;
    user: any;
    formik: any;

}

const ResultDetailAccordion: React.FC<ResultDetailAccordionProps> = ({ result, authData, expanded, setExpanded, user, formik}) => {

const resultDetailsRef = useRef<HTMLDivElement>(null);
const configurationRef = useRef<HTMLDivElement>(null);

// Separate state variables for each accordion
const [resultScrollable, setResultScrollable] = useState(false);
const [resultShowScrollBottom, setResultShowScrollBottom] = useState(false);

const [configScrollable, setConfigScrollable] = useState(false);
const [configShowScrollBottom, setConfigShowScrollBottom] = useState(false);

// Updated checkScroll function to handle different refs
const checkScroll = (ref: any, setShowScrollBottom: any) => {
    if (ref.current) {
        const { scrollTop, scrollHeight, clientHeight } = ref.current;
        setShowScrollBottom(scrollTop + clientHeight < scrollHeight);
    }
};

// Updated scrollContent function to scroll specific ref
const scrollContent = (ref: any, direction: any) => {
    ref.current?.scrollBy({
        top: direction === 'down' ? 600 : -600,
        behavior: 'smooth'
    });
};

// Set up scroll event listeners for each ref
useEffect(() => {
    const handleResultScroll = () => checkScroll(resultDetailsRef, setResultShowScrollBottom);

    if (resultDetailsRef.current) {
        // Delay initial check to ensure content is fully loaded
        setTimeout(handleResultScroll, 100);

        // Add scroll listener
        resultDetailsRef.current.addEventListener('scroll', handleResultScroll);
    }

    return () => {
        resultDetailsRef.current?.removeEventListener('scroll', handleResultScroll);
    };
}, [resultDetailsRef.current?.scrollHeight, resultDetailsRef.current?.clientHeight]);

useEffect(() => {
    const handleConfigScroll = () => checkScroll(configurationRef, setConfigShowScrollBottom);

    if (configurationRef.current) {
        // Delay initial check to ensure content is fully loaded
        setTimeout(handleConfigScroll, 100);

        // Add scroll listener
        configurationRef.current.addEventListener('scroll', handleConfigScroll);
    }

    return () => {
        configurationRef.current?.removeEventListener('scroll', handleConfigScroll);
    };
}, [configurationRef.current?.scrollHeight, configurationRef.current?.clientHeight]);



const handleAccordianChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

    const handleJsonExport = async () => {
        const export_url = `results/${result.id}/export_json`;
        try {
            const response = await api.get(export_url, {
                headers: {
                    'Authorization': `Bearer ${authData.access}`
                },
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `result_${result.id}.json`;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Failed to download JSON file:", error);
        }
    };

    return ( 
          
    <Grid 
        item 
        xs={12} 
        md={3} 
        lg={3}
        sx={{ 
            height: '100%',
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'space-between',
            borderRight: "solid #eee 1px",
            p: 3,
            pt: 0,
            background: 'rgba(1, 1, 1, 0.02)'
        }}
    >           
            {/* Accordion Container */}
            <Box data-testid="Box-luha" sx={{ flexGrow: 1, overflowY: 'auto', pt:1 }}>

            <Accordion data-testid="Accordion-izlg" 
                expanded={expanded === 'resultInfo'} 
                onChange={handleAccordianChange('resultInfo')}
                sx={{ 
                    boxShadow: 'none', 
                    padding: 0,
                    margin: 0,
                    '&:before': { display: 'none' },
                    backgroundColor: 'transparent',
                    '&.Mui-expanded': {
                        padding: 0,
                        margin: 0,
                    },
                }}
                >
                <AccordionSummary data-testid="AccordionSummary-8s4y"
                    expandIcon={<ExpandMoreIcon sx={{}}/>}
                    aria-controls="result-info-content"
                    id="result-info-header"
                    sx={{
                        padding: 0,
                        margin: 0,
                        paddingY: 0.5,
                        minHeight: "40px",
                        // color: colorConfigs.primary,
                        '& .MuiSvgIcon-root': {
                            // color: colorConfigs.primary,
                        },
                        backgroundColor: 'transparent',
                        '&.Mui-expanded': {
                            minHeight: "40px",
                            margin: 0,
                            padding: 0,
                            paddingY: 0.5,
                            color: colorConfigs.primary,
                            '& .MuiSvgIcon-root': {
                                color: colorConfigs.primary,
                            },
                            '& .MuiTypography-root': {
                                color: colorConfigs.primary, // Ensure Typography changes color
                            },
                        },
                        '&:hover': {
                            color: colorConfigs.primary,
                            '& .MuiSvgIcon-root': {
                                color: colorConfigs.primary,
                            },
                            '& .MuiTypography-root': {
                                color: colorConfigs.primary, // Ensure Typography changes color
                            },
                        },
                        '& .MuiAccordionSummary-content': {
                            margin: 0,  // Ensure no margin in the content area
                        },
                    }}
                >
                <Box data-testid="Box-8doy" sx={{display: 'flex', alignItems: 'center', gap: 2,}}>  
                    <InsertChartOutlinedIcon fontSize="large"/>
                    <Typography data-testid="Typography-ff1a" sx={{ fontSize: fontConfigs.detailPage.headings.title, fontWeight: "Bold" }}>Result Info</Typography>
                </Box>
               
                </AccordionSummary>
                <AccordionDetails
                    ref={resultDetailsRef}
                    sx={{ 
                        padding: 0, 
                        maxHeight: sizeConfigs.detailPage.dialogContent.accordionDetails, 
                        overflow: 'scroll',
                        '&::-webkit-scrollbar': { display: 'none' },
                        msOverflowStyle: 'none', // Internet Explorer 10+
                        scrollbarWidth: 'none', // Firefox
                        }}>
                    {formik.values.datarun_id && (
                        <Box data-testid="Box-8ww5" sx={{ py: 2, display: 'flex', flexDirection: 'column'}}>
                            <Typography data-testid="Typography-zcjp" sx={{fontSize: '14px', fontWeight: 'bold'}}>This is a Reprocessed Result</Typography>
                                <Box data-testid="Box-jx6w" sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                                    <Field data-testid="Field-725q" name="datarun_id" type="text" className="form-control" label="Datarun ID" component={ResultDetailEditField} />
                                    <Tooltip data-testid="Tooltip-8u3h"   
                                        arrow
                                        placement="top"
                                        title="View Datarun"
                                        >
                                        <IconButton data-testid="IconButton-w1q3"
                                            component={RouterLink}
                                            to={`/data_analysis/data_run_management/${formik?.values?.datarun_id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{transition: 'transform 0.3s ease', p:1, mt: 1}}
                                        >
                                            <VisibilityOutlinedIcon sx={{color: colorConfigs.sidebar.color}} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                <Box data-testid="Box-1sfo" sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                                    <Field data-testid="Field-2xv8" name="source_result_id" type="text" className="form-control" label="Source Result ID" component={ResultDetailEditField} />
                                    <Tooltip data-testid="Tooltip-tngb"   
                                        arrow
                                        placement="top"
                                        title="View Source Result"
                                        >
                                        <IconButton data-testid="IconButton-lbid"
                                            component={RouterLink}
                                            to={`/results/${formik?.values?.source_result_id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{transition: 'transform 0.3s ease', p:1, mt: 1}}
                                        >
                                            <VisibilityOutlinedIcon sx={{color: colorConfigs.sidebar.color}} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                <Box data-testid="Box-zc5h" sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                                    <Field data-testid="Field-wrrv" name="source_dataset_id" type="text" className="form-control" label="Source Dataset ID" component={ResultDetailEditField} />
                                    <Tooltip data-testid="Tooltip-eaja"   
                                        arrow
                                        placement="top"
                                        title="View Source Dataset"
                                        >
                                        <IconButton data-testid="IconButton-x18n"
                                            component={RouterLink}
                                            to={`/data_analysis/data_set_management/${formik?.values?.source_dataset_id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{transition: 'transform 0.3s ease', p:1, mt: 1}}
                                        >
                                            <VisibilityOutlinedIcon sx={{color: colorConfigs.sidebar.color}} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                        </Box>
                    )}

                    <Box data-testid="Box-nsjx" sx={{pb:3}}>
                    <Field data-testid="Field-fue8" name="id" type="text" className="form-control" label="ID" component={ResultDetailEditField} />
                    <Field data-testid="Field-m513" name="timestamp" type="text" className="form-control" label="Date" component={ResultDetailEditField} />
                    <Field data-testid="Field-0kfr" name="sample_id" type="text" className="form-control" label="Sample ID" component={ResultDetailEditField} />
                   
                   
                    <Field data-testid="Field-m046" name="notes" type="text" className="form-control" label="Notes" component={NotesField} rows={3}/>
                    {user ? (
                        <div data-testid="div-vlw2">
                            <Field data-testid="Field-22g6" name="user_email" type="text" id={formik.values.result_user_id} status={formik.values.user_is_active} className="form-control" label="User" component={TextAdornmentReadOnlyField} />
                            <Field data-testid="Field-awow" name="user_organisation_name" type="text" id={formik.values.organisation_id} tier={formik.values.user_subscription_tier} className="form-control" label="Organisation" component={TextAdornmentNoStatusReadOnlyField} />
                        </div>
                    ) : (
                        <Field data-testid="Field-foob" name="user_id" type="text" className="form-control" label="User ID" component={ResultDetailEditField} />
                    )}
                    <div data-testid="div-3haf" style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                        <Field data-testid="Field-t9te" name="method_id" type="text" className="form-control" label="Method ID" component={ResultDetailEditField} />
                        <Field data-testid="Field-65qi" name="housing_id" type="text" className="form-control" label="Housing ID" component={ResultDetailEditField} />
                    </div>
                    <Field data-testid="Field-8efb" name="device_model" type="text" className="form-control" label="Device Model" component={ResultDetailEditField} />
                    <div data-testid="div-71vr" style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                        <Field data-testid="Field-snfn" name="app_version" type="text" className="form-control" label="App Version" component={ResultDetailEditField} />   
                        <Field data-testid="Field-6yxw" name="os_version" type="text" className="form-control" label="OS Version" component={ResultDetailEditField} />
                    </div>

                    <Box data-testid="Box-hls5"
                        onClick={() => scrollContent(resultDetailsRef, 'down')}
                        sx={{
                            opacity: resultShowScrollBottom ? 0.9 : 0,  // Controls visibility smoothly
                            transition: 'opacity 0.3s ease',  // Smooth transition for opacity
                            pointerEvents: resultShowScrollBottom ? 'auto' : 'none',  // Disable clicks when hidden
                            position: 'sticky',
                            bottom: 0,
                            width: '100%',
                            height: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            backgroundColor: colorConfigs.tables.headBg,
                            cursor: 'pointer',
                            zIndex: 10,
                            display: 'flex',
                            '&:hover': {
                                backgroundColor: colorConfigs.tables.headBg,
                                opacity: 1,
                            }
                        }}
                    >
                        <ArrowDropDownIcon fontSize="large" sx={{ color: '#fff' }} />
                    </Box>
                    </Box>
                </AccordionDetails>
                </Accordion>

                {/* Configuration Accordion */}
                <Accordion data-testid="Accordion-e70u" 
                expanded={expanded === 'configuration'} 
                onChange={handleAccordianChange('configuration')}
                sx={{ 
                    boxShadow: 'none', 
                    padding: 0,
                    margin: 0,
                    '&:before': { display: 'none' },
                    backgroundColor: 'transparent',
                    '&.Mui-expanded': {
                        padding: 0,
                        margin: 0,
                    },
                }}
                >
                <AccordionSummary data-testid="AccordionSummary-d661"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="configuration-content"
                    id="configuration-header"
                    sx={{
                        padding: 0,
                        paddingY: 0.5,
                        margin: 0,
                        minHeight: "40px",
                        // color: colorConfigs.primary,
                        '& .MuiSvgIcon-root': {
                            // color: colorConfigs.primary,
                        },
                        backgroundColor: 'transparent',
                        '&.Mui-expanded': {
                            minHeight: "40px",
                            margin: 0,
                            padding: 0,
                            paddingY: 0.5,
                            color: colorConfigs.primary,
                            '& .MuiSvgIcon-root': {
                                color: colorConfigs.primary,
                            },
                            '& .MuiTypography-root': {
                                color: colorConfigs.primary, // Ensure Typography changes color
                            },
                        },
                        '&:hover': {
                            color: colorConfigs.primary,
                            '& .MuiSvgIcon-root': {
                                color: colorConfigs.primary,
                            },
                            '& .MuiTypography-root': {
                                color: colorConfigs.primary, // Ensure Typography changes color
                            },
                        },
                        '& .MuiAccordionSummary-content': {
                            margin: 0,  // Ensure no margin in the content area
                        },
                    }}
                >
                <Box data-testid="Box-ck4y" sx={{display: 'flex', alignItems: 'center', gap: 2,}}>  
                    <DataObjectOutlinedIcon fontSize="large"/>
                    <Typography data-testid="Typography-6dyu" sx={{ fontSize: fontConfigs.detailPage.headings.title, fontWeight: "Bold"}}>Configuration</Typography>
                </Box>
               
                </AccordionSummary>
                <AccordionDetails 
                    ref={configurationRef} 
                    sx={{ 
                        padding: 0, 
                        maxHeight: sizeConfigs.detailPage.dialogContent.accordionDetails, 
                        overflow: 'scroll',
                        '&::-webkit-scrollbar': { display: 'none' },
                        msOverflowStyle: 'none', // Internet Explorer 10+
                        scrollbarWidth: 'none', // Firefox
                        }}>
                    <div data-testid="div-hui6" style={{}}>
                        <Field data-testid="Field-q3v8" name="control_baseline_threshold" type="text" label="Control Baseline Threshold" component={ResultDetailEditField} />
                        <Field data-testid="Field-gi0h" name="frames_to_capture" type="text" label="Frames to Capture" component={ResultDetailEditField} />
                        <Field data-testid="Field-y1q8" name="initial_h_factor" type="text" label="Initial H Factor" component={ResultDetailEditField} />
                        <Field data-testid="Field-dyx9" name="polynomial_baseline_order" type="text" label="Polynomial Baseline Order" component={ResultDetailEditField} />
                        <Field data-testid="Field-xl79" name="reader_resolution" type="text" label="Reader Resolution" component={ResultDetailEditField} />
                        <Field data-testid="Field-pznt" name="max_conformance_probability" type="text" label="Max Conformance Probability" component={ResultDetailEditField} />
                        <Field data-testid="Field-cggt" name="min_conformance_probability" type="text" label="Min Conformance Probability" component={ResultDetailEditField} />
                    </div>
                    <Box data-testid="Box-gigg" sx={{ border: '1px #ddd solid', borderRadius: 1.2, p: 2, paddingY: 1.5, marginY: 2, display: 'flex', flexDirection: 'column'}}>
                        <Field data-testid="Field-ov1q" name="camera_macro_focus_required" label="Camera Macro Focus Required" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                        <Field data-testid="Field-nn7s" name="collection_mode" label="Collection Mode" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                        <Field data-testid="Field-4v5w" name="control_baseline" label="Control Baseline" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                        <Field data-testid="Field-8cyf" name="control_exposure" label="Control Exposure" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                        <Field data-testid="Field-bxrz" name="control_line_correction" label="Control Line Correction" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                        <Field data-testid="Field-77vq" name="flash_required" label="Flash Required" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                        <Field data-testid="Field-zhzi" name="profile_alignment" label="Profile Alignment" component={ReadOnlySwitch} colorConfigs={colorConfigs} />
                    </Box>

                    <Box data-testid="Box-ar6t"
                        onClick={() => scrollContent(configurationRef, 'down')}
                        sx={{
                            opacity: configShowScrollBottom ? 0.9 : 0,  // Controls visibility smoothly
                            transition: 'opacity 0.3s ease',  // Smooth transition for opacity
                            pointerEvents: configShowScrollBottom ? 'auto' : 'none',  // Disable clicks when hidden
                            position: 'sticky',
                            bottom: 0,
                            width: '100%',
                            height: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            backgroundColor: colorConfigs.tables.headBg,
                            cursor: 'pointer',
                            display: 'flex',
                            zIndex: 10,
                            '&:hover': {
                                backgroundColor: colorConfigs.tables.headBg,
                                opacity: 1,
                            }
                        }}
                    >
                        <ArrowDropDownIcon fontSize="large" sx={{ color: '#fff' }} />
                    </Box>

                </AccordionDetails>
                </Accordion>
        </Box>
        <Box data-testid="Box-rl3t" sx={{ 
            // pt: 2,               
            // textAlign: 'center'
            }}>
            <Button data-testid="Button-2sgs" variant="contained" onClick={handleJsonExport}>
            Export JSON
            </Button>
        </Box>
    </Grid>
     );
}
 
export default ResultDetailAccordion;