import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { useMediaQuery, Box, Button, Grid, Link, Typography, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled, Theme, Switch, Tooltip, IconButton } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { ResultSessionStorageKey, ResultSessionStorageModel, ResultTableParameterStorageService } from "../../../services/sessionStorage";
import colorConfigs from "../../../configs/colorConfigs";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { tsXLXS } from 'ts-xlsx-export';
import * as XLSX from "xlsx";
import { MRT_FilterFns } from 'material-react-table';
import RareButton from "../buttons/RareButton";
import api from "../../../redux/features/auth/axiosAuthHelper";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const StyledMenu = styled((props: any) => (
  <Menu data-testid="Menu-9w0d"
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


interface ResultsTableForMethodsProps {
  authData: any;
  rows: any[];
  isLoading: boolean;
  handleResultRowClick: (row: any) => void;
  handleRefreshButton: () => void;
}

const ResultsTableForMethods: React.FC<ResultsTableForMethodsProps> = ({
  authData,
  rows,
  isLoading,
  handleResultRowClick,
  handleRefreshButton
}) => {

  console.log("Results", rows)
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const customResultTypeFilter = (row: any, columnId: any, filterValue: string) => {
    const { datarun_id, test_configuration } = row.original;
    const isDCMAppResult = !datarun_id && test_configuration?.collection_mode;
    const isNormalAppResult = !datarun_id && !test_configuration?.collection_mode;
    const isDatarunResult = !!datarun_id;

    const datarunText = `Datarun: ${datarun_id}`;
    const searchValue = filterValue.toLowerCase();

    return (
      (isDCMAppResult && "data collection mode result".includes(searchValue)) ||
      (isNormalAppResult && "normal app result".includes(searchValue)) ||
      (isDatarunResult && "reprocessed result".includes(searchValue)) ||
      (isDatarunResult && datarunText.toLowerCase().includes(searchValue))
    );
  };

  const filterFns = {
    ...MRT_FilterFns,
    customResultTypeFilter,
  };


  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };


  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      Header: () => <div data-testid="div-dzio" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Result<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'sample_id',
      header: 'Sample ID',
      Header: () => <div data-testid="div-2f63" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Sample<br />ID
      </div>,
    },
    {
      accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      header: 'Timestamp',
      Header: () => <div data-testid="div-j5l4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Timestamp<br />&nbsp;
      </div>,
      Cell: ({ cell }) => (
        <div data-testid="div-7em7" style={{ whiteSpace: 'pre-wrap' }}>
          {cell.getValue()?.toString().split(' ').join('\n')}
        </div>
      ),
    },
    {
      accessorKey: 'datarun_id',
      header: 'Source',
      Header: () => (
        <div
          data-testid="div-dfvg"
          style={{ textAlign: 'center', height: '100%', minHeight: '50px' }}
        >
          Source
          <br />
          &nbsp;
        </div>
      ),
      Cell: ({ row }) => {
        const { datarun_id, source_result, test_configuration } =
          row.original;
        const source_result_id = source_result?.id;
        const dataset_id = source_result?.dataset_id;
        const isDCMAppResult =
          !datarun_id && test_configuration?.collection_mode;
        const isNormalAppResult =
          !datarun_id && !test_configuration?.collection_mode;
        const isDatarunResult = !!datarun_id;

        return (
          <div data-testid="div-6a98">
            {isDCMAppResult ? (
              <Typography data-testid="Typography-oy1b">
                Data Collection Mode Result
              </Typography>
            ) : isNormalAppResult ? (
              <Typography data-testid="Typography-kkv6">
                Normal App Result
              </Typography>
            ) : isDatarunResult ? (
              <div data-testid="div-2hzx"
              >
                <Typography
                  data-testid="Typography-9npz"
                  variant="subtitle1"
                  gutterBottom
                >
                  Reprocessed Result
                </Typography>
                <Box data-testid="div-x5v0"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    pl: 2,
                  }}
                >
                  {/* Datarun ID Section */}
                  <div data-testid="div-mlp6" onClick={(e) => e.stopPropagation()} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography data-testid="Typography-ry81" sx={{ fontSize: '12px', pr: 0.5, }}>
                      <strong>Data Run ID:</strong> {datarun_id}
                    </Typography>
                    <Tooltip data-testid="Tooltip-3i31" title="Copy Datarun ID">
                      <IconButton data-testid="IconButton-w2nx"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyToClipboard(datarun_id);
                        }}
                      >
                        <ContentCopyIcon sx={{ p: 0, m: 0, fontSize: '16px' }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip data-testid="Tooltip-dt9t" title="View Datarun">
                      <IconButton data-testid="IconButton-1nto"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(
                            `/data_analysis/data_run_management/${datarun_id}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                      >
                        <OpenInNewIcon sx={{ p: 0, m: 0, fontSize: '16px' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  {/* Dataset ID Section */}
                  <div data-testid="div-iw27" onClick={(e) => e.stopPropagation()} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography data-testid="Typography-ey9z" sx={{ fontSize: '12px', pr: 0.5, }}>
                      <strong>Dataset ID:</strong> {dataset_id}
                    </Typography>
                    <Tooltip data-testid="Tooltip-0kl3" title="Copy Dataset ID">
                      <IconButton data-testid="IconButton-kcj7"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyToClipboard(dataset_id);
                        }}
                      >
                        <ContentCopyIcon sx={{ p: 0, m: 0, fontSize: '16px' }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip data-testid="Tooltip-362t" title="View Dataset">
                      <IconButton data-testid="IconButton-k5nz"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(
                            `/data_analysis/data_set_management/${dataset_id}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                      >
                        <OpenInNewIcon sx={{ p: 0, m: 0, fontSize: '16px' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  {/* Source Result ID Section */}
                  <div data-testid="div-xrg2" onClick={(e) => e.stopPropagation()} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography data-testid="Typography-gdjc" sx={{ fontSize: '12px', pr: 0.5, }}>
                      <strong>Source Result ID:</strong> {source_result_id}
                    </Typography>
                    <Tooltip data-testid="Tooltip-hdyn" title="Copy Source Result ID">
                      <IconButton data-testid="IconButton-79gl"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyToClipboard(source_result_id);
                        }}
                      >
                        <ContentCopyIcon sx={{ p: 0, m: 0, fontSize: '16px' }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip data-testid="Tooltip-r2ar" title="View Source Result">
                      <IconButton data-testid="IconButton-3x7h"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(
                            `/results/${source_result_id}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                      >
                        <OpenInNewIcon sx={{ fontSize: '16px' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Box>
              </div>
            ) : null}
          </div>
        );
      },
      enableColumnFilter: true,
      filterFn: 'customResultTypeFilter',
    },
    {
      accessorKey: 'method_id',
      header: "Method",
      Header: () => <div data-testid="div-olz0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Method<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'housing_id',
      header: "Housing",
      Header: () => <div data-testid="div-4vcd" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Housing<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'organisation_id',
      header: 'Organisation ID',
      Header: () => <div data-testid="div-lcw7" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Organisation<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'user_id',
      header: 'User ID',
      Header: () => <div data-testid="div-psyz" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        User<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
  ]

  return <MaterialReactTable
    columns={columns}
    data={rows}
    filterFns={filterFns}
    state={{
      isLoading,
    }}
    defaultColumn={{
      minSize: 10,
      maxSize: 160,
      size: 10,
    }}
    enableColumnOrdering={true}
    positionActionsColumn="last"
    enableRowSelection
    selectAllMode="all"
    // enableRowActions
    initialState={{
      density: 'compact',
      columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions'] },
    }}

    // TABLE STYLING IS IN THE THEME
    displayColumnDefOptions={{
      'mrt-row-actions': {
        header: "Actions", // Renames Actions Column
      },
    }}

    muiTopToolbarProps={({ table }) => ({
      sx: (theme: any) => ({
        ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
      }),
    })}

    muiTableHeadProps={{
      sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
    }}

    muiTableHeadCellProps={({ column }) => ({
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
        ...(column.id === 'mrt-row-select' && {
          maxWidth: '50px',
          width: '50px',
        }),
      }),
    })}

    muiFilterTextFieldProps={{
      sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
    }}

    muiTableBodyRowProps={({ row }) => ({
      onClick: (event) => {
        handleResultRowClick(row.original)
      },
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
        backgroundColor:
          row.index % 2 === 0
            ? colorConfigs.tables.firstRow
            : colorConfigs.tables.secondRow,
      }),
    })}

    muiTableBodyProps={{
      sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
    }}

    muiPaginationProps={{
      rowsPerPageOptions: [250, 500, 1000],
      sx: {
        padding: 0,
        margin: 0,
        '& .MuiTablePagination-root': {
          padding: 0,
          margin: 0,
        },
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          minHeight: '0px',
        },
        '& .MuiTablePagination-selectLabel': {
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          margin: 0,
        },
      },
    }}

    muiTableBodyCellProps={({ cell }) => ({
      // TABLE BODY CELLS
      sx: {
      },
    })}

    muiBottomToolbarProps={{
      // BOTTOM TOOLBAR (Pagination)
      sx: {
        height: '41px',
        maxHeight: '41px',
        minHeight: '41px',
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: '#fff',
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        margin: 0,
        padding: 0,
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          paddingBottom: 0,
          paddingTop: 0,
        },
        '& .MuiBox-root': {
          p: 0,
        },
        '& .MuiIconButton-root': {
          color: colorConfigs.tables.headBg,
        },
        '& .MuiTablePagination-root': {
          margin: 0,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          color: colorConfigs.buttons.bg,
        },
        '& .MuiTablePagination-selectLabel': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          color: colorConfigs.buttons.bg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
      },
    }}


    renderTopToolbarCustomActions={({ table }) => {


      return (
        <Box data-testid="Box-36bn" sx={{ display: 'flex', gap: '0.3rem', height: "40px", alignItems: 'flex-end' }}>
          {/* <Tooltip data-testid="Tooltip-1z0f" title="View action on selected data" placement="top" arrow>
            <Box data-testid="Box-ufit">
              <RareButton data-testid="RareButton-4f1x"
                variant="outlined"
                onClick={handleClick}
                icon={<KeyboardArrowDownIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: isMobile ? 0 : 1, px: isMobile ? 1 : 2, fontSize: '14px' }}
              >
                {isMobile ? "" : "Bulk Actions"}
              </RareButton>
            </Box>
          </Tooltip> */}
          <Tooltip data-testid="Tooltip-7bcy" title="Refresh Data" placement="top" arrow>
            <Box data-testid="Box-doq4">
              <RareButton data-testid="RareButton-klzb"
                variant="outlined"
                onClick={() => handleRefreshButton()}
                center={true}
                icon={<RefreshIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >

              </RareButton>
            </Box>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {/* <MenuItem data-testid="MenuItem-qc5c" disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            } onClick={() => handleExportMultiLineRows(table.getSelectedRowModel().rows)} disableRipple>
              <Box data-testid="Box-phjl" sx={{ display: 'flex', alignItems: 'center' }}>
                <FileDownloadOutlinedIcon />
                <Typography data-testid="Typography-08co">Export to Excel (Mulitline)</Typography>
              </Box>
            </MenuItem>
            <MenuItem data-testid="MenuItem-qc5d" disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            } onClick={() => handleExportOneLineRows(table.getSelectedRowModel().rows)} disableRipple>
              <Box data-testid="Box-phjh" sx={{ display: 'flex', alignItems: 'center' }}>
                <FileDownloadOutlinedIcon />
                <Typography data-testid="Typography-08cr">Export to Excel (One Line)</Typography>
              </Box>
            </MenuItem> */}
            {/* <MenuItem data-testid="MenuItem-qc5e" disabled={
                          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        } onClick={() => handleExportAntonioRows(table.getSelectedRowModel().rows)} disableRipple>
                          <Box data-testid="Box-phjg" sx={{display: 'flex', alignItems: 'center'}}>
                            <FileDownloadOutlinedIcon />
                            <Typography data-testid="Typography-08cp">Export to Excel (Antonio Layout)</Typography>
                          </Box>    
                        </MenuItem> */}
          </StyledMenu>
        </Box>
      );
    }}
  />
}

export default ResultsTableForMethods;

