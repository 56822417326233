import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import MobileMenuItem from "./MobileMenuItem";
import { useDispatch, useSelector } from "react-redux";
import { RouteType } from "../../../routes/config";
import { RootState } from "../../../redux/store";
import colorConfigs from "../../../configs/colorConfigs";
import { Link } from "react-router-dom";
import SubMobileMenuItemCollapse from "./SubMobileMenuItemCollapse";
import SubMobileMenuItem from "./SubMobileMenuItem";
import { setAppState } from "../../../redux/features/appStateSlice";
import checkRoutePermissions from "../../../routes/appRoutePermissions";


type Props = {
  item: RouteType;
  handleDrawerClose: () => void;
};

const MobileMenuItemCollapse = ({ item, handleDrawerClose }: Props) => {
  const dispatch = useDispatch();
  const { appState } = useSelector((state: RootState) => state.appState);
  const authData = useSelector((state: RootState) => state.auth);
  const permission = checkRoutePermissions(authData, item);

  const [open, setOpen] = useState(() => {
    return appState === item.state || false
  }
  );

  // Check if parent or child is selected
  const isChildSelected = item.child?.some((childItem) => appState.startsWith(childItem.state));

  useEffect(() => {
    if (isChildSelected) {
      setOpen(true);  // Open the parent if any child is selected
    } else if (appState !== item.state) {
      setOpen(false)
    }
  }, [isChildSelected, item, appState]);

  if (!permission) return null;

  return (
    item.sidebarProps ? (
      <>
        <ListItemButton data-testid="ListItemButton-5snc"
          onClick={() => { setOpen(!open) }}
          disableRipple
          selected={isChildSelected}
          sx={{
            "&: hover": {
              color: colorConfigs.sidebar.activeBg,
              backgroundColor: colorConfigs.sidebar.bg,
              // Apply hover effect to the icon inside ListItemButton
              "& .MuiListItemIcon-root": {
                color: permission === "Premium" || permission === "Coming Soon" ? "grey" : colorConfigs.sidebar.activeBg,
              },
            },
            "&.Mui-selected": {
              backgroundColor: colorConfigs.sidebar.bg,
              color: colorConfigs.sidebar.activeBg,
              "&:hover": {
                backgroundColor: colorConfigs.sidebar.bg,
              },
              "&:focus": {
                backgroundColor: colorConfigs.sidebar.bg,
              },
              "&:active": {
                backgroundColor: colorConfigs.sidebar.bg,
              },
            },
            "&.Mui-selected.Mui-focusVisible": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&.Mui-selected:active": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&.Mui-selected:focus": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            color: isChildSelected ? colorConfigs.sidebar.activeBg : colorConfigs.sidebar.color,
            paddingY: 0.2
          }}
        >
          <ListItemIcon data-testid="ListItemIcon-5dnl" sx={{
            minWidth: "unset",
            marginLeft: "7px",
            p: 0,
            marginRight: "20px",
            justifyContent: "center",
            transition: "all 0.3s",
            color: isChildSelected ? colorConfigs.sidebar.activeBg : colorConfigs.sidebar.color,
            "&:hover": {
              color: colorConfigs.sidebar.hoverBg,
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&.Mui-selected": {
              backgroundColor: colorConfigs.sidebar.bg,
              color: colorConfigs.sidebar.activeBg,
              "&:hover": {
                backgroundColor: colorConfigs.sidebar.bg,
              },
              "&:focus": {
                backgroundColor: colorConfigs.sidebar.bg,
              },
              "&:active": {
                backgroundColor: colorConfigs.sidebar.bg,
              },
            },
            "&.Mui-selected.Mui-focusVisible": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&.Mui-selected:active": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&.Mui-selected:focus": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
          }}>
            {item.sidebarProps.icon && item.sidebarProps.icon}
          </ListItemIcon>
          {item.sidebarProps.displayText}
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </ListItemButton>
        <Collapse in={open} timeout="auto">
          <List>
            {item.child?.map((route, index) => (
              route.sidebarProps ? (
                route.child ? (
                  <SubMobileMenuItemCollapse handleDrawerClose={handleDrawerClose} item={route} key={index} />
                ) : (
                  <SubMobileMenuItem handleDrawerClose={handleDrawerClose} item={route} key={index} />
                )
              ) : null
            ))}
          </List>
        </Collapse>
      </>
    ) : null
  );
};

export default MobileMenuItemCollapse;