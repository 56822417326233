import React from 'react';
import { FieldProps, getIn } from 'formik';
import { Checkbox, IconButton, InputAdornment, MenuItem, Select, TextField, TextFieldProps } from '@mui/material';
import assets from '../../../assets';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import colorConfigs from '../../../configs/colorConfigs';
import CloseIcon from "@mui/icons-material/Close";
import 'dayjs/locale/en-gb';

export const FormDateFromPicker: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
    
    const { error, helperText, field, form, ...rest } = props
    
      return (

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DemoContainer components={['DatePicker']}>
          <DatePicker data-testid="DatePicker-20qn"
              label="From"
              value={field.value ? dayjs(field.value) : null}
              sx={{
                margin: '20px',
                '& .MuiInputBase-root': {
                  fontSize: '0.9rem',
                }}}
              onChange={(newValue) => {
                  if (newValue) {
                    form.setFieldValue(field.name, newValue ? dayjs(newValue).toISOString() : "");
                  }
              }}
          />
          </DemoContainer>
          </LocalizationProvider>
      )
    }

export const FormDateToPicker: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DemoContainer components={['DatePicker']}>
        <DatePicker data-testid="DatePicker-prbi"
            label="To"
            value={field.value ? dayjs(field.value) : null}
            sx={{
              margin: '20px',
              '& .MuiInputBase-root': {
                fontSize: '0.9rem',
              }}}
            onChange={(newValue) => {
                if (newValue) {
                  form.setFieldValue(field.name, newValue ? dayjs(newValue).toISOString() : "");
                }
            }}
        />
        </DemoContainer>
        </LocalizationProvider>
    )
  }

  export const FormResultIdTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField data-testid="TextField-sxxv"
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}      
        label="Result ID"
        margin="normal"
        placeholder='Enter full ID'
        autoFocus
      />
    )
  }

  export const FormUserIdTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField data-testid="TextField-3nek"
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}  
        label="User ID"
        margin="normal"
        placeholder='User identifier entered in app'
        autoFocus
      />
    )
  }

/**
 * Material TextField Component with Formik Support including Errors.
 * Intended to be specified via the `component` prop in a Formik <Field data-testid="Field-y7cy"> or <FastField> component.
 * Material-UI specific props are passed through.
 */
export const FormTestTypeTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField data-testid="TextField-zqbp"
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}  
        label="Test Type"
        margin="normal"
        autoFocus
        placeholder='e.g. Pheonix...'
        InputProps={{
      }}
      />
    )
  }


  interface FormTestTypeSelectFieldProps extends FieldProps {
    options: { value: string | number, label: string }[];
  }

  //  Buhlmann Contact Select
  export const FormTestTypeSelectField: React.FC<FormTestTypeSelectFieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, options, type, ...rest } = props
  
    return (
      <TextField data-testid="TextField-c81b"
        error={Boolean(isTouched && errorMessage)}
        helperText={(isTouched && errorMessage) ? errorMessage : undefined}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}  
        select
        label="Test Types"
      >
           <MenuItem data-testid="MenuItem-h9n5" key={0} value={""}>
              <em>&nbsp;</em>
           </MenuItem>
  
        {options.map((option, index) => (
          <MenuItem data-testid="MenuItem-omyz" key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      
      </TextField>
    )
  }

  interface FormOrganisationSelectFieldProps extends FieldProps {
    options: { value: string | number, label: string }[];
  }

  //  Buhlmann Contact Select
  export const FormOrganisationSelectField: React.FC<FormOrganisationSelectFieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, options, type, ...rest } = props
  
    return (
      <TextField data-testid="TextField-lok6"
        error={Boolean(isTouched && errorMessage)}
        helperText={(isTouched && errorMessage) ? errorMessage : undefined}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}  
        select
        label="Organisation"
      >
           <MenuItem data-testid="MenuItem-fbav" key={0} value={""}>
              <em>&nbsp;</em>
           </MenuItem>
  
        {options.map((option, index) => (
          <MenuItem data-testid="MenuItem-hoeq" key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      
      </TextField>
    )
  }
  
/**
 * Material TextField Component with Formik Support including Errors.
 * Intended to be specified via the `component` prop in a Formik <Field data-testid="Field-h5qw"> or <FastField> component.
 * Material-UI specific props are passed through.
 */
export const FormLotNumberTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField data-testid="TextField-t6ra"
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}  
        label="Lot Number"
        margin="normal"
        placeholder='e.g. AB12/3'
        autoFocus
        InputProps={{
      }}
      />
    )
  }


    /**
     * Material TextField Component with Formik Support including Errors.
     * Intended to be specified via the `component` prop in a Formik <Field data-testid="Field-pkm1"> or <FastField> component.
     * Material-UI specific props are passed through.
     */
    export const FormSampleIDTextField: React.FC<FieldProps & TextFieldProps> = props => {
        const isTouched = getIn(props.form.touched, props.field.name)
        const errorMessage = getIn(props.form.errors, props.field.name)
    
        const { error, helperText, field, form, ...rest } = props
    
        return (
        <TextField data-testid="TextField-1kut"
        //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            InputLabelProps={{
              sx: {
                fontSize: '0.9rem',
              },
            }}
            sx={{
              margin: '7px 0',
              '& .MuiInputBase-root': {
                fontSize: '0.9rem',
              },
            }}  
            label="Sample ID"
            margin="normal"
            placeholder='e.g. SampleIDD1'
            autoFocus
            InputProps={{
        }}
        />
        )
    }

  export const FormSampleTypeTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField data-testid="TextField-t0gm"
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}  
        label="Sample Type"
        margin="normal"
        placeholder='e.g. SampleType1'
        autoFocus
        InputProps={{
      }}
      />
    )
  }

  export const FormVisualResultTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField data-testid="TextField-cnuz"
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}  
        label="Visual Result"
        margin="normal"
        placeholder='e.g. Positive'
        autoFocus
        InputProps={{
      }}
      />
    )
  }

  export const FormSkipTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField data-testid="TextField-avxv"
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}  
        label="Skip"
        margin="normal"
        placeholder='e.g. 50 (Skip first 50 items)'
        autoFocus
        InputProps={{
      }}
      />
    )
  }

  export const FormLimitTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField data-testid="TextField-pnan"
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        InputLabelProps={{
          sx: {
            fontSize: '0.9rem',
          },
        }}
        sx={{
          margin: '7px 0',
          '& .MuiInputBase-root': {
            fontSize: '0.9rem',
          },
        }}  
        label="Limit"
        margin="normal"
        placeholder='e.g. 100 (Only fetch 100 items)'
        autoFocus
        InputProps={{
      }}
      />
    )
  }

  export const FormAppliedFiltersTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField data-testid="TextField-6h3d"
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        label="Enter new filter name"
        margin="none"
        placeholder='e.g. My new filter'
        autoFocus
        autoComplete="off"

        InputProps={{
          sx: {
              height: '32px',
              padding: '0 14px',
              'input': {
                  height: '1.1876em',
                  padding: '5px 0'
              }
          },
        }}
        sx={{
            margin: '9.8px 0',
            height: '35px',
            '& .MuiInputBase-root': {
                height: '100%',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid',
            },
        }}
        InputLabelProps={{
          sx: {
            top: '-7px'
          }
        }}
        variant="outlined"
      />
    )
  }


  // NEW FILTERBAR FEILDS

  export const FBTextReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
        <TextField data-testid="TextField-prpr"
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus
            sx={{
              m: 0,
              fontSize: '14px',
            }}
            InputLabelProps={{
              style: {
                fontSize: '14px',
              },
              shrink: true 
          }}
            InputProps={{
              sx:{
                fontSize: '14px',
                '& input': {
                  padding: 0.5,
                  paddingX: 1
              },
              }
          }}
        />
    )
  }

  export const FBTextEditField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField data-testid="TextField-bazw"
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus
            sx={{
              m: 0,
              fontSize: '14px',
            }}
            InputLabelProps={{
              style: {
                fontSize: '14px',
            },
              shrink: true 
          }}
            InputProps={{
              sx:{
                fontSize: '14px',
                '& input': {
                  padding: 0.5,
                  paddingX: 1
              },
              }
          }}
        />
    )
  }

  interface FormFBSelectFieldProps extends FieldProps {
    options: { value: string | number, label: string }[];
    selectTitle: string
  }

  export const FormFBSelectField: React.FC<FormFBSelectFieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, options, selectTitle, type, ...rest } = props
  
    return (
      <TextField data-testid="TextField-364l"
        error={Boolean(isTouched && errorMessage)}
        helperText={(isTouched && errorMessage) ? errorMessage : undefined}
        {...rest}
        {...field}
        fullWidth
        select
        sx={{
          m: 0,
          fontSize: '14px',
          '& Mui.select': {
            padding: 0.5,
            paddingX: 1,
            p: 0,
        },  
        }}
        InputLabelProps={{
          style: {
            fontSize: '14px',
            color: colorConfigs.text,
          },
          shrink: true 
      }}
        InputProps={{
          sx:{
            fontSize: '14px',
            '& input': {
              padding: 0.5,
              paddingX: 1,
            },
            '& .MuiOutlinedInput-input': {
              fontSize: '14px',
              padding: 0.5,
              paddingX: 1,
          },  
          }
      }}
      >
           <MenuItem data-testid="MenuItem-u26m" 
                     sx={{ 
                      padding: 0.5,
                      paddingX: 1,
                      fontSize: '14px'
                    }}
                    key={0} 
                    value={""}>
              <em>{selectTitle}</em>
           </MenuItem>
  
        {options.map((option, index) => (
          <MenuItem data-testid="MenuItem-1i47" 
                    sx={{ 
                      padding: 0.5,
                      paddingX: 1,
                      fontSize: '14px'
                    }} 
                    key={option.value} 
                    value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      
      </TextField>
    )
  }


  export const FormFBLimitSelectField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, type, ...rest } = props
    const options = [{value: "10", label: 'Latest 10'}, {value: "100", label: 'Latest 100'}, {value: "1000", label: 'latest 1000'}]
    return (
      <TextField data-testid="TextField-4rpa"
        error={Boolean(isTouched && errorMessage)}
        helperText={(isTouched && errorMessage) ? errorMessage : undefined}
        {...rest}
        {...field}
        fullWidth
        select
        sx={{
          minWidth: '172px',
          m: 0,
          fontSize: '14px',
          '& Mui.select': {
            padding: 0.5,
            paddingX: 1,
            p: 0,
        },  
        }}
        InputLabelProps={{
          style: {
            fontSize: '14px',
            color: colorConfigs.text,
          },
          shrink: true 
      }}
        InputProps={{
          sx:{
            fontSize: '14px',
            '& input': {
              padding: 0.5,
              paddingX: 1,
            },
            '& .MuiOutlinedInput-input': {
              fontSize: '14px',
              padding: 0.5,
              paddingX: 1,
          },  
          }
      }}
      >
                    <MenuItem data-testid="MenuItem-zury" 
                      sx={{ 
                      padding: 0.5,
                      paddingX: 1,
                      fontSize: '14px'
                    }}
                    key={0} 
                    value={""}>
              <em>Show All</em>
           </MenuItem>
  
  
        {options.map((option, index) => (
          <MenuItem data-testid="MenuItem-lu2w" 
                    sx={{ 
                      padding: 0.5,
                      paddingX: 1,
                      fontSize: '14px'
                    }} 
                    key={option.value} 
                    value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      
      </TextField>
    )
  }


  export const FBIDEditField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    // Destructure InputProps so we can merge it with our defaults
    const { error, helperText, field, form, InputProps, ...rest } = props
  
    const mergedInputProps = {
      sx: {
        fontSize: '14px',
        pr: 1,
        '& input': {
          padding: 0.5,
          paddingX: 1,
        },
        // If the parent passed in any sx, merge it here
        ...(InputProps?.sx as object),
      },
      ...InputProps, // this brings in the endAdornment and any other InputProps
    }
  
    return (
      <TextField
        data-testid="TextField-bazw"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        variant="outlined"
        margin="normal"
        autoFocus
        sx={{
          m: 0,
          p: 0,
          fontSize: '14px',
        }}
        InputLabelProps={{
          style: {
            fontSize: '14px',
            color: colorConfigs.text,
          },
          shrink: true,
        }}
        InputProps={mergedInputProps}
      />
    )
  }
  


  export const FBDatePicker: React.FC<FieldProps & TextFieldProps> = (props) => {
    const isTouched = getIn(props.form.touched, props.field.name);
    const errorMessage = getIn(props.form.errors, props.field.name);
    const { error, helperText, field, form, ...rest } = props;
  
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DatePicker
          data-testid="DatePicker-q5r7"
          label={rest.label}
          value={field.value ? dayjs(field.value) : null}
          onChange={(newValue) => {
            if (newValue && dayjs(newValue).isValid()) {
              form.setFieldValue(field.name, dayjs(newValue).toISOString());
            } else {
              form.setFieldValue(field.name, "");
            }
          }}
          // format="DD/MM/YYYY"
          // Use the TextField as the default slot
          slots={{ textField: TextField }}
          slotProps={{
            textField: (params) => ({
              ...params,
              error: error ?? Boolean(isTouched && errorMessage),
              helperText:
                helperText ??
                (isTouched && errorMessage ? errorMessage : undefined),
              fullWidth: true,
              variant: "outlined",
              margin: "normal",
              autoFocus: true,
              sx: {
                m: 0,
                fontSize: "14px",
              },
              InputLabelProps: {
                style: { fontSize: "14px", color: colorConfigs.text },
                shrink: true,
              },
              // Merge the default InputProps with our custom clear icon
              InputProps: {
                ...params.InputProps,
                sx: {
                  fontSize: "14px",
                  "& input": {
                    padding: 0.5,
                    pl: 1,
                  },
                  ...params.InputProps?.sx,
                },
                endAdornment: (
                  <>
                    {params.InputProps?.endAdornment}
                    {field.value ? (
                      <InputAdornment data-testid="InputAdornment-a2rs" position="end">
                        <IconButton data-testid="IconButton-1uv8"
                          onClick={() => form.setFieldValue(field.name, "")}
                          sx={{ padding: 0 }}
                        >
                          <CloseIcon
                            fontSize="small"
                            sx={{
                              fontSize: "12px",
                              "&:hover": { color: "red" },
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ) : null}
                  </>
                ),
              },
            }),
          }}
        />
      </LocalizationProvider>
    );
  };

  interface FormFBSavedSearchSelectFieldProps extends FieldProps {
    options: { value: string | number; label: string; /* include any other filter data if needed */ }[];
    selectTitle: string;
    onDeleteOption?: (option: any) => void;
  }

  export const FormFBSavedSearchSelectField: React.FC<FormFBSavedSearchSelectFieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  // Extract custom onChange from props (if provided) and rest of the props.
  const {
    error,
    helperText,
    field,
    form,
    options,
    selectTitle,
    type,
    onChange: customOnChange,
    onDeleteOption,
    ...rest
  } = props;

  // Create a combined onChange handler.
  const handleChange = (e: any) => {
    // First call the custom onChange if it exists.
    if (customOnChange) {
      customOnChange(e);
    }
    // Then update Formik’s field state.
    field.onChange(e);
  };

  return (
    <TextField data-testid="TextField-hh8o"
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      {...rest}
      {...field}
      // Ensure the value is always defined (empty string instead of undefined)
      value={field.value ?? ""}
      onChange={handleChange}
      fullWidth
      select
      sx={{
        m: 0,
        fontSize: "14px",
        "& Mui.select": {
          padding: 0.5,
          paddingX: 1,
          p: 0,
        },
      }}
      InputLabelProps={{
        style: { fontSize: "14px", color: colorConfigs.text, },
        shrink: true,
      }}
      InputProps={{
        sx: {
          fontSize: "14px",
          "& input": { padding: 0.5, paddingX: 1 },
          "& .MuiOutlinedInput-input": {
            fontSize: "14px",
            padding: 0.5,
            paddingX: 1,
          },
        },
      }}
    >
      <MenuItem data-testid="MenuItem-zipl"
        sx={{ padding: 0.5, paddingX: 1, fontSize: "14px" }}
        key={0}
        value=""
      >
        <em>{selectTitle}</em>
      </MenuItem>
      {options.map((option) => (
       <MenuItem data-testid="MenuItem-8l4l"
          sx={{
            padding: 0.5,
            paddingX: 1,
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          key={option.value}
          value={option.value}
        >
          <span data-testid="span-cce2">{option.label}</span>
          {onDeleteOption && (
            <IconButton data-testid="IconButton-d0l8"
              size="small"
              onClick={e => {
                // Prevent the MenuItem from being selected
                e.stopPropagation();
                onDeleteOption(option);
              }}
            >
              <CloseIcon fontSize="small" sx={{ fontSize: "12px", "&:hover": {
                    color: "red",
                  }, }} />
            </IconButton>
          )}
        </MenuItem>
      ))}
    </TextField>
  );
};