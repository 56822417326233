import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Badge, Box, Button, Grid, IconButton, Link, ListItemIcon, Menu, MenuItem, MenuProps, Paper, Tooltip, Typography, alpha, styled, useMediaQuery } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { InvoiceSessionStorageKey, InvoiceSessionStorageModel, InvoiceTableParameterStorageService } from "../../../services/sessionStorage";
import RefreshIcon from '@mui/icons-material/Refresh';
import colorConfigs from "../../../configs/colorConfigs";
import { Link as RouterLink } from 'react-router-dom';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import { tsXLXS } from 'ts-xlsx-export';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import RareButton from "../buttons/RareButton";

const StyledMenu = styled((props: any) => (
  <Menu data-testid="Menu-zjzp"
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


interface InvoiceTableProps {
  rows: any[];
  isLoading: boolean;
  sorting: any;
  columnVisibility: any;
  columnFilters: any;
  pagination: any;
  globalFilter: any;
  showGlobalFilter: any;
  setShowGlobalFilter: (value: any) => void;
  handleRemoveAllFilters: () => void;
  setGlobalFilter: (value: string) => void;
  setSorting: (sorting: any[]) => void;
  setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
  setColumnFilters: (filters: any[]) => void;
  setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
  setInvoice: (detail: any) => void;
  setPopup: (detail: any) => void;
  handleRefreshButton: () => void;
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({
  rows,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setInvoice,
  setPopup,
  handleRefreshButton
}) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      Header: () => <div data-testid="div-yqya" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        ID<br />&nbsp;
      </div>,
    },
    {
      accessorKey: 'invoice_number',
      header: "Invoice Number",
      Header: () => <div data-testid="div-syup" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Invoice<br />Number
      </div>,
    },
    {
      accessorFn: (row) => row.created ? dayjs(row.created).toISOString() : '',
      id: 'created',
      header: 'Date',
      Header: () => <div data-testid="div-egi5" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Date<br />&nbsp;
      </div>,
      Cell: ({ cell }) => {
        const value = cell.getValue() as string | null;
        return (
          <div data-testid="div-3spc" style={{ whiteSpace: 'pre-wrap' }}>
            {value ? dayjs(value).format('YYYY-MM-DD') : ''}
          </div>
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      Header: () => <div data-testid="div-kthu" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Status<br />&nbsp;
      </div>,
      Cell: ({ cell }) => {
        const value = cell.getValue() as string | null;
        return (
          <div data-testid="div-33rg" style={{ whiteSpace: 'pre-wrap' }}>
            {!value ? "" : value === 'paid' ?
              <Box data-testid="Box-lxv5" sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                justifyContent: 'center'
              }}>
                <Typography data-testid="Typography-9ji3" fontSize="smaller" sx={{ paddingX: 0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Paid</Typography>
              </Box>
              : <></>}
          </div>
        );
      },
    },
    // {
    //   accessorKey: 'total', //This needs to be 0.00 or 10.99 etc and with the correct symbol from the row.currency that we get from strip i.e. "gbp"
    //   header: 'Amout Due',
    //   Header:() => <div data-testid="div-ohyh" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
    //                   Amount<br />Due
    //               </div>, 
    // },
    //   {
    //       accessorKey: 'subscription.start_date',
    //       header: 'Start Date',
    //       Header:() => <div data-testid="div-e6yt" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
    //       Start<br />Date
    //       </div>, 
    //   },
    //   {
    //     accessorKey: 'subscription.end_date',
    //     header: 'Subscription Ends',
    //     Header:() => <div data-testid="div-dia1" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
    //     Start<br />Date
    //     </div>, 
    // },
  ]


  // Define the type for the keys for handling persistance of table settings in session storage
  type InvoiceSessionStorageKeyType = keyof InvoiceSessionStorageModel;

  // Generic handler function for persisting table parameters
  const handleParameterChange = <T,>(
    key: InvoiceSessionStorageKeyType,
    currentValue: T,
    setValueFunction: (value: T) => void,
    newValue: T | ((prevValue: T) => T)
  ) => {
    const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
    InvoiceTableParameterStorageService.set(key, updatedValue);
    setValueFunction(updatedValue);
  };

  // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
  const setSortingFunction = (newSortingValue: any) =>
    handleParameterChange(InvoiceSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
  const setPaginationChangeFunction = (newPaginationValue: any) =>
    handleParameterChange(InvoiceSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
  const setFilterChangeFunction = (newFilterChangeValue: any) =>
    handleParameterChange(InvoiceSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
  const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
    handleParameterChange(InvoiceSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
  const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
    handleParameterChange(InvoiceSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);


  return <MaterialReactTable
    columns={columns}
    data={rows}
    state={{
      isLoading,
      sorting,
      columnFilters,
      pagination,
      globalFilter,
      showGlobalFilter
    }}
    onSortingChange={setSortingFunction}
    onColumnFiltersChange={setFilterChangeFunction}
    onPaginationChange={setPaginationChangeFunction}
    onGlobalFilterChange={setGlobalFilterChangeFunction}
    onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
    defaultColumn={{
      minSize: 10,
      maxSize: 160,
      size: 10,
    }}
    enableColumnOrdering={true}
    positionActionsColumn="last"
    enableRowSelection
    selectAllMode="all"
    enableRowActions
    initialState={{
      sorting,
      columnVisibility: columnVisibility,
      columnFilters,
      pagination,
      globalFilter,
      density: 'compact',
      columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions'] },
    }}

    // TABLE STYLING IS IN THE THEME
    displayColumnDefOptions={{
      'mrt-row-actions': {
        header: "Actions", // Renames Actions Column
      },
    }}

    muiTopToolbarProps={({ table }) => ({
      sx: (theme: any) => ({
        ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
      }),
    })}

    muiTableHeadProps={{
      sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
    }}

    muiTableHeadCellProps={({ column }) => ({
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
        ...(column.id === 'mrt-row-select' && {
          maxWidth: '50px',
          width: '50px',
        }),
      }),
    })}

    muiFilterTextFieldProps={{
      sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
    }}

    muiTableBodyRowProps={({ row }) => ({
      onClick: (event) => {
        console.log(row.original);
        setInvoice(row.original);
        setPopup('Edit');
      },
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
        backgroundColor:
          row.index % 2 === 0
            ? colorConfigs.tables.firstRow
            : colorConfigs.tables.secondRow,
      }),
    })}

    muiTableBodyProps={{
      sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
    }}

    muiPaginationProps={{
      rowsPerPageOptions: [250, 500, 1000],
      sx: {
        padding: 0,
        margin: 0,
        '& .MuiTablePagination-root': {
          padding: 0,
          margin: 0,
        },
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          minHeight: '0px',
        },
        '& .MuiTablePagination-selectLabel': {
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          margin: 0,
        },
      },
    }}

    muiTableBodyCellProps={({ cell }) => ({
      // TABLE BODY CELLS
      sx: {
      },
    })}

    muiBottomToolbarProps={{
      // BOTTOM TOOLBAR (Pagination)
      sx: {
        height: '41px',
        maxHeight: '41px',
        minHeight: '41px',
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: '#fff',
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        margin: 0,
        padding: 0,
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          paddingBottom: 0,
          paddingTop: 0,
        },
        '& .MuiBox-root': {
          p: 0,
        },
        '& .MuiIconButton-root': {
          color: colorConfigs.tables.headBg,
        },
        '& .MuiTablePagination-root': {
          margin: 0,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          color: colorConfigs.buttons.bg,
        },
        '& .MuiTablePagination-selectLabel': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          color: colorConfigs.buttons.bg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
      },
    }}



    renderTopToolbarCustomActions={({ table }) => {

      const handleArchiveCycles = () => {
        table.getSelectedRowModel().flatRows.map(row => {
          //Add archive logic
        });
      };

      const handleExportRows = (rows: any[]) => {
        const excelRows = [];
        const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
        const filename = `${now}_InvoiceList`;

        for (let x = 0; x < rows.length; x++) {
          // const user = rows[x].original;
          // const formattedDateJoined = dayjs(user.date_joined).format('YYYY-MM-DD HH:mm:ss');
          // const formattedLastLogin = user.last_login ? dayjs(user.last_login).format('YYYY-MM-DD HH:mm:ss') : '';

          excelRows[x] = {
            // "ID": user.id,
            // "User Type": user.user_type,
            // "First Name": user.first_name,
            // "Last Name": user.last_name,
            // "Email": user.email,
            // "Organisation ID": user.organistation || '',
            // "Organisation Name": user.organistation_name || '',
            // "Subscription Tier": user.subscription_tier || '',
            // "Date Created": formattedDateJoined,
            // "Last Login": formattedLastLogin,
            // "Active": user.is_active ? "Yes" : "No",
            // "Units": user.units,
            // "Username": user.username || '',
            // "Locale": user.locale || '',
            // "Total Active Dogs": user.total_active_dogs,
            // "Total Dogs": user.total_dogs,
            // "Total Cycles": user.total_cycles,
            // "Total Results": user.total_results,
          };
        }

        tsXLXS().exportAsExcelFile(excelRows).saveAsExcelFile(filename);
      };


      return (
        <Box data-testid="Box-jy5z" sx={{ display: 'flex', gap: '0.3rem', height: "40px", alignItems: 'flex-end' }}>
          <Tooltip data-testid="Tooltip-zgvp" title="Refresh Data" placement="top" arrow>
            <Box data-testid="Box-0j1q">
              <RareButton data-testid="RareButton-wfqb"
                variant="outlined"
                onClick={() => handleRefreshButton()}
                center={true}
                icon={<RefreshIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >
              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-hi0m" title="Clear All Table Filters" placement="top" arrow>
            <Box data-testid="Box-zp05">
              <RareButton data-testid="RareButton-6trj"
                variant="outlined"
                onClick={() => handleRemoveAllFilters()}
                icon={<FilterAltOffOutlinedIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >
              </RareButton>
            </Box>
          </Tooltip>
          {/* <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem data-testid="MenuItem-o9cs" disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                      } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                        Export to Excel
                      </MenuItem>
                    </StyledMenu> */}
        </Box>
      );
    }}

    renderRowActions={({ row, table }) => (

      <Box data-testid="Box-romq" sx={{ gap: 1, display: "flex", justifyContent: 'center' }}>
        <Tooltip data-testid="Tooltip-9ufp"
          arrow
          placement="left"
          title="View Invoice"
        >
          <IconButton data-testid="IconButton-jd81"
            disabled={!row.original.hosted_invoice_url}
            component={RouterLink}
            to={row.original.hosted_invoice_url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ transition: 'transform 0.3s ease' }}
          >
            <VisibilityOutlinedIcon sx={{ color: row.original.hosted_invoice_url ? colorConfigs.sidebar.color : colorConfigs.sidebar.bg }} />
          </IconButton>
        </Tooltip>
        <Tooltip data-testid="Tooltip-wjn4"
          arrow
          placement="left"
          title="Download Invoice"
        >
          <IconButton data-testid="IconButton-ms1y"
            disabled={!row.original.invoice_pdf}
            component={RouterLink}
            to={row.original.invoice_pdf}
            sx={{ transition: 'transform 0.3s ease' }}
          >
            <FileDownloadOutlinedIcon sx={{ color: row.original.invoice_pdf ? colorConfigs.sidebar.color : colorConfigs.sidebar.bg }} />
          </IconButton>
        </Tooltip>
      </Box>
    )}
  />
}

export default InvoiceTable;

