import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Field } from "formik";
import { FormSwitch } from "../../../components/common/fields/ProfileFeilds";
import { SmallTextEditField, TextEditField } from "../fields/fields";
import colorConfigs from "../../../configs/colorConfigs";

type ReaderConfigurationProps = {
  create?: boolean;
  formik: {
    handleChange: (event: React.ChangeEvent<any>) => void;
    values: {
      collection_mode?: boolean;
      control_exposure?: boolean;
      control_baseline?: boolean;
    };
  };
};

const ReaderConfiguration: React.FC<ReaderConfigurationProps> = ({
  formik,
  create,
}) => {
  return (
    <Box data-testid="Box-glwm"
      sx={{
        p: 2,
        pt: 0,
        mb: 3, // margin-bottom
      }}
    >
      <Box sx={{ height: "55px", display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>Reader&nbsp;Configuration</Typography>
      </Box>
      <Accordion data-testid="Accordion-ej7r">
        <AccordionSummary data-testid="AccordionSummary-xrob" expandIcon={<ExpandMoreIcon />}>
          <Typography data-testid="Typography-y6aj" variant="subtitle1" fontWeight="bold" sx={{ color: colorConfigs.primary }}>
            Data Collection
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div data-testid="div-0h1g"
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "1rem",
              // width: "100%",
            }}
          >
            <Field data-testid="Field-ae4w"
              disabled={create}
              name="collection_mode"
              type="checkbox"
              label="Data Collection Mode"
              component={FormSwitch}
              visible={false}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      {/* Reader Parameters Section */}
      <Accordion data-testid="Accordion-ez8m">
        <AccordionSummary data-testid="AccordionSummary-bw54" expandIcon={<ExpandMoreIcon />}>
          <Typography data-testid="Typography-anop" variant="subtitle1" fontWeight="bold" sx={{ color: colorConfigs.primary }}>
            Reader Parameters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div data-testid="div-613x"
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "1rem",
              // width: "100%",
            }}
          >
            <Field data-testid="Field-evdg"
              name="frames_to_capture"
              type="number"
              label="Frames to Capture"
              component={SmallTextEditField}
              onChange={formik.handleChange}
            />
            <Field data-testid="Field-tltg"
              name="flash_required"
              disabled={create}
              type="checkbox"
              label="Flash Required"
              component={FormSwitch}
            />
            {!formik.values.collection_mode && (
              <>
                <Field data-testid="Field-zwu7"
                  name="control_exposure"
                  type="checkbox"
                  label="Control Exposure"
                  component={FormSwitch}
                />
                {formik.values.control_exposure && (
                  <>
                    <Field data-testid="Field-bjbm"
                      name="minimumProfileControlLevel"
                      type="number"
                      label="Minimum Profile Control Level"
                      component={SmallTextEditField}
                      onChange={formik.handleChange}
                    />
                    <Field data-testid="Field-ylf2"
                      name="maximumProfileControlLevel"
                      type="number"
                      label="Maximum Profile Control Level"
                      component={SmallTextEditField}
                      onChange={formik.handleChange}
                    />
                  </>
                )}
                <Field data-testid="Field-zjlg"
                  name="control_line_correction"
                  type="checkbox"
                  label="Control Line Correction"
                  component={FormSwitch}
                />
                <Field data-testid="Field-gudz"
                  name="profile_alignment"
                  type="checkbox"
                  label="Profile Alignment"
                  component={FormSwitch}
                />

                <Field data-testid="Field-xec4"
                  name="polynomial_baseline_order"
                  type="number"
                  label="Polynomial Baseline Order"
                  component={SmallTextEditField}
                  onChange={formik.handleChange}
                />
                <Field data-testid="Field-2yk8"
                  name="initial_h_factor"
                  type="number"
                  disabled={create}
                  label="Initial H Scale Factor"
                  component={SmallTextEditField}
                  onChange={formik.handleChange}
                />
                <Field data-testid="Field-q9jg"
                  name="control_baseline"
                  type="checkbox"
                  label="Control Baseline"
                  component={FormSwitch}
                />
                {formik.values.control_baseline && (
                  <Field data-testid="Field-5lqc"
                    name="control_baseline_threshold"
                    type="number"
                    label="Control Baseline Threshold"
                    component={SmallTextEditField}
                    onChange={formik.handleChange}
                  />
                )}
              </>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ReaderConfiguration;
