import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import colorConfigs from '../../../configs/colorConfigs';


interface UsageGraphProps {
  data: any[]
}

const UsageGraph: React.FC<UsageGraphProps> = ({ data }) => {
  // Map the provided data to extract the series values and categories.
  const seriesData = data.map(item => item.y)
  const categories = data.map(item => item.x)

  const series = [
    {
      name: 'Results',
      data: seriesData,
    },
  ]

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 250,
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: [
      colorConfigs.dashboard.dark_red,
      colorConfigs.dashboard.light_grey,
    ],
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: 'Results',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return `${val} results`
        },
      },
    },
  }

  return (
    <div data-testid="div-7w5y">
      <div data-testid="div-r8xt" id="chart">
        <ReactApexChart
          type="bar"
          options={options}
          series={series}
          height={options.chart?.height}
        />
      </div>
      <div data-testid="div-60v5" id="html-dist"></div>
    </div>
  )
}

export default UsageGraph