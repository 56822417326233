import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import colorConfigs from "../../../configs/colorConfigs";

interface DatarunStatusCardProps {
  total_results: number;
  error_results: number;
  successful_results: number;
}

const DatarunStatusCard: React.FC<DatarunStatusCardProps> = ({
  total_results,
  error_results,
  successful_results,
}) => {
  let tooltipColor = "";

  if (total_results === 0 || successful_results === 0) {
    tooltipColor = colorConfigs.buttons.cancel; // Red: All errors
  } else if (error_results === 0) {
    tooltipColor = colorConfigs.buttons.success; // Green: All successful
  } else {
    tooltipColor = colorConfigs.buttons.warning; // Orange (mixed results)
  }

  // Dynamically create a theme with the provided tooltip background color
  const customTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: tooltipColor, // Custom theme color
            color: "#fff", // White text
          },
          arrow: {
            color: tooltipColor, // Ensure the arrow matches the tooltip color
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <Tooltip
        data-testid="Tooltip-sykq"
        arrow
        placement="left"
        title={
          <Box>
            <Typography
              data-testid="Typography-cb00"
              sx={{ fontSize: "14px", fontWeight: "bold", color: "#fff" }}
            >
              Generated Results: {total_results}
            </Typography>
            <Typography
              data-testid="Typography-ozq0"
              sx={{ fontSize: "14px", fontWeight: "bold", color: "#fff" }}
            >
              Rejected: {error_results}
            </Typography>
            <Typography
              data-testid="Typography-axiq"
              sx={{ fontSize: "14px", fontWeight: "bold", color: "#fff" }}
            >
              Successful: {successful_results}
            </Typography>
          </Box>
        }
      >
        <Typography
          data-testid="Typography-z7fb"
          fontSize="smaller"
          sx={{
            paddingX: 0.7,
            border: `1.5px solid ${tooltipColor}`,
            borderRadius: 2,
            color: tooltipColor,
          }}
        >
          Complete
        </Typography>
      </Tooltip>
    </ThemeProvider>
  );
};

export default DatarunStatusCard;
