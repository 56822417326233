import { Navigate, useNavigate } from "react-router-dom";
import { Box, Button, Card, Chip, CircularProgress, Collapse, Container, Divider, Grid, Grid2, linearProgressClasses, Link, Paper, Tab, Table, TableCell, tableCellClasses, TableRow, Tabs, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { last5columns, organisationColumns } from '../../components/common/tables/Columns';
import api from "../../redux/features/auth/axiosAuthHelper";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { refreshAccessToken } from "../../redux/features/auth/authSlice";
import dayjs from 'dayjs';
import colorConfigs from "../../configs/colorConfigs";
import ResultDetailGraph from "../../components/common/graphs/ResultDetailGraph";
import { LatestDCMSessionStorageKey, LatestDCMTableParameterStorageService } from "../../services/sessionStorage";
import LastFiveResultsTable from "../../components/common/tables/LastFiveResultsTable";
import RecentDCMResultsTable from "../../components/common/tables/RecentDCMResultsTable";
import { Field, FormikProvider, useFormik } from "formik";
import fontConfigs from "../../configs/fontConfigs";
import { ResultDetailTableDashboardField } from "../../components/common/fields/fields";
import dummyresultdata from "../../components/common/graphs/resultdata";
import * as Yup from "yup";
import TestsGraph from "../../components/common/graphs/TestInsightsGraph";
import UsageGraph from "../../components/common/graphs/UsageGraph";
import ResultSourceRadarGraph from "../../components/common/graphs/ResultSourceRadarGraph";
import ResultSourceRadialGraph from "../../components/common/graphs/ResultSourceRadialGraph";
import RareChip from "../../components/common/buttons/RareChip";



type Props = {}

interface LatestDCM {
  id: string;
  organisation_id: number | null;
  user_id: number | null;
  timestamp: string;
  sample_id: string;
  method_id: number | null;
  housing_id: number | null;
  test_configuration: {
    collection_mode: boolean | null;
  }
}

interface LineData {
  score: number | string;
  peak_position: number | string;
  tc_ratio: number | string;
  t_color?: string; // Color for each T-peak line
}

interface GraphData {
  test_lines: LineData[];        // Data for test lines with T-peaks
  cscore: number | string;       // Score for the C-line
  cpeak: number | string;        // Peak position for the C-line
  c_color?: string;               // Color for the C-peak annotation
  profile: any[];                // Mean profile data
  baseline: any[];               // Mean baseline data
  baseline_color: string;        // Color for the mean baseline series
  profile_color: string;         // Color for the mean profile series
  meanProfileVisible: boolean;   // Whether the mean profile/baseline is visible
  baselines: any[];           // Array of frame data (each frame's baseline)
  profiles: any[];         // Array of frame data (each frame's profile)
}


interface SortingState {
  id: string;
  desc?: boolean;
}

interface ColumnVisibilityState {
  [key: string]: boolean;
}

interface FilterState {
  id: string;
  value: string;
}

interface TableState {
  sorting: SortingState[];
  columnVisibility: ColumnVisibilityState;
  columnFilters: FilterState[];
  pagination: { pageSize: number; pageIndex: number };
}

// Initial state
const initialState: TableState = {
  sorting: [],
  columnVisibility: {},
  columnFilters: [],
  pagination: { pageSize: 50, pageIndex: 0 },
};

const HomePage = (props: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('result');
  const [sourceValue, setSourceValue] = useState('radar')
  const [recentRows, setRecentRows] = useState<any>();
  const [latestInvoice, setLatestInvoice] = useState<any>();
  const [invoiceExpired, setInvoiceExpired] = useState<any>('false');
  const [methodCount, setMethodCount] = useState<any>();
  const [housingCount, setHousingCount] = useState<any>();
  const [userCount, setUserCount] = useState<any>();

  const [last10DCAResults, setLast10DCAResults] = useState<any[]>([]);
  const [latestNormalResult, setLatestNormalResult] = useState<any>({});
  const [resultPlatformPieChartData, setResultPlatformPieChartData] = useState<any>({});
  const [resultDevicePieChartData, setResultDevicePieChartData] = useState<any>({});
  const [usageTableData, setUsageTableData] = useState<any[]>([]);


  const [graphData, setGraphData] = useState<any>({
    test_lines: [],
    cscore: '',
    cpeak: '',
    profile: [''],
    baseline: [''],
    c_color: '',
    baseline_color: '',
    profile_color: '',
    profiles: [],
    baselines: [],
    meanProfileVisible: false,
  })

  const authData = useSelector((state: RootState) => state.auth);

  const validationSchema = () => {
    return Yup.object().shape({
    });
  }
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      app_build: '',
      app_version: '',
      device_model: '',
      device_uuid: '',
      full_image: '',
      id: '',
      latitude: '',
      longitude: '',
      notes: '',
      organisation_id: '',
      os_version: '',
      sample_id: '',
      sample_type: '',

      strip_image_paths: [],
      // Test lines
      baseline: '',
      cline_score: '',
      cline_peak_position: '',
      cline_peak_area: '',
      // Lines
      t_lines: [],
      tline_1_score: '',
      tline_1_area: '',
      tline_1_peak_position: '',
      tline_1_ratio: '',
      tline_2_score: '',
      tline_2_area: '',
      tline_2_peak_position: '',
      tline_2_ratio: '',
      profile: '',

      timestamp: '',
      user_id: '',
      uuid: '',
      tc_area_ratio: '',

      // User Data
      result_user_id: '',
      user_first_name: '',
      user_last_name: '',
      user_email: '',
      user_is_active: '',
      user_organisation_id: '',
      user_organisation_name: '',
      user_user_type: '',
      user_subscription_tier: '',

      camera_macro_focus_required: false,
      collection_mode: false,
      control_baseline: false,
      control_baseline_threshold: '',
      control_exposure: false,
      control_line_correction: false,
      flash_required: false,
      frames_to_capture: '',
      initial_h_factor: '',
      polynomial_baseline_order: '',
      profile_alignment: false,
      profile_control_levels: '',
      reader_resolution: [],
      test_pmf_classifier_file: '',
      max_conformance_probability: '',
      min_conformance_probability: '',

      normal_result_method_id: '',
      normal_result_housing_id: ''
    },
    validationSchema,
    onSubmit: (values) => {
    },
  });
  // Set Graph data for normal result
  useEffect(() => {
    if (latestNormalResult?.id) {

      const dynamicProfiles: any[] = [];
      const dynamicBaselines: any[] = [];

      // Add mean profile and baseline if visible
      dynamicProfiles.push({ data: latestNormalResult.test_strips[0]?.mean_profile, color: colorConfigs.result.meanProfile, name: "Mean Profile" });
      dynamicBaselines.push({ data: latestNormalResult.test_strips[0]?.baseline, color: colorConfigs.result.meanBaseline, name: "Mean Baseline" });

      setGraphData({
        test_lines: latestNormalResult.test_strips[0]?.t_lines,
        cscore: latestNormalResult.test_strips[0]?.c_line?.score || 0,
        cpeak: latestNormalResult.test_strips[0]?.c_line?.peak_position || 0,
        c_color: latestNormalResult.test_strips[0]?.c_color,
        profile: latestNormalResult.test_strips[0]?.mean_profile,
        baseline: latestNormalResult.test_strips[0]?.baseline,
        baseline_color: colorConfigs.result.meanBaseline,
        profile_color: colorConfigs.result.meanProfile,
        profiles: dynamicProfiles, // Initialize as empty
        baselines: dynamicBaselines, // Initialize as empty
        meanProfileVisible: true
      });

      formik.setFieldValue('normal_result_method_id', latestNormalResult.method_id);
      formik.setFieldValue('normal_result_housing_id', latestNormalResult.housing_id);

      // Test Strips
      formik.setFieldValue('cline_score', latestNormalResult.test_strips[0].c_line.score.toFixed(2));
      formik.setFieldValue('cline_peak_position', latestNormalResult.test_strips[0].c_line.peak_position.toFixed(2));
      formik.setFieldValue('cline_area', latestNormalResult.test_strips[0].c_line.area?.toFixed(2));

      // Setting lines as an array to dynamically render these
      formik.setFieldValue('t_lines', latestNormalResult.test_strips[0].t_lines);

      // Dynaically sets tline score, peak and tc_ratio
      latestNormalResult.test_strips[0].t_lines.map((line: any, index: any, array: any[]) => {
        formik.setFieldValue(`tline_${array.length - index}_score`, parseFloat(line.score).toFixed(2));
        formik.setFieldValue(`tline_${array.length - index}_peak_position`, parseFloat(line.peak_position).toFixed(2));
        formik.setFieldValue(`tline_${array.length - index}_ratio`, parseFloat(line.tc_ratio).toFixed(2));
        formik.setFieldValue(`tline_${array.length - index}_area`, parseFloat(line.area)?.toFixed(2));
        const TCAreaRatio = parseFloat(line.area) / latestNormalResult.test_strips[0].c_line.area
        formik.setFieldValue(`tline_${array.length - index}_tc_area_ratio`, TCAreaRatio.toFixed(2));
      })

      setIsLoading(false);
    }
  }, [latestNormalResult])

  // Fetch all dashboard data on mount
  useEffect(() => {
    if (!authData.access) return;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const results = await Promise.allSettled([
          api.get('/results/dashboard/stats', {
            headers: { Authorization: `Bearer ${authData.access}` },
          }),
          api.get('/results/views', {
            headers: { Authorization: `Bearer ${authData.access}` },
          }),
          api.get('/payments/invoices/', {
            headers: { Authorization: `Bearer ${authData.access}` },
          }),
          api.get('/methods/?limit=1', {
            headers: { Authorization: `Bearer ${authData.access}` },
          }),
          api.get('/housing/?limit=1', {
            headers: { Authorization: `Bearer ${authData.access}` },
          }),
          api.get('/users/?limit=1', {
            headers: { Authorization: `Bearer ${authData.access}` },
          })
        ]);

        // Check each result individually
        if (results[0].status === 'fulfilled') {
          const statsRes = results[0].value;
          setLast10DCAResults(statsRes.data.last_10_dca_results);
          setUsageTableData(statsRes.data.usage);
          setLatestNormalResult(statsRes.data.latest_normal_result);
          setResultPlatformPieChartData(statsRes.data.result_source_data);
          setResultDevicePieChartData(statsRes.data.result_device_data);
        } else {
          console.error('Error fetching stats:', results[0].reason);
        }

        if (results[1].status === 'fulfilled') {
          const viewsRes = results[1].value;
          setRecentRows(viewsRes.data.results);
        } else {
          console.error('Error fetching views:', results[1].reason);
        }

        if (results[2].status === 'fulfilled') {
          const invoicesRes = results[2].value;
          const invoiceVal = invoicesRes.data.results[0]?.current_period_end;
          setLatestInvoice(invoiceVal);
          if (invoiceVal) {
            let invoiceDateDiff = new Date().getTime() - invoiceVal;
            if (invoiceDateDiff > 0) {
              setInvoiceExpired('true');
            }
          }
        } else {
          console.error('Error fetching invoices:', results[2].reason);
        }

        if (results[3].status === 'fulfilled') {
          const methodsRes = results[3].value;
          setMethodCount(methodsRes.data.count);
        } else {
          console.error('Error fetching methods:', results[3].reason);
        }

        if (results[4].status === 'fulfilled') {
          const housingRes = results[4].value;
          setHousingCount(housingRes.data.count);
        } else {
          console.error('Error fetching housing:', results[4].reason);
        }

        if (results[5].status === 'fulfilled') {
          const usersRes = results[5].value;
          setUserCount(usersRes.data.count);
        } else {
          console.error('Error fetching users:', results[5].reason);
        }
      } catch (err) {
        console.error('Unexpected error:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [authData.access, dispatch]);



  const handleTabsChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleSourceTabsChange = (event: React.SyntheticEvent, newValue: string) => {
    setSourceValue(newValue);
    console.log(sourceValue)
  };


  if (!authData.access || isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (authData.isFreeUser) return <Navigate to="/account/subscription" />;

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column' }}>
            {/* Card Header */}
            <Box
              sx={{
                background: colorConfigs.tables.headBg,
                p: 0.5,
                px: 1.5,
                borderRadius: '4px 4px 0 0',
              }}
            >
              <Typography sx={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', }}>
                Latest Result
              </Typography>
            </Box>
            {/* Tabs */}
            <Tabs value={value} onChange={handleTabsChange} textColor="secondary" indicatorColor="secondary">
              <Tab value="result" label="Result" />
              <Tab value="dcm" label="Data Collection" />
            </Tabs>
            {value === 'result' ? (
              <Collapse in={!!graphData?.test_lines?.length}>
                <Box sx={{ p: 0, display: 'flex', justifyContent: 'center' }}>
                  <ResultDetailGraph graphdata={graphData} showGraph={true} />
                </Box>
                <FormikProvider value={formik}>
                  <Table
                    sx={{
                      width: '100%',
                      borderCollapse: 'separate',
                      // borderSpacing: '0 8px',
                    }}
                  >
                    <TableRow>
                      <TableCell sx={{ py: 1 }}>
                        <Typography sx={{ ...fontConfigs.detailPage.headings.label, color: colorConfigs.dashboard.dark_grey }}>
                          Housing ID
                        </Typography>
                      </TableCell>
                      <TableCell align="right" sx={{ py: 1 }}>
                        <RareChip variant="success">{formik.values.normal_result_housing_id}</RareChip>
                      </TableCell>
                      <TableCell sx={{ py: 1 }}>
                        <Typography sx={{ ...fontConfigs.detailPage.headings.label, color: colorConfigs.dashboard.dark_grey }}>
                          Method ID
                        </Typography>
                      </TableCell>
                      <TableCell align="right" sx={{ py: 1 }}>
                        <RareChip variant="success">{formik.values.normal_result_method_id}</RareChip>
                      </TableCell>
                    </TableRow>
                  </Table>

                </FormikProvider>
              </Collapse>
            ) : (
              <Box >
                <RecentDCMResultsTable authData={authData} rows={last10DCAResults} />
              </Box>
            )}
          </Card>
          <Box mt={3}>
            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  background: colorConfigs.tables.headBg,
                  p: 0.5,
                  px: 1.5,
                  borderRadius: '4px 4px 0 0',
                }}
              >
                <Typography sx={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', }}>
                  Recently Viewed Results
                </Typography>
              </Box>
              <Box sx={{ p: 0 }}>
                <LastFiveResultsTable authData={authData} rows={recentRows} />
              </Box>
            </Card>
          </Box>
        </Grid>

        {/* Middle Column */}
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                background: colorConfigs.tables.headBg,
                p: 0.5,
                px: 1.5,
                borderRadius: '4px 4px 0 0',
              }}
            >
              <Typography sx={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', }}>
                TC Height Distribution
              </Typography>
            </Box>
            <Box sx={{ px: 2, pt: 1 }}>
              <TestsGraph />
            </Box>
          </Card>
          <Box mt={3}>
            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  background: colorConfigs.tables.headBg,
                  p: 0.5,
                  px: 1.5,
                  borderRadius: '4px 4px 0 0',
                }}
              >
                <Typography sx={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', }}>
                  Result Source
                </Typography>
              </Box>
              <Tabs value={sourceValue} onChange={handleSourceTabsChange} textColor="secondary" indicatorColor="secondary">
                <Tab value="radar" label="Radar" />
                <Tab value="radial" label="Radial" />
              </Tabs>
              <Box sx={{ p: 0 }}>
                {sourceValue === 'radar' ? (
                  <ResultSourceRadarGraph data={resultDevicePieChartData} />
                ) : (
                  <ResultSourceRadialGraph data={resultDevicePieChartData} />
                )}
              </Box>
            </Card>
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                background: colorConfigs.tables.headBg,
                p: 0.5,
                px: 1.5,
                borderRadius: '4px 4px 0 0',
              }}
            >
              <Typography sx={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', }}>
                Usage
              </Typography>
            </Box>
            <Box sx={{ p: 0 }}>
              <Table
                sx={{
                  width: '100%',
                  borderCollapse: 'separate',
                  // borderSpacing: '0 8px',
                }}
              >
                <TableRow>
                  <TableCell sx={{ py: 1 }}>
                    <Typography sx={{ ...fontConfigs.detailPage.headings.label, color: colorConfigs.dashboard.dark_grey }}>
                      Tier
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ py: 1 }}>
                    <RareChip variant="success">{authData.tier}</RareChip>
                  </TableCell>
                </TableRow>


                <TableRow>
                  <TableCell sx={{ py: 1 }}>
                    <Typography sx={{ ...fontConfigs.detailPage.headings.label, color: colorConfigs.dashboard.dark_grey }}>
                      Next Invoice
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ py: 1 }}>
                    <RareChip data-testid="RareChip-sqah" variant={invoiceExpired == 'true' ? 'cancel' : latestInvoice ? "success" : 'cancel'}>{latestInvoice ? latestInvoice : 'N/A'}</RareChip>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ py: 1 }}>
                    <Typography sx={{ ...fontConfigs.detailPage.headings.label, color: colorConfigs.dashboard.dark_grey }}>
                      Housings
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ py: 1 }}>
                    <RareChip data-testid="RareChip-e6dm" variant='success'>{housingCount}</RareChip>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ py: 1 }}>
                    <Typography sx={{ ...fontConfigs.detailPage.headings.label, color: colorConfigs.dashboard.dark_grey }}>
                      Methods
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ py: 1 }}>
                    <RareChip data-testid="RareChip-r3pg" variant='success'>{methodCount}</RareChip>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ py: 1 }}>
                    <Typography sx={{ ...fontConfigs.detailPage.headings.label, color: colorConfigs.dashboard.dark_grey }}>
                      Users
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ py: 1 }}>
                    <RareChip data-testid="RareChip-19qm" variant='success'>{userCount}</RareChip>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ py: 1 }}>
                    <Typography sx={{ ...fontConfigs.detailPage.headings.label, color: colorConfigs.dashboard.dark_grey }}>
                      Processing Units
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ py: 1 }}>
                    <RareChip data-testid="RareChip-60yj" variant='success'>285/300</RareChip>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ py: 1 }}>
                    <Typography sx={{ ...fontConfigs.detailPage.headings.label, color: colorConfigs.dashboard.dark_grey }}>
                      Data Capacity
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ py: 1 }}>
                    <RareChip data-testid="RareChip-3mug" variant='success'>145MB/450MB</RareChip>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ py: 1 }}>
                    <Typography sx={{ ...fontConfigs.detailPage.headings.label, color: colorConfigs.dashboard.dark_grey }}>
                      No. of Results
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ py: 1 }}>
                    <RareChip data-testid="RareChip-b8ms" variant='success'>230</RareChip>
                  </TableCell>
                </TableRow>

              </Table>
            </Box>
          </Card>
          <Box mt={3}>
            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  background: colorConfigs.tables.headBg,
                  p: 0.5,
                  px: 1.5,
                  borderRadius: '4px 4px 0 0',
                }}
              >
                <Typography sx={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', }}>
                  Usage Table
                </Typography>
              </Box>
              <Box sx={{ p: 2, pt: 4 }}>
                <UsageGraph data={usageTableData} />
              </Box>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;