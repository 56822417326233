import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, Typography, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled, Theme, Switch, IconButton, Tooltip, FormControlLabel, useMediaQuery } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ResultSessionStorageKey, ResultSessionStorageModel, ResultTableParameterStorageService } from "../../../services/sessionStorage";
import colorConfigs from "../../../configs/colorConfigs";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { tsXLXS } from 'ts-xlsx-export';
import Crop169Icon from '@mui/icons-material/Crop169';
import { MRT_FilterFns } from 'material-react-table';
import BiotechIcon from '@mui/icons-material/Biotech';
import CheckIcon from '@mui/icons-material/Check';
import { statusLabels } from "../CustomHooks";
import api from "../../../redux/features/auth/axiosAuthHelper";
import NotificationSnackbarCard, { parseErrors } from "../cards/NotificationSnackbarCard";
import RareButton from "../buttons/RareButton";

const StyledMenu = styled((props: any) => (
  <Menu data-testid="Menu-6rpz"
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


interface MethodsTableProps {
  authData: any;
  rows: any[];
  isLoading: boolean;
  sorting: any;
  columnVisibility: any;
  columnFilters: any;
  pagination: any;
  globalFilter: any;
  showGlobalFilter: any;
  setShowGlobalFilter: (value: any) => void;
  handleRemoveAllFilters: () => void;
  setGlobalFilter: (value: string) => void;
  setSorting: (sorting: any[]) => void;
  setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
  setColumnFilters: (filters: any[]) => void;
  setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
  openEditPage: (detail: any) => void;
  openCreatePage: () => void;
  handleRefreshButton: () => void;
}

const MethodsTable: React.FC<MethodsTableProps> = ({
  authData,
  rows,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  openEditPage,
  openCreatePage,
  handleRefreshButton
}) => {

  console.log("Methods", rows)
  const isMobile = useMediaQuery('(max-width:768px)');

  // For Snack Bar
  const [errorQueue, setErrorQueue] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const customOrganisationFilter = (row: any, columnId: any, filterValue: any) => {
    const { organisation_name, organisation_id } = row.original;
    const searchValue = filterValue.toLowerCase();
    return (
      organisation_name.toLowerCase().includes(searchValue) ||
      organisation_id.toString().includes(searchValue)
    );
  };

  const customStatusFilter = (row: any, columnId: any, filterValue: any) => {
    const normalize = (str: string) => str.toLowerCase().replace(/_/g, ' ').trim(); // Convert to lowercase, replace underscores with spaces, and trim
    const status = normalize(row.getValue(columnId)?.toString() || ""); // Normalize the status value

    // Normalize filterValue to handle both single strings and arrays
    const normalizedFilterValues = Array.isArray(filterValue)
      ? filterValue.map((value) => normalize(value))
      : [normalize(filterValue)];
    // Check if any of the normalized filter values are partially included in the status
    return normalizedFilterValues.some((value) => status.includes(value));
  };

  const filterFns = {
    ...MRT_FilterFns,
    customOrganisationFilter,
    customStatusFilter, // Add your custom status filter
  };


  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      Header: () => <div data-testid="div-rz8i" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Method<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'test_name',
      header: 'Name',
      Header: () => <div data-testid="div-4vt0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Name<br />&nbsp;
      </div>,
    },
    {
      accessorKey: 'description',
      header: 'Description',
      Header: () => <div data-testid="div-4vt0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Description<br />&nbsp;
      </div>,
      Cell: ({ row }) => {
        const fullText = row.original.description;
        return (
          <div data-testid="div-hh9n"
            style={{
              minWidth: '250px',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              overflow: 'visible',
              lineHeight: '1.5',
            }}
          >
            {fullText}
          </div>
        );
      },
    },


    {
      accessorKey: 'version',
      header: 'Version',
      Header: () => <div data-testid="div-tjew" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Version<br />&nbsp;
      </div>,
    },
    {
      accessorFn: (row) => dayjs(row.date_created).format('YYYY-MM-DD HH:mm:ss'),
      header: 'Date Created',
      Header: () => <div data-testid="div-7d8m" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Date<br />Created
      </div>,
      Cell: ({ cell }) => (
        <div data-testid="div-f0rq" style={{ whiteSpace: 'pre-wrap' }}>
          {cell.getValue()?.toString().split(' ').join('\n')}
        </div>
      ),
    },
    {
      accessorFn: (row) => dayjs(row.date_updated).format('YYYY-MM-DD HH:mm:ss'), // Change to updated
      header: 'Date Updated',
      Header: () => <div data-testid="div-3u38" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Updated<br />Date
      </div>,
      Cell: ({ cell }) => (
        <div data-testid="div-1too" style={{ whiteSpace: 'pre-wrap' }}>
          {cell.getValue()?.toString().split(' ').join('\n')}
        </div>
      ),
    },
    {
      accessorKey: 'organisation_name', // Base accessor
      header: "organisation",
      Header: () => (
        <div data-testid="div-5pni" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Organisation<br />&nbsp;
        </div>
      ),
      Cell: ({ row }) => (
        <div data-testid="div-qrwv">
          {row.original.organisation_id} {row.original.organisation_name}
        </div>
      ),
      enableColumnFilter: true,
      filterFn: 'customOrganisationFilter',
    },
    {
      accessorKey: 'status',
      header: 'Status',
      Header: () => <div data-testid="div-m78u" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Status<br />&nbsp;
      </div>,
      Cell: ({ cell }) => (
        <div data-testid="div-lj82" style={{ display: "flex", justifyContent: "center" }}>
          {cell.getValue() == "ACTIVE" ?
            <Typography data-testid="Typography-f9ae" fontSize="smaller" sx={{ paddingX: 0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography>
            : cell.getValue() == "INACTIVE" ? <Typography data-testid="Typography-s0yz" fontSize="smaller" sx={{ paddingX: 0.7, border: `1.5px solid ${colorConfigs.buttons.inactive}`, borderRadius: 2, color: colorConfigs.buttons.inactive }} >Inactive</Typography>
              : cell.getValue() == "IN_PROGRESS" ? <Typography data-testid="Typography-a87c" fontSize="smaller" sx={{ paddingX: 0.7, border: `1.5px solid ${colorConfigs.buttons.in_progress}`, borderRadius: 2, color: colorConfigs.buttons.in_progress }} >In Progress</Typography>
                : cell.getValue() == "DATARUN" ? <Typography data-testid="Typography-a95k" fontSize="smaller" sx={{ paddingX: 0.7, border: `1.5px solid ${colorConfigs.text}`, borderRadius: 2, color: colorConfigs.text }} >Datarun Method</Typography>
                  : null
          }
        </div>
      ),
      filterFn: 'customStatusFilter',
    },
  ]


  // Define the type for the keys for handling persistance of table settings in session storage
  type ResultSessionStorageKeyType = keyof ResultSessionStorageModel;

  // Generic handler function for persisting table parameters
  const handleParameterChange = <T,>(
    key: ResultSessionStorageKeyType,
    currentValue: T,
    setValueFunction: (value: T) => void,
    newValue: T | ((prevValue: T) => T)
  ) => {
    const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
    ResultTableParameterStorageService.set(key, updatedValue);
    setValueFunction(updatedValue);
  };

  // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
  const setSortingFunction = (newSortingValue: any) =>
    handleParameterChange(ResultSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
  const setPaginationChangeFunction = (newPaginationValue: any) =>
    handleParameterChange(ResultSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
  const setFilterChangeFunction = (newFilterChangeValue: any) =>
    handleParameterChange(ResultSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
  const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
    handleParameterChange(ResultSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
  const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
    handleParameterChange(ResultSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);



  return <MaterialReactTable
    columns={columns}
    data={rows}
    filterFns={filterFns}
    state={{
      isLoading,
      sorting,
      columnFilters,
      pagination,
      globalFilter,
      showGlobalFilter
    }}
    onSortingChange={setSortingFunction}
    onColumnFiltersChange={setFilterChangeFunction}
    onPaginationChange={setPaginationChangeFunction}
    onGlobalFilterChange={setGlobalFilterChangeFunction}
    onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
    defaultColumn={{
      minSize: 10,
      maxSize: 160,
      size: 10,
    }}
    enableColumnOrdering={true}
    positionActionsColumn="last"
    enableRowSelection
    selectAllMode="all"
    enableRowActions
    initialState={{
      sorting,
      columnVisibility,
      columnFilters,
      pagination,
      globalFilter,
      density: 'compact',
      columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions'] },
    }}

    // TABLE STYLING IS IN THE THEME
    displayColumnDefOptions={{
      'mrt-row-actions': {
        header: "Actions", // Renames Actions Column
      },
    }}

    muiTopToolbarProps={({ table }) => ({
      sx: (theme: any) => ({
        ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
      }),
    })}

    muiTableHeadProps={{
      sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
    }}

    muiTableHeadCellProps={({ column }) => ({
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
        ...(column.id === 'mrt-row-select' && {
          maxWidth: '50px',
          width: '50px',
        }),
      }),
    })}

    muiFilterTextFieldProps={{
      sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
    }}

    muiTableBodyRowProps={({ row }) => ({
      onClick: (event) => {
        console.log(row.original);
        openEditPage(row);
      },
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
        backgroundColor:
          row.index % 2 === 0
            ? colorConfigs.tables.firstRow
            : colorConfigs.tables.secondRow,
      }),
    })}

    muiTableBodyProps={{
      sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
    }}

    muiPaginationProps={{
      rowsPerPageOptions: [250, 500, 1000],
      sx: {
        padding: 0,
        margin: 0,
        '& .MuiTablePagination-root': {
          padding: 0,
          margin: 0,
        },
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          minHeight: '0px',
        },
        '& .MuiTablePagination-selectLabel': {
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          margin: 0,
        },
      },
    }}

    muiTableBodyCellProps={({ cell }) => ({
      // TABLE BODY CELLS
      sx: {
      },
    })}

    muiBottomToolbarProps={{
      // BOTTOM TOOLBAR (Pagination)
      sx: {
        height: '41px',
        maxHeight: '41px',
        minHeight: '41px',
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: '#fff',
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        margin: 0,
        padding: 0,
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          paddingBottom: 0,
          paddingTop: 0,
        },
        '& .MuiBox-root': {
          p: 0,
        },
        '& .MuiIconButton-root': {
          color: colorConfigs.tables.headBg,
        },
        '& .MuiTablePagination-root': {
          margin: 0,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          color: colorConfigs.buttons.bg,
        },
        '& .MuiTablePagination-selectLabel': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          color: colorConfigs.buttons.bg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
      },
    }}


    renderTopToolbarCustomActions={({ table }) => {

      const handleArchiveResults = () => {
        table.getSelectedRowModel().flatRows.map(row => {
          //Add archive logic
        });
      };


      const handleExportRows = (rows: MRT_Row<any>[]) => {
        var excel_rows = []
        var now = dayjs().format('YYYY-MM-DD HH:mm:ss')
        var filename = now + "_HousingList"

        for (var x = 0; x < rows.length; x++) {
          const formattedDateCreated = dayjs(rows[x].original.date_created).format('YYYY-MM-DD HH:mm:ss')
          const formattedDateUpdated = dayjs(rows[x].original.date_created).format('YYYY-MM-DD HH:mm:ss') // Change to updated

          excel_rows[x] = {
            "ID": rows[x].original.id,
            "Name": rows[x].original.test_name,
            "Description": rows[x].original.description,
            "Date Created": formattedDateCreated,
            "Date Updated": formattedDateUpdated,
            "Organisation ID": rows[x].original.organisation_id,
            "Organisation Name": rows[x].original.organisation_name,
            "Housing": rows[x].original.housing,
            "Version": rows[x].original.version,
            "Status": rows[x].original.status,
          }
        }

        tsXLXS().exportAsExcelFile(excel_rows).saveAsExcelFile(filename);

      };

      return (
        <Box data-testid="Box-x58c" sx={{ display: 'flex', gap: '0.3rem', height: "40px", alignItems: 'flex-end' }}>
          <Tooltip data-testid="Tooltip-7u3q" title="View action on selected data" placement="top" arrow>
            <Box data-testid="Box-pdes">
              <RareButton data-testid="RareButton-8toe"
                variant="outlined"
                onClick={handleClick}
                icon={<KeyboardArrowDownIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: isMobile ? 0 : 1, px: isMobile ? 1 : 2, fontSize: '14px' }}
              >
                {isMobile ? "" : "Bulk Actions"}
              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-be9p" title="Refresh Data" placement="top" arrow>
            <Box data-testid="Box-ggs2">
              <RareButton data-testid="RareButton-r8pb"
                variant="outlined"
                onClick={() => handleRefreshButton()}
                center={true}
                icon={<RefreshIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >
              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-yia6" title="Clear All Table Filters" placement="top" arrow>
            <Box data-testid="Box-pejn">
              <RareButton data-testid="RareButton-uouz"
                variant="outlined"
                onClick={() => handleRemoveAllFilters()}
                icon={<FilterAltOffOutlinedIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >
              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-c10d" title="Create New Method" placement="top" arrow>
            <Box data-testid="Box-4xsw">
              <RareButton data-testid="RareButton-59l7"
                onClick={() => openCreatePage()}
                variant="success"
                icon={<BiotechIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: isMobile ? 0 : 1, px: isMobile ? 1 : 2 }}
              >
                {isMobile ? "" : "Create New Method"}
              </RareButton>
            </Box>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem data-testid="MenuItem-8r2o" disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
              <Box data-testid="Box-05o5" sx={{ display: 'flex', alignItems: 'center' }}>
                <FileDownloadOutlinedIcon />
                <Typography data-testid="Typography-ga5h">Export to Excel</Typography>
              </Box>
            </MenuItem>
          </StyledMenu>
        </Box>
      );
    }}

    renderRowActions={({ row, table }) => {
      const handleChangeStatus = async () => {
        const isActive = row.original.status === "ACTIVE";
        const newStatus = isActive ? "deactivate" : "activate";
        const url = `methods/${row.original.id}/${newStatus}/`;

        try {
          await api.patch(url, {}, {
            headers: { Authorization: `Bearer ${authData.access}` },
          });

          handleRefreshButton();

          setSuccessMessage(`Method successfully ${isActive ? "deactivate" : "activate"}`);
          console.log(`Method successfully ${isActive ? "deactivate" : "activate"}`);

        } catch (error: any) {
          if (error?.response?.status === 400) {
            const errors = parseErrors(error.response.data);
            setErrorQueue(errors);
          } else {
            const errorText = `Unknown error ${isActive ? "deactivate" : "activate"} method`;
            setErrorQueue([errorText]);
            console.error(errorText, error.response);
          }
        }
      };

      return (
        <Box data-testid="Box-6yj1"
          onClick={(event) => event.stopPropagation()} // Prevent row click when clicking anything inside
          sx={{ gap: '1.5rem', display: "flex", alignItems: 'center' }}
        >
          <Tooltip data-testid="Tooltip-hbse"
            arrow
            placement="left"
            title={row.original.status === "ACTIVE"
              ? "Warning: Deactivating Method will disable it in the app"
              : "Activating this method will make it available in the app"
            }
          >
            <Box data-testid="Box-64iw">
              <FormControlLabel
                control={
                  <Switch
                    checked={row.original.status === "ACTIVE"}
                    onChange={handleChangeStatus}
                    color="success"
                  />
                }
                label={row.original.status === "ACTIVE" ? "Active" : "Inactive"}
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontSize: '14px',
                  },
                }}
              />
            </Box>
          </Tooltip>
          <NotificationSnackbarCard
            errorQueue={errorQueue}
            successMessage={successMessage}
            setErrorQueue={setErrorQueue}
            setSuccessMessage={setSuccessMessage}
          />
        </Box>
      );
    }}

  />
}

export default MethodsTable;

