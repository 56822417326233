import {
    Box,
    Button,
    Card,
    Container,
    CssBaseline,
    Dialog,
    FormControl,
    Grid,
    Link,
    ThemeProvider,
    Typography,
    useTheme,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    TextField,
  } from "@mui/material";
  import api from "../../../redux/features/auth/axiosAuthHelper";
  import { Field, FormikProvider, useFormik } from "formik";
  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import * as Yup from "yup";
  import assets from "../../../assets";
  import { FormEmailTextField } from "../fields/AuthFields";
  import colorConfigs from "../../../configs/colorConfigs";
  import versionConfig from "../../../configs/versionConfig";
import RareButton from "../buttons/RareButton";
    
  function Copyright(props: any) {
  
    return (
      <Typography data-testid="Typography-s6cr"
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        NovarumDX is registered trademarks of NovarumDX in many countries of the world.
      </Typography>
    );
  }
  
  export default function ForgottenPasswordCard() {
    const [showDialog, setShowDialog] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [disabled, setDisabled] = useState<boolean>(true)
  
    const navigate = useNavigate();
    const theme = useTheme();
  
    const validationSchema = () => {
      return Yup.object().shape({
        email: Yup.string()
          .email("Invalid email format")
          .required("This field is required"),
      });
    };

    const handleEmailChange = (event: any) => {
      setDisableButton(false)
    }
  
    const handleClose = () => {
      setShowDialog(false);
      navigate("/login");
    };
  
    const formik = useFormik({
      initialValues: {
        email: "",
      },
      validationSchema,
      onSubmit: (values) => {
        const post_data = {
          email: values.email,
        };
        console.log(post_data);
        api
          .post("auth/password-reset/", post_data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        setShowDialog(true);
      },
    });

    useEffect(() => {
      setDisabled(formik.initialValues !== formik.values)
  }, [formik.values])
  
    return (
            <Box data-testid="Box-wqvd"
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 4,
                // justifyContent: 'center',
                px: 2,
              }}
            >
              {/* Logo at the top */}
              <Box data-testid="Box-neaz"
                component="img"
                sx={{
                  width: { xs: '80%', sm: '60%', md: '40%', lg: '35%', xl: '30%' }, // Responsive widths
                  height: 'auto',
                  mb:  {xs: 3, sm: 1},
                }}
                alt="Rare Logo"
                src={assets.images.rareLogo}
              />
              
              <Card data-testid="Card-zule"
                sx={{
                  width: { xs: '80%', sm: '60%', md: '50%', lg: '40%', xl: '30%' }, // Responsive widths
                  boxShadow: 10,
                  p: {xs: 3, sm: 4, md: 5, lg: 8},
                  borderRadius: 5,
                }}
              >
                <CssBaseline />


                  {showDialog && (
                    <Dialog data-testid="Dialog-287w"
                      open={showDialog}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle data-testid="DialogTitle-75h3" id="alert-dialog-title">
                        Password Recovery
                      </DialogTitle>
                      <DialogContent data-testid="DialogContent-n9d9">
                        <DialogContentText id="alert-dialog-description">
                          {"We have sent an email to "}{" "}
                          <strong>{formik.values.email}</strong>
                          {" with instructions to reset your password. "}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions data-testid="DialogActions-kxo2">
                        <Button data-testid="Button-4w3j" onClick={handleClose} color="primary" autoFocus>
                          {"Return to Login"}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}

                    <Box data-testid="Box-mbfr"
                      sx={{
                        
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        px: {xs: 1, sm: 0, md: 5}
                      }}
                    >
                    <Box data-testid="Box-7iot" />
                      <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit} style={{width: '100%',}}>
                          <Box data-testid="Box-wuch" sx={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                            <Field data-testid="Field-jxuv"
                              name="email"
                              fullWidth
                              type="text"
                              label="Email"
                              autoFocus
                              component={FormEmailTextField}
                              onValueChange={(itemValue: any) => {
                                setDisableButton(false);
                              }}
                            />
                          </Box>

                          <Box data-testid="Box-27vy"
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            my: 2,
                          }}
                        >                     
                        <RareButton data-testid="Button-becy"
                          type="submit"
                          fullWidth
                          variant="contained"
                          disabled={!disabled}
                          sx={{width: {xs: '90%', sm: '50%'}}} >
                          Submit
                      </RareButton>
                      </Box>
                      
                        </form>
                        </FormikProvider>
                      <Box data-testid="Box-e1p3" sx={{alignSelf: 'flex-start'}} >
                        <Link data-testid="Link-yi2r" href="/login" variant="body2">
                          Return to  Login
                        </Link>
                      </Box>
                  </Box>
                  <Typography data-testid="Typography-micj" variant="body2" color="text.secondary" align="center">
                      {versionConfig.version}
                  </Typography>
                  </Card>
              </Box>
    );
  }
  