import React from "react";
import { Box, IconButton } from "@mui/material";
import {
  ArrowUpward,
  ArrowDownward,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import { Direction } from "../../../types/types";
import colorConfigs from "../../../configs/colorConfigs";

interface JoystickProps {
  direction: Direction;
  onDirectionClick: (direction: Direction) => void;
  size?: number; // Base size to scale the joystick
}

const JoystickIcon: React.FC<JoystickProps> = ({
  direction,
  onDirectionClick,
  size = 48,
}) => {
  const baseColor = "#7F7F7F";
  const clickedColor = colorConfigs.buttons.success;

  const handleDirectionClick = (direction: Direction) => {
    onDirectionClick(direction);
  };

  return (
    <Box data-testid="Box-wzqg"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        bgcolor: "white",
        borderRadius: "50%",
        boxShadow: 2,
        padding: 1, // Adds spacing inside the joystick
      }}
    >
      {/* Up Button */}
      <IconButton data-testid="IconButton-ldy1"
        onClick={() => handleDirectionClick("UP")}
        sx={{ width: size, height: size }}
      >
        <ArrowUpward
          sx={{
            fontSize: size * 1.2,
            color: direction === "UP" ? clickedColor : baseColor,
          }}
        />
      </IconButton>

      <Box data-testid="Box-6osk" display="flex" justifyContent="center" alignItems="center">
        {/* Left Button */}
        <IconButton data-testid="IconButton-faoj"
          onClick={() => handleDirectionClick("LEFT")}
          sx={{ width: size, height: size }}
        >
          <ArrowBack
            sx={{
              fontSize: size * 1.2,
              color: direction === "LEFT" ? clickedColor : baseColor,
            }}
          />
        </IconButton>

        {/* Center Spacer */}
        <Box data-testid="Box-0bwn" width={size * 0.9} height={size * 0.6} />

        {/* Right Button */}
        <IconButton data-testid="IconButton-ihlz"
          onClick={() => handleDirectionClick("RIGHT")}
          sx={{ width: size, height: size }}
        >
          <ArrowForward
            sx={{
              fontSize: size * 1.2,
              color: direction === "RIGHT" ? clickedColor : baseColor,
            }}
          />
        </IconButton>
      </Box>

      {/* Down Button */}
      <IconButton data-testid="IconButton-1n3x"
        onClick={() => handleDirectionClick("DOWN")}
        sx={{ width: size, height: size }}
      >
        <ArrowDownward
          sx={{
            fontSize: size * 1.2,
            color: direction === "DOWN" ? clickedColor : baseColor,
          }}
        />
      </IconButton>
    </Box>
  );
};

export default JoystickIcon;
