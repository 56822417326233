import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, Typography, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled, Theme, Switch, CircularProgress, LinearProgress, Tooltip, IconButton, Badge, useMediaQuery } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { ResultSessionStorageKey, ResultSessionStorageModel, ResultTableParameterStorageService } from "../../../services/sessionStorage";
import colorConfigs from "../../../configs/colorConfigs";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { tsXLXS } from 'ts-xlsx-export';
import Crop169Icon from '@mui/icons-material/Crop169';
import { MRT_FilterFns } from 'material-react-table';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import ProgressBar from "../cards/ProgressBar";
import RareButton from "../buttons/RareButton";
import { Link as RouterLink } from 'react-router-dom';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import api from "../../../redux/features/auth/axiosAuthHelper";
import DatarunStatusCard from "../cards/DatarunStatusCard";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const StyledMenu = styled((props: any) => (
  <Menu data-testid="Menu-80wi"
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


interface DataRunTableProps {
  authData: any;
  rows: any[];
  isLoading: boolean;
  sorting: any;
  columnVisibility: any;
  columnFilters: any;
  pagination: any;
  globalFilter: any;
  showGlobalFilter: any;
  setShowGlobalFilter: (value: any) => void;
  handleRemoveAllFilters: () => void;
  setGlobalFilter: (value: string) => void;
  setSorting: (sorting: any[]) => void;
  setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
  setColumnFilters: (filters: any[]) => void;
  setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
  setDataRun: (detail: any) => void;
  setPopup: (detail: any) => void;
  handleRefreshButton: () => void;
}

const DataRunTable: React.FC<DataRunTableProps> = ({
  authData,
  rows,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setDataRun,
  setPopup,
  handleRefreshButton
}) => {

  console.log("DataRuns", rows)
  const isMobile = useMediaQuery('(max-width:768px)');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const customOrganisationFilter = (row: any, columnId: any, filterValue: any) => {
    const { organisation_id } = row.original;
    const searchValue = filterValue.toLowerCase();
    return (
      organisation_id.toString().includes(searchValue)
    );
  };

  const customUserFilter = (row: any, columnId: any, filterValue: any) => {
    const { user_id } = row.original;
    const searchValue = filterValue.toLowerCase();
    return (
      user_id.toString().includes(searchValue)
    );
  };

  const customStatusFilter = (row: any, columnId: any, filterValue: any) => {
    const normalize = (str: string) => str.toLowerCase().replace(/_/g, ' ').trim(); // Convert to lowercase, replace underscores with spaces, and trim
    const status = normalize(row.getValue(columnId)?.toString() || ""); // Normalize the status value

    // Normalize filterValue to handle both single strings and arrays
    const normalizedFilterValues = Array.isArray(filterValue)
      ? filterValue.map((value) => normalize(value))
      : [normalize(filterValue)];
    // Check if any of the normalized filter values are partially included in the status
    return normalizedFilterValues.some((value) => status.includes(value));
  };

  const filterFns = {
    ...MRT_FilterFns,
    customOrganisationFilter,
    customUserFilter,
    customStatusFilter, // Add your custom status filter
  };

  const columns: MRT_ColumnDef<any>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: 'ID',
      Header: () => <div data-testid="div-a84d" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        ID<br />&nbsp;
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: 'Name',
      Header: () => <div data-testid="div-6b2a" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Run<br />Name
      </div>,
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: 'Description',
      Header: () => <div data-testid="div-4vt0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Description<br />&nbsp;
      </div>,
      Cell: ({ row }) => {
        const fullText = row.original.description;
        return (
          <div data-testid="div-ejj6"
            style={{
              minWidth: '250px',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              overflow: 'visible',
              lineHeight: '1.5',
            }}
          >
            {fullText}
          </div>
        );
      },
    },
    {
      id: 'dataset_id',
      accessorKey: 'dataset_id',
      header: 'Dataset ID',
      Header: () => <div data-testid="div-a3ja" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        DataSet<br />ID
      </div>,
    },
    {
      id: 'method_id',
      accessorKey: 'method_id',
      header: 'Method ID',
      Header: () => <div data-testid="div-a2x5" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Method<br />ID
      </div>,
    },
    {
      id: 'date_created',
      accessorFn: (row) => dayjs(row.date_created).format('YYYY-MM-DD HH:mm:ss'),
      header: 'Date Created',
      Header: () => <div data-testid="div-peg3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Date<br />Created
      </div>,
      Cell: ({ cell }) => (
        <div data-testid="div-ju5j" style={{ whiteSpace: 'pre-wrap' }}>
          {cell.getValue()?.toString().split(' ').join('\n')}
        </div>
      ),
    },
    {
      id: 'organisation_id', // Base accessor
      accessorKey: 'organisation_id', // Base accessor
      header: "Organisation ID",
      Header: () => (
        <div data-testid="div-kaq0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Organisation<br />ID
        </div>
      ),
      Cell: ({ row }) => (
        <div data-testid="div-kc10">
          {row.original.organisation_id}
        </div>
      ),
      enableColumnFilter: true,
      filterFn: 'customOrganisationFilter',
    },
    {
      id: 'user_id', // Base accessor
      accessorKey: 'user_id', // Base accessor
      header: "User ID",
      Header: () => (
        <div data-testid="div-kr0r" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          User<br />ID
        </div>
      ),
      Cell: ({ row }) => (
        <div data-testid="div-jhqp">
          {row.original.user_id}
        </div>
      ),
      enableColumnFilter: true,
      filterFn: 'customOrganisationFilter',
    },
    {
      id: "status",
      accessorKey: 'status',
      header: 'Status',
      Header: () => (
        <div data-testid="div-cmtc" style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          minHeight: '50px'
        }}>
          Status<br />&nbsp;
        </div>
      ),
      Cell: ({ cell, row }) => (
        <div data-testid="div-81fm" style={{ width: '100px', display: "flex", justifyContent: "center", alignItems: "center" }}>
          {cell.getValue() === "PENDING" ? (
            <Tooltip data-testid="Tooltip-tsod" arrow placement="left" title={"Click to update progress"} onClick={(event) => { event.stopPropagation() }}>
              <Box data-testid="Box-mu2j" onClick={(event) => { event.stopPropagation(); handleRefreshButton() }} sx={{ display: 'flex', gap: 0.5 }}>
                <Typography data-testid="Typography-dlhg"
                  fontSize="smaller"
                  sx={{
                    paddingX: 0.7,
                    border: `1.5px solid ${colorConfigs.buttons.inactive}`,
                    borderRadius: 2,
                    color: colorConfigs.buttons.inactive
                  }}
                >
                  Processing
                  <CircularProgress data-testid="CircularProgress-qze9" onClick={() => handleRefreshButton()} sx={{ ml: 1, maxHeight: '10px', maxWidth: '10px' }} />
                </Typography>
              </Box>
            </Tooltip>
          ) : cell.getValue() === "COMPLETED_WITH_ERRORS" || cell.getValue() === "COMPLETED" ? (
            <DatarunStatusCard
              total_results={row.original.total_results}
              error_results={row.original.error_results}
              successful_results={row.original.successful_results}
            />
          ) : cell.getValue() === "RUNNING" ? (
            <div data-testid="div-ljar" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <ProgressBar
                initialDateTime={row.original.date_created}
                totalCount={row.original.total_results}
                successful={row.original.successful_results}
                failed={row.original.error_results}
              />
            </div>
          ) : null}
        </div>
      ),
      filterFn: 'customStatusFilter',
    },

  ]


  // Define the type for the keys for handling persistance of table settings in session storage
  type ResultSessionStorageKeyType = keyof ResultSessionStorageModel;

  // Generic handler function for persisting table parameters
  const handleParameterChange = <T,>(
    key: ResultSessionStorageKeyType,
    currentValue: T,
    setValueFunction: (value: T) => void,
    newValue: T | ((prevValue: T) => T)
  ) => {
    const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
    ResultTableParameterStorageService.set(key, updatedValue);
    setValueFunction(updatedValue);
  };

  // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
  const setSortingFunction = (newSortingValue: any) =>
    handleParameterChange(ResultSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
  const setPaginationChangeFunction = (newPaginationValue: any) =>
    handleParameterChange(ResultSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
  const setFilterChangeFunction = (newFilterChangeValue: any) =>
    handleParameterChange(ResultSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
  const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
    handleParameterChange(ResultSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
  const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
    handleParameterChange(ResultSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);



  return <MaterialReactTable
    columns={columns}
    data={rows}
    filterFns={filterFns}
    state={{
      isLoading,
      sorting,
      columnFilters,
      pagination,
      globalFilter,
      showGlobalFilter
    }}
    onSortingChange={setSortingFunction}
    onColumnFiltersChange={setFilterChangeFunction}
    onPaginationChange={setPaginationChangeFunction}
    onGlobalFilterChange={setGlobalFilterChangeFunction}
    onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
    defaultColumn={{
      minSize: 10,
      maxSize: 160,
      size: 10,
    }}
    enableColumnOrdering={true}
    positionActionsColumn="last"
    enableRowSelection
    selectAllMode="all"
    enableRowActions
    initialState={{
      sorting,
      columnVisibility,
      columnFilters,
      pagination,
      globalFilter,
      density: 'compact',
      columnPinning: { left: ['mrt-row-select'], right: ['status', 'mrt-row-actions'] },
    }}

    // TABLE STYLING IS IN THE THEME
    displayColumnDefOptions={{
      'mrt-row-actions': {
        header: "Actions", // Renames Actions Column
      },
    }}

    muiTopToolbarProps={({ table }) => ({
      sx: (theme: any) => ({
        ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
      }),
    })}

    muiTableHeadProps={{
      sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
    }}

    muiTableHeadCellProps={({ column }) => ({
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
        ...(column.id === 'mrt-row-select' && {
          maxWidth: '50px',
          width: '50px',
        }),
        ...(column.id === 'status' && {
          minWidth: '200px',
          width: '200px',
        }),
      }),
    })}

    muiFilterTextFieldProps={{
      sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
    }}

    muiTableBodyRowProps={({ row }) => ({
      onClick: (event) => {
        console.log(row.original);
        setDataRun(row.original);
        setPopup('Edit');
      },
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
        backgroundColor:
          row.index % 2 === 0
            ? colorConfigs.tables.firstRow
            : colorConfigs.tables.secondRow,
      }),
    })}

    muiTableBodyProps={{
      sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
    }}

    muiPaginationProps={{
      rowsPerPageOptions: [250, 500, 1000],
      sx: {
        padding: 0,
        margin: 0,
        '& .MuiTablePagination-root': {
          padding: 0,
          margin: 0,
        },
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          minHeight: '0px',
        },
        '& .MuiTablePagination-selectLabel': {
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          margin: 0,
        },
      },
    }}

    muiTableBodyCellProps={({ cell }) => ({
      // TABLE BODY CELLS
      sx: {
      },
    })}

    muiBottomToolbarProps={{
      // BOTTOM TOOLBAR (Pagination)
      sx: {
        height: '41px',
        maxHeight: '41px',
        minHeight: '41px',
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: '#fff',
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        margin: 0,
        padding: 0,
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          paddingBottom: 0,
          paddingTop: 0,
        },
        '& .MuiBox-root': {
          p: 0,
        },
        '& .MuiIconButton-root': {
          color: colorConfigs.tables.headBg,
        },
        '& .MuiTablePagination-root': {
          margin: 0,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          color: colorConfigs.buttons.bg,
        },
        '& .MuiTablePagination-selectLabel': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          color: colorConfigs.buttons.bg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
      },
    }}


    renderTopToolbarCustomActions={({ table }) => {

      const handleArchiveResults = () => {
        table.getSelectedRowModel().flatRows.map(row => {
          //Add archive logic
        });
      };


      const handleExportRows = (rows: MRT_Row<any>[]) => {
        var excel_rows = []
        var now = dayjs().format('YYYY-MM-DD HH:mm:ss')
        var filename = now + "_DataRunList"

        for (var x = 0; x < rows.length; x++) {
          const formattedDateCreated = dayjs(rows[x].original.date_created).format('YYYY-MM-DD HH:mm:ss')
          const formattedDateUpdated = dayjs(rows[x].original.date_created).format('YYYY-MM-DD HH:mm:ss') // Change to updated

          excel_rows[x] = {
            "ID": rows[x].original.id,
            "Name": rows[x].original.name,
            "Description": rows[x].original.description,
            "Date Created": formattedDateCreated,
            "Date Updated": formattedDateUpdated,
            "Organisation ID": rows[x].original.organisation_id,
            "Organisation Name": rows[x].original.organisation_name,
            "Status": rows[x].original.is_active ? "ACTIVE" : "INACTIVE",
          }
        }

        tsXLXS().exportAsExcelFile(excel_rows).saveAsExcelFile(filename);

      };

      return (
        <Box data-testid="Box-dkbj" sx={{ display: 'flex', gap: '0.3rem', height: "40px", alignItems: 'flex-end' }}>
          <Tooltip data-testid="Tooltip-lld6" title="View action on selected data" placement="top" arrow>
            <Box data-testid="Box-mc7b">
              <RareButton data-testid="RareButton-zw1q"
                variant="outlined"
                onClick={handleClick}
                icon={<KeyboardArrowDownIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: isMobile ? 0 : 1, px: isMobile ? 1 : 2, fontSize: '14px' }}
              >
                {isMobile ? "" : "Bulk Actions"}
              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-fhj5" title="Refresh Data" placement="top" arrow>
            <Box data-testid="Box-zh2o">
              <RareButton data-testid="RareButton-3wlp"
                variant="outlined"
                onClick={() => handleRefreshButton()}
                center={true}
                icon={<RefreshIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >
              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-vooj" title="Clear All Table Filters" placement="top" arrow>
            <Box data-testid="Box-1ttd">
              <RareButton data-testid="RareButton-rmr8"
                variant="outlined"
                onClick={() => handleRemoveAllFilters()}
                icon={<FilterAltOffOutlinedIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >
              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-il0t" title="Create New DataRun" placement="top" arrow>
            <Box data-testid="Box-vvxk">
              <RareButton data-testid="RareButton-ru74"
                onClick={() => setPopup("Create")}
                variant="success"
                icon={<PlaylistPlayIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: isMobile ? 0 : 1, px: isMobile ? 1 : 2 }}
              >
                {isMobile ? "" : "Create New Datarun"}
              </RareButton>
            </Box>
          </Tooltip>

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem data-testid="MenuItem-bj44" disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
              <Box data-testid="Box-zoem" sx={{ display: 'flex', alignItems: 'center' }}>
                <FileDownloadOutlinedIcon />
                <Typography data-testid="Typography-7rfe">Export to Excel</Typography>
              </Box>
            </MenuItem>
          </StyledMenu>
        </Box>
      );
    }}

    renderRowActions={({ row, table }) => {

      const handleDownload = async (row: any) => {
        try {
          const response = await api.get(`/dataruns/${row.original.id}/export`, {
            responseType: "blob", // Ensures file is treated as a binary object
            headers: {
              "Authorization": `Bearer ${authData.access}` // Pass authentication token
            }
          });

          // Create a Blob from the response data
          const blob = new Blob([response.data], { type: response.headers["content-type"] });

          // Create a link element and trigger the download
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", `datarun_${row.original.id}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Download failed:", error);
        }
      };

      return (
        <Box data-testid="Box-aiox" onClick={(event) => event.stopPropagation()} // Prevent row click when clicking anything inside
          sx={{ gap: 1, display: "flex", justifyContent: 'center', alignItems: 'center' }}
        >

          <Tooltip data-testid="Tooltip-fgec"
            arrow
            placement="left"
            title={row.original.successful_results ? "View Report" : ''}
          >
            <IconButton
              data-testid="IconButton-j8w4"
              disabled={!row.original.successful_results}
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  row.original.report,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
              sx={{ transition: 'transform 0.3s ease' }}
            >
              <AssignmentOutlinedIcon
                sx={{ color: row.original.successful_results ? colorConfigs.sidebar.color : 'transparent' }}
              />
            </IconButton>
          </Tooltip>


          <Tooltip data-testid="Tooltip-qyfg"
            arrow
            placement="left"
            title={row.original.successful_results ? "Export Datarun Excel" : ''}
          >
            <IconButton data-testid="IconButton-j8w4"
              disabled={!row.original.successful_results}
              onClick={() => handleDownload(row)}
              sx={{ transition: 'transform 0.3s ease' }}
            >
              <FileDownloadOutlinedIcon sx={{ color: row.original.successful_results ? colorConfigs.sidebar.color : 'transparent' }} />
            </IconButton>
          </Tooltip>


          <Tooltip data-testid="Tooltip-qyfj"
            arrow
            placement="left"
            title={row.original.successful_results ? "View Reprocessed Results" : ''}
          >
            <Badge data-testid="Badge-tgqt"
              badgeContent={row.original.successful_results}
              color={row.original.total_results ? 'primary' : 'default'}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiBadge-badge': {
                  top: '13px',
                  right: '10px',
                  background: colorConfigs.tables.headBg,
                  opacity: 0.98
                },
              }}
              overlap="circular"
            >
              <IconButton data-testid="IconButton-j8w8"
                disabled={!row.original.successful_results}
                onClick={() => window.open(`/results?datarun=${row.original.id}`, '_blank')}
                sx={{ transition: 'transform 0.3s ease' }}
              >
                <InsertChartOutlinedIcon sx={{ color: row.original.successful_results ? colorConfigs.sidebar.color : 'transparent' }} />
              </IconButton>
            </Badge>
          </Tooltip>

        </Box>
      );
    }}
  />
}

export default DataRunTable;

