import { Alert, AlertTitle, Box, Button, Card, Divider, FormControlLabel, FormGroup, Grid, IconButton, Link, Paper, Snackbar, Switch, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { isLoggedIn, refreshToken } from '../../../redux/features/auth/authService';
import SignInDialog from '../../../components/common/SignInDialog';
import api from '../../../redux/features/auth/axiosAuthHelper';
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';
import { FormIsActiveSwitch, FormLastLoginTextField, FormUserEmailTextField, FormUserFirstNameTextField, FormUserIDTextField, FormUserLastNameTextField, FormUserTypeTextField } from '../../../components/common/fields/ProfileFeilds';
import * as Yup from "yup";
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import { TextEditField, TextEditReadOnlyField } from '../../../components/common/fields/fields';
import MuiAlert from '@mui/material/Alert';
import { SyntheticEvent } from 'react';
import { SnackbarCloseReason } from '@mui/material';
import colorConfigs from '../../../configs/colorConfigs';
import RareButton from '../buttons/RareButton';
import RareChip from '../buttons/RareChip';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

interface EditUserDetailProps {
    setPopup: (detail: any) => void
    handleBackButton: () => void;
}

const EditProfileCard: React.FC<EditUserDetailProps> = ({setPopup, handleBackButton}) => {
  
  const [allowEdit, setAllowEdit] = useState<boolean>(false)
  const [errors, setErrors] = useState<any>(null)
  const [initialValues, setInitialValues] = useState<any>("")
  const [updatedValues, setUpdatedValues] = useState<any>("")
  const [hasMadeChanges, setHasMadeChanges] = useState<any>(false)
  const authData = useSelector((state: RootState) => state.auth);
  const [open, setOpen] = useState(false);

const dispatch = useDispatch<AppDispatch>();

const handleSnackbarClose = (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
if (reason === 'clickaway') {
  return;
}
setOpen(false);
};
const handleAlertClose = (event: SyntheticEvent<Element, Event>) => {
setOpen(false);
};

useEffect(() => {
  if (authData.user) {
      formik.setFieldValue('id', authData.user.id);
      formik.setFieldValue('first_name', authData.user.first_name);
      formik.setFieldValue('last_name', authData.user.last_name);
      formik.setFieldValue('email', authData.user.email);
      formik.setFieldValue('user_type', authData.user.user_type);
      formik.setFieldValue('date_joined', authData.user.date_joined ? dayjs(authData.user.date_joined).format('YYYY-MM-DD') : '' );
      formik.setFieldValue('last_login', authData.user.last_login ? dayjs(authData.user.last_login).format('YYYY-MM-DD HH:mm:ss') : '' );
      formik.setFieldValue('is_active', authData.user.is_active);
  }
}, [authData.user]);

const validationSchema = () => {
  return Yup.object().shape({
      first_name: Yup.string().required("This field is required"), 
      last_name: Yup.string().required("This field is required"),
      email: Yup.string().required("This field is required").email(),
  });
}

const formik = useFormik({
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      user_type: "",
      date_joined: "",
      last_login: "",
      is_active: false
  },
  validationSchema,
  onSubmit: async (values) => {

      const patch_data = {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              // user_type: values.user_type,
              // is_active: values.is_active,
          }

      try {
          await api.patch(`profile/`, patch_data, {
              headers: {
                  'Authorization': `Bearer ${authData.access}`
              }
          })
          setErrors(null);
          setOpen(!open)
          setAllowEdit(!allowEdit)
          dispatch(fetchUserProfile());
          console.log(`Profile edited successfully.`);
          setTimeout(() => {
            setPopup("")
          }, 1500);
      } catch (error: any) {
          if (error?.response?.status === 400) {
              const firstErrorItem = Object.keys(error.response.data)[0];
              setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
          } else {
              const errorText = `Unknown error 'updating' profile`;
              setErrors(errorText);
              console.error(errorText, error);
          }
      }
  },
  });

    // Effect to set initial form values from subscription to check for form changes
    useEffect(() => {
      setTimeout(() => {
          setInitialValues(formik.initialValues);
          setUpdatedValues(formik.initialValues);
      }, 500);
      setHasMadeChanges(false);
  }, [authData.isFreeUser, open]);

  // Update the updatedValues state when formik values change
  useEffect(() => {
      setUpdatedValues(formik.values);
  }, [formik.values]);

  // Function to check if form has changed so the save button becomes active on edit
  const hasFormChanged = (flatModel: Record<string, any>, formValues: Record<string, any>): boolean => {
      for (const key in formValues) {
          if (key === "expiry_date") {
              const formDate = dayjs(formValues[key]).format('YYYY-MM-DD');
              const initialDate = dayjs(formValues[key]).format('YYYY-MM-DD');
              if (formDate !== initialDate) {
                  console.log("Changed Date", formDate, initialDate)
                  return true;
              }
          } else {
              if (formValues[key]!= flatModel[key]) {
                  console.log("Changed Data", formValues[key], flatModel[key])
                  return true;
              }
          }
      }
      return false;
  };
          
  // Use Effect to check if any changes ahve been made to the form on Edit
  useEffect(() => {
      if (authData.user) {
          if (Object.keys(initialValues).length > 0 && Object.keys(updatedValues).length > 0) {
              const formChanged = hasFormChanged(initialValues, updatedValues);
              setHasMadeChanges(formChanged);
          }
      }
  }, [updatedValues, initialValues, authData.user]);



  return authData.isAuthenticated == false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated == true && authData.isFreeUser ? (
    <Navigate to="/account/subscription"/>
    ) : authData.isAuthenticated == true && authData.isFreeUser === false ? (

            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit} style={{}}> 
                <Box data-testid="Box-nxzb" sx={{ height: "100%", p:2, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

                    {errors && (
                      <Alert data-testid="Alert-p4wd" severity="error" style={{ width: '100%' }}>
                        <AlertTitle data-testid="AlertTitle-84mx">Error</AlertTitle>
                        {errors}
                      </Alert>
                    )}
                  <div data-testid="div-hrh8" style={{width: "100%", height: "100%", margin: "auto", padding: "1em", display: "flex", alignItems: "center"}}>

                  <Grid container spacing={5} alignItems="top" justifyContent="center" style={{ minWidth: "100%", padding: 5 }}>
                  <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                  <Card data-testid="Card-i4kr" sx={{ pb: 1, boxShadow: 10, height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Box data-testid="Box-smjv" sx={{display: 'flex'}}>
                                    <Box data-testid="Box-0hlh" sx={{height: '100%', background: colorConfigs.tables.headBg, display: 'flex', alignItems: 'center', borderBottomRightRadius: 3}}>
                                    <Typography data-testid="Typography-y8ab" sx={{px: 2, py: 0.5, color: '#fff', fontWeight: 'bold', fontSize: '20px' }}>My Details</Typography>
                                    </Box>
                                </Box>
                                <Box data-testid="Box-m9po" sx={{p: 2, pt:3, height: '100%', display: 'flex', flexDirection: 'column', gap: 3}}>
                                  <Field data-testid="Field-lixo" name="first_name" type="text" className="form-control" label="First Name" component={TextEditField} />
                                  <Field data-testid="Field-5vny" name="last_name" type="text" className="form-control" label="Last Name" component={TextEditField} />
                                  <Field data-testid="Field-uepu" name="email" type="text" className="form-control" label="Email" component={TextEditField} />
                                </Box>
                          </Card>
                        </Grid>
          
                        <Grid item xs={12} md={5} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Card data-testid="Card-vdev" sx={{ pb: 1, boxShadow: 10, height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Box data-testid="Box-2kdj" sx={{display: 'flex'}}>
                                    <Box data-testid="Box-nlsn" sx={{height: '100%', background: colorConfigs.tables.headBg, display: 'flex', alignItems: 'center', borderBottomRightRadius: 3}}>
                                    <Typography data-testid="Typography-mqe8" sx={{px: 2, py: 0.5, color: '#fff', fontWeight: 'bold', fontSize: '20px' }}>My Stats</Typography>
                                    </Box>
                                </Box>
                                <Box data-testid="Box-458z" sx={{p: 2, pt:3, height: '100%', display: 'flex', flexDirection: 'column', gap: 3}}>
                                  <Field data-testid="Field-rtzr" name="user_type" type="text" className="form-control" label="User Type" component={TextEditReadOnlyField} />
                                  <Field data-testid="Field-0vmn" name="date_joined" type="text" className="form-control" label="Date Created" component={TextEditReadOnlyField} />
                                  <Field data-testid="Field-b6kt" name="last_login" type="text" className="form-control" label="Last Login" component={TextEditReadOnlyField} />
                                </Box>
                          </Card>
                        </Grid>
                      </Grid>
                    </div>
          
                    {/* <div data-testid="div-ns6i" className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <RareButton data-testid="RareButton-k3ir"
                            onClick={() => handleBackButton()}
                            variant="contained"
                            icon={<AddOutlinedIcon fontSize='small' sx={{p:0, m:0, color: "#fff"}} />}
                        >
                            Ok
                        </RareButton>

                        <RareButton data-testid="RareButton-svmd"
                            onClick={() => handleBackButton()}
                            variant="contained"
                            disabled={true}
                        >
                            Ok
                        </RareButton>

                        <RareButton data-testid="RareButton-u0x5"
                            onClick={() => handleBackButton()}
                            variant="outlined"
                        >  
                        Outlined
                        </RareButton>


                        <RareButton data-testid="RareButton-yym2"
                            onClick={() => handleBackButton()}
                            variant="outlined"
                            disabled={true}
                        >  
                        Outlined
                        </RareButton>

                        <RareButton data-testid="RareButton-e6nn"
                            onClick={() => handleBackButton()}
                            variant="cancel"
                        >
                            Back
                        </RareButton>

                        <RareButton data-testid="RareButton-zf3i"
                            onClick={() => handleBackButton()}
                            variant="cancel"
                            disabled={true}
                        >
                            Back
                        </RareButton>

                        <RareButton data-testid="RareButton-s1gp"
                            onClick={() => handleBackButton()}
                            variant="success"
                        >
                            Save
                        </RareButton>

                        <RareButton data-testid="RareButton-pn26"
                            onClick={() => handleBackButton()}
                            variant="success"
                            disabled={true}
                        >
                            Disabled
                        </RareButton>

                        <RareChip data-testid="RareChip-5b5h" variant="contained">OK</RareChip>
                        <RareChip data-testid="RareChip-w99r" variant="contained" disabled={true}>OK</RareChip>

                        <RareChip data-testid="RareChip-778s" variant="outlined">Outlined</RareChip>
                        <RareChip data-testid="RareChip-2q17" variant="outlined" disabled={true}>Outlined</RareChip>

                        <RareChip data-testid="RareChip-veyh" variant="cancel">Back</RareChip>
                        <RareChip data-testid="RareChip-c1nt" variant="cancel" disabled={true}>Back</RareChip>

                        <RareChip data-testid="RareChip-px6o" variant="success">Save</RareChip>
                        <RareChip data-testid="RareChip-g1ay" variant="success" disabled={true}>Disabled</RareChip>

                        <RareChip data-testid="RareChip-bosy" variant="contained" deleteIcon={<AddOutlinedIcon sx={{color: "#fff"}} />} onDelete={() => {console.log("delete")}} onClick={() => {console.log("click")}} >OK</RareChip>

                        <RareButton data-testid="RareButton-m1ns"
                            type="submit"
                            variant="success"
                            disabled={!hasMadeChanges}
                            sx={{
                                type:"submit",
                                position: "relative",
                                m: 1,
                            }}
                        >
                            Save Changes
                        </RareButton>
                    </div> */}
                   <div data-testid="div-altn" className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <RareButton data-testid="RareButton-2tjl"
                            onClick={() => handleBackButton()}
                            variant="outlined"
                            sx={{
                                position: "relative",
                                m: 1,
                            }}
                        >
                            Back
                        </RareButton>
                        <RareButton data-testid="RareButton-oimc"
                            type="submit"
                            variant="success"
                            disabled={!hasMadeChanges}
                            sx={{
                                position: "relative",
                                m: 1,
                            }}
                        >
                            Save Changes
                        </RareButton>
                    </div>
                  </Box>
              </form>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <MuiAlert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
                     Profile successfully updated
                    </MuiAlert>
                </Snackbar>
            </FormikProvider>
) : (null);
};
export default EditProfileCard;

