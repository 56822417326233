import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { useMediaQuery, Badge, Box, Button, Grid, IconButton, Link, ListItemIcon, Menu, MenuItem, MenuProps, Paper, Tooltip, Typography, alpha, styled } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { OrganisationSessionStorageKey, OrganisationSessionStorageModel, OrganisationTableParameterStorageService } from "../../../services/sessionStorage";
import RefreshIcon from '@mui/icons-material/Refresh';
import colorConfigs from "../../../configs/colorConfigs";
import { Link as RouterLink } from 'react-router-dom';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import { tsXLXS } from 'ts-xlsx-export';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import { MRT_FilterFns } from 'material-react-table';
import RareButton from "../buttons/RareButton";

const StyledMenu = styled((props: any) => (
  <Menu data-testid="Menu-ho67"
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


interface OrganisationsTableProps {
  rows: any[];
  isLoading: boolean;
  sorting: any;
  columnVisibility: any;
  columnFilters: any;
  pagination: any;
  globalFilter: any;
  showGlobalFilter: any;
  setShowGlobalFilter: (value: any) => void;
  handleRemoveAllFilters: () => void;
  setGlobalFilter: (value: string) => void;
  setSorting: (sorting: any[]) => void;
  setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
  setColumnFilters: (filters: any[]) => void;
  setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
  openEditPage: (detail: any) => void;
  openCreatePage: () => void;
  handleRefreshButton: () => void;
}

const OrganisationsTable: React.FC<OrganisationsTableProps> = ({
  rows,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  openEditPage,
  openCreatePage,
  handleRefreshButton
}) => {

  console.log("rows", rows)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const customStatusFilter = (row: any, columnId: any, filterValue: any) => {
    const normalize = (str: string) => str.toLowerCase().replace(/_/g, ' ').trim(); // Convert to lowercase, replace underscores with spaces, and trim
    const status = normalize(row.getValue(columnId)?.toString() || ""); // Normalize the status value

    // Normalize filterValue to handle both single strings and arrays
    const normalizedFilterValues = Array.isArray(filterValue)
      ? filterValue.map((value) => normalize(value))
      : [normalize(filterValue)];
    // Check if any of the normalized filter values are partially included in the status
    return normalizedFilterValues.some((value) => status.includes(value));
  };

  const filterFns = {
    ...MRT_FilterFns,
    customStatusFilter, // Add your custom status filter
  };


  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      Header: () => <div data-testid="div-kdav" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        ID<br />&nbsp;
      </div>,
    },
    {
      accessorKey: 'name',
      header: "Name",
      Header: () => <div data-testid="div-7ciw" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Name<br />&nbsp;
      </div>,
    },
    {
      accessorFn: (row) => dayjs(row.date_created).format('YYYY-MM-DD HH:mm:ss'),
      header: 'Date',
      Header: () => <div data-testid="div-4f6c" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Date<br />Created
      </div>,
      Cell: ({ cell }) => (
        <div data-testid="div-bzyf" style={{ whiteSpace: 'pre-wrap' }}>
          {cell.getValue()?.toString().split(' ').join('\n')}
        </div>
      ),
    },
    // {
    //     accessorKey: 'subscription_plan',
    //     header: 'Subscription ID',
    //     Header:() => <div data-testid="div-45yv" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
    //                     Subscription<br />ID
    //                 </div>,
    // },
    {
      accessorKey: 'subscription_tier',
      header: 'Subscription Tier ID',
      Header: () => <div data-testid="div-5scx" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Subscription<br />Tier
      </div>,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      Header: () => <div data-testid="div-6ck9" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Status<br />&nbsp;
      </div>,
      Cell: ({ cell }) => (
        <div data-testid="div-nt2b" style={{ display: "flex", justifyContent: "center" }}>
          {cell.getValue() == "ACTIVE" ?
            <Typography data-testid="Typography-itly" fontSize="smaller" sx={{ paddingX: 0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography>
            : cell.getValue() == "NOT_ACTIVE" ? <Typography data-testid="Typography-n34d" fontSize="smaller" sx={{ paddingX: 0.7, border: `1.5px solid ${colorConfigs.buttons.inactive}`, borderRadius: 2, color: colorConfigs.buttons.inactive }} >Inactive</Typography>
              : cell.getValue() == "NOT_VALIDATED" ? <Typography data-testid="Typography-ffs7" fontSize="smaller" sx={{ paddingX: 0.7, border: `1.5px solid ${colorConfigs.buttons.in_progress}`, borderRadius: 2, color: colorConfigs.buttons.in_progress }} >Not Validated</Typography>
                : null
          }
        </div>
      ),
      filterFn: 'customStatusFilter',
    },
  ]


  // Define the type for the keys for handling persistance of table settings in session storage
  type OrganisationSessionStorageKeyType = keyof OrganisationSessionStorageModel;

  // Generic handler function for persisting table parameters
  const handleParameterChange = <T,>(
    key: OrganisationSessionStorageKeyType,
    currentValue: T,
    setValueFunction: (value: T) => void,
    newValue: T | ((prevValue: T) => T)
  ) => {
    const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
    OrganisationTableParameterStorageService.set(key, updatedValue);
    setValueFunction(updatedValue);
  };

  // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
  const setSortingFunction = (newSortingValue: any) =>
    handleParameterChange(OrganisationSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
  const setPaginationChangeFunction = (newPaginationValue: any) =>
    handleParameterChange(OrganisationSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
  const setFilterChangeFunction = (newFilterChangeValue: any) =>
    handleParameterChange(OrganisationSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
  const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
    handleParameterChange(OrganisationSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
  const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
    handleParameterChange(OrganisationSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);


  return <MaterialReactTable
    columns={columns}
    data={rows}
    filterFns={filterFns}
    state={{
      isLoading,
      sorting,
      columnFilters,
      pagination,
      globalFilter,
      showGlobalFilter
    }}
    onSortingChange={setSortingFunction}
    onColumnFiltersChange={setFilterChangeFunction}
    onPaginationChange={setPaginationChangeFunction}
    onGlobalFilterChange={setGlobalFilterChangeFunction}
    onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
    defaultColumn={{
      minSize: 10,
      maxSize: 160,
      size: 10,
    }}
    enableColumnOrdering={true}
    positionActionsColumn="last"
    enableRowSelection
    selectAllMode="all"
    // enableRowActions
    initialState={{
      sorting,
      columnVisibility,
      columnFilters,
      pagination,
      globalFilter,
      density: 'compact',
      columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions'] },
    }}

    // TABLE STYLING IS IN THE THEME
    displayColumnDefOptions={{
      'mrt-row-actions': {
        header: "Actions", // Renames Actions Column
      },
    }}

    muiTopToolbarProps={({ table }) => ({
      sx: (theme: any) => ({
        ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
      }),
    })}

    muiTableHeadProps={{
      sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
    }}

    muiTableHeadCellProps={({ column }) => ({
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
        ...(column.id === 'mrt-row-select' && {
          maxWidth: '50px',
          width: '50px',
        }),
      }),
    })}

    muiFilterTextFieldProps={{
      sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
    }}

    muiTableBodyRowProps={({ row }) => ({
      onClick: (event) => {
        console.log(row.original);
        openEditPage(row);
      },
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
        backgroundColor:
          row.index % 2 === 0
            ? colorConfigs.tables.firstRow
            : colorConfigs.tables.secondRow,
      }),
    })}

    muiTableBodyProps={{
      sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
    }}

    muiPaginationProps={{
      rowsPerPageOptions: [250, 500, 1000],
      sx: {
        padding: 0,
        margin: 0,
        '& .MuiTablePagination-root': {
          padding: 0,
          margin: 0,
        },
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          minHeight: '0px',
        },
        '& .MuiTablePagination-selectLabel': {
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          margin: 0,
        },
      },
    }}

    muiTableBodyCellProps={({ cell }) => ({
      // TABLE BODY CELLS
      sx: {
      },
    })}

    muiBottomToolbarProps={{
      // BOTTOM TOOLBAR (Pagination)
      sx: {
        height: '41px',
        maxHeight: '41px',
        minHeight: '41px',
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: '#fff',
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        margin: 0,
        padding: 0,
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          paddingBottom: 0,
          paddingTop: 0,
        },
        '& .MuiBox-root': {
          p: 0,
        },
        '& .MuiIconButton-root': {
          color: colorConfigs.tables.headBg,
        },
        '& .MuiTablePagination-root': {
          margin: 0,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          color: colorConfigs.buttons.bg,
        },
        '& .MuiTablePagination-selectLabel': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          color: colorConfigs.buttons.bg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
      },
    }}



    renderTopToolbarCustomActions={({ table }) => {

      const handleArchiveCycles = () => {
        table.getSelectedRowModel().flatRows.map(row => {
          //Add archive logic
        });
      };

      const handleExportRows = (rows: any[]) => {
        const excelRows = [];
        const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
        const filename = `${now}_UserList`;

        for (let x = 0; x < rows.length; x++) {
          const user = rows[x].original;
          const formattedDateJoined = dayjs(user.date_joined).format('YYYY-MM-DD HH:mm:ss');
          const formattedLastLogin = user.last_login ? dayjs(user.last_login).format('YYYY-MM-DD HH:mm:ss') : '';

          excelRows[x] = {
            "ID": user.id,
            "Name": user.name,
            "Subscription ID": user.subscription,
            "Subscriptions Tier": user.tier,
            "Reader Config Url": user.reader_config,
          };
        }

        tsXLXS().exportAsExcelFile(excelRows).saveAsExcelFile(filename);
      };


      return (

        <Box data-testid="Box-wmxk" sx={{ display: 'flex', gap: '0.3rem', height: "40px", alignItems: 'flex-end' }}>
          <Tooltip data-testid="Tooltip-lyke" title="View action on selected data" placement="top" arrow>
            <Box data-testid="Box-gpjt">
              <RareButton data-testid="RareButton-xbdk"
                variant="outlined"
                onClick={handleClick}
                icon={<KeyboardArrowDownIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: isMobile ? 0 : 1, px: isMobile ? 1 : 2, fontSize: '14px' }}
              >
                {isMobile ? "" : "Bulk Actions"}
              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-5qzj" title="Refresh Data" placement="top" arrow>
            <Box data-testid="Box-9ley">
              <RareButton data-testid="RareButton-5g0j"
                variant="outlined"
                onClick={() => handleRefreshButton()}
                center={true}
                icon={<RefreshIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >
              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-tzbi" title="Clear All Table Filters" placement="top" arrow>
            <Box data-testid="Box-e74t">
              <RareButton data-testid="RareButton-wrsn"
                variant="outlined"
                onClick={() => handleRemoveAllFilters()}
                icon={<FilterAltOffOutlinedIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >
              </RareButton>
            </Box>
          </Tooltip>
          <Tooltip data-testid="Tooltip-wug4" title="Create New Organisation" placement="top" arrow>
            <Box data-testid="Box-zdjz">
              <RareButton data-testid="RareButton-zf2h"
                onClick={() => openCreatePage()}
                variant="success"
                icon={<DomainAddOutlinedIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: isMobile ? 0 : 1, px: isMobile ? 1 : 2 }}
              >
                {isMobile ? "" : "Create New Organisation"}

              </RareButton>
            </Box>
          </Tooltip>

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem data-testid="MenuItem-971d" disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
              Export to Excel
            </MenuItem>
          </StyledMenu>
        </Box>
      );
    }}

  // renderRowActions={({ row, table }) => (

  //     <Box data-testid="Box-lmd6" sx={{ gap: '0.5rem', display: "flex" }}>
  //     <Tooltip data-testid="Tooltip-dkn3"     
  //         arrow
  //         placement="left"
  //         title="Cycles"
  //         >
  //         <Badge data-testid="Badge-p8af"
  //         badgeContent={row.original.total_cycles}
  //         color={row.original.total_cycles ? 'primary' : 'default'}
  //         anchorOrigin={{
  //             vertical: 'top',
  //             horizontal: 'right',
  //         }}
  //         sx={{
  //         '& .MuiBadge-badge': {
  //             top: '13px',
  //             right: '10px',
  //             background: colorConfigs.tables.headBg,
  //             opacity: 0.98
  //         },
  //         }}
  //         overlap="circular"
  //         >
  //         <IconButton data-testid="IconButton-pzbe"
  //             disabled={!row.original.total_cycles}
  //             component={RouterLink}
  //             to={`/cycles?user_id=${row.original.id}`}
  //             state={{ clinicID: row.original.id }}
  //             sx={{transition: 'transform 0.3s ease'}}
  //         >
  //             <RotateRightOutlinedIcon fontSize="large" sx={{color: row.original.total_cycles ? colorConfigs.sidebar.color: colorConfigs.sidebar.bg}} />
  //         </IconButton>
  //         </Badge>
  //     </Tooltip>
  //     </Box>
  //   )}
  />
}

export default OrganisationsTable;

