import { Card, Grid, Typography, Dialog, Button, FormControlLabel, Checkbox, TextField, Box, Alert, AlertTitle, ListItem, ListItemText, Switch, List, ListSubheader, DialogContent, IconButton, DialogTitle, Divider, Stepper, StepLabel, Step, Snackbar, Tooltip, Collapse, LinearProgress, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { Field, FormikConsumer, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs from 'dayjs';
import { SmallTextEditField, SmallEditReadOnlyField, DatasetSelectField } from '../fields/fields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import colorConfigs from '../../../configs/colorConfigs';
import { DataRunSessionStorageKey, DataRunTableParameterStorageService } from '../../../services/sessionStorage';
import MuiAlert from '@mui/material/Alert';
import sizeConfigs from '../../../configs/sizeConfigs';
import { useDatasets } from '../CustomHooks';
import { Link as RouterLink } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import DataRunMethodCard from './DataRunMethodCard';
import NotificationSnackbarCard, {
    parseErrors,
} from "./NotificationSnackbarCard";
import RareButton from '../buttons/RareButton';
import { Method } from '../../../types/types';


interface DataRunDetailProps {
    datarun_id?: any | null;
    handleRefreshButton: () => void;
    handleBackButton: () => void;
    handleActiveToggle: () => void;
}

const DataRunDetailCard: React.FC<DataRunDetailProps> = ({
    datarun_id,
    handleRefreshButton,
    handleBackButton,
    handleActiveToggle,
}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [datarun, setDataRun] = useState<any>(null);
    const resultsCardRef = React.useRef<{ clearSelectedResults: () => void } | null>(null);
    const [datasetOptions, setDatasetOptions] = useState<any[]>([]) // save or save_and_run
    const datasets = useDatasets();
    const [errors, setErrors] = useState<string | null>(null);
    const [methodId, setMethodId] = useState<number | undefined>();
    const [housingIDforNonDCADataset, setHousingIDforNonDCADataset] = useState<number | undefined>();
    const [methodData, setMethodData] = useState<Method | undefined>();
    const [errorQueue, setErrorQueue] = useState<string[]>([]);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [triggerSaveMethod, setTriggerSaveMethod] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [methodCreationErrors, setMethodCreationErrors] = useState<any>("")
    const [datarunMethodStepsComplete, setDatarunMethodStepsComplete] = useState<boolean>(false)
    const [triggerSaveDatarun, setTriggerSaveDatarun] = useState<boolean>(false)

    const create = !datarun_id

    const validationSchema = () => {
        return Yup.object().shape({

        });
    }

    // Fetch datarun data and point_model JSON data
    useEffect(() => {
        if (datarun_id) {
            const fetchDataRun = async () => {
                try {
                    const url = `dataruns/${datarun_id}/with_dataset`;

                    const response = await api.get(url, {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`
                        }
                    });

                    const fetchedDataRun = response.data;
                    setDataRun(fetchedDataRun);
                    setMethodId(fetchedDataRun.method_id);
                } catch (fetchError: any) {
                    if (fetchError.response !== "Request cancelled... refreshing token") {
                        console.error('Error fetching datarun', fetchError);
                        setErrorQueue([`No Method found with ID ${methodId}`]);
                        setErrors(`No DataRun found with ID ${datarun_id}`)
                        setTimeout(() => {
                            handleBackButton()
                        }, 4000);
                    }
                }
            };
            fetchDataRun();
        }
    }, [datarun_id]);


    const formik = useFormik({
        enableReinitialize: !create,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues:
            (!create && datarun) ? {
                id: datarun.id || '',
                name: datarun.name || '',
                description: datarun.description || '',
                user_id: datarun.user_id || '',
                organisation_id: datarun.organisation_id || '',
                dataset_id: datarun.dataset_id || '',
                date_created: datarun.date_created ? dayjs(datarun.date_created).format('YYYY-MM-DD HH:mm:ss') : null, // Cant edit - Set to null if no date
                status: datarun.status || ''
            }
                : {
                    id: '',
                    name: '',
                    description: '',
                    user_id: '',
                    organisation_id: '',
                    dataset_id: '',
                    date_created: null, // Cant edit - Set to null if no date
                    status: ''
                },
        validationSchema,
        onSubmit: async (values) => {
            setLoading(true)
            const data = {
                name: values.name,
                description: values.description,
                dataset_id: values.dataset_id,
                method_id: methodData?.id,
            }

            const url = datarun ? `dataruns/${datarun.id}` : `dataruns/`
            const method = datarun ? 'patch' : 'post'

            console.log("data", data)
            console.log("url", url)
            console.log("method", method)
            setErrorQueue([]);
            setSuccessMessage("");
            try {
                const response = await api({
                    method,
                    url,
                    data: data,
                    headers: {
                        'Authorization': `Bearer ${authData.access}`,
                    },
                });
                setErrors(null)
                setSuccessMessage(
                    `DataRun successfully ${datarun_id ? "updated" : "created"}.`
                );
                console.log("DataRun successfully updated")
                const reprocessResponse = await api.post(
                    `dataruns/${response.data.id}/reprocess`,
                    { method: methodData },
                    {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`,
                        }
                    },
                );
                console.log("DataRun reprocess started")
                setSuccessMessage(
                    "DataRun reprocess started"
                );
                const count = DataRunTableParameterStorageService.get(DataRunSessionStorageKey.count)
                DataRunTableParameterStorageService.set(DataRunSessionStorageKey.count, count + 1) // Updated count to not trigger refresh data button
                handleRefreshButton();
            } catch (error: any) {
                if (error?.response?.status === 400) {
                    const errors = parseErrors(error.response.data);
                    setErrorQueue(errors);
                    setErrors(error)
                } else {
                    const errorText = `Unknown error ${datarun_id ? "updating" : "creating"
                        } method`;
                    setErrorQueue([errorText]);
                    setErrors(errorText)
                    console.error(errorText, error.response);
                }
            } finally {
                setLoading(false)
            }
        },
    });

    const steps = [
        'Name Datarun',
        'Describe Datarun',
        'Select Dataset',
        'Configure New Method',
        'Save',
    ];

    const status = [
        { label: "Active", value: "ACTIVE" },
        { label: "Inactive", value: "INACTIVE" },
        { label: "Dataset", value: "DATASET" },
    ];

    useEffect(() => {
        if (!create && datarun) {
            const datarunsDataset = [{
                label: datarun.dataset.name,
                value: datarun.dataset.id,
                description: datarun.dataset.desscription,
            }];
            setDatasetOptions(datarunsDataset)
        }
        if (!datarun_id && datasets.datasets.length) {
            const datasetOptions = datasets.datasets.map(dataset => ({
                label: dataset.name,
                value: dataset.id,
                description: dataset.desscription,
            }));
            setDatasetOptions(datasetOptions)
        }
        if (!datarun_id && datasets.datasetError) {
            setErrorQueue([datasets.datasetError])
        }
    }, [datasets.datasets, datasets.datasetError, datarun, create])

    const handleSelectedResultsChange = (result_ids: any[]) => {
        formik.setFieldValue('result_ids', result_ids)
    }

    const handleReset = () => {
        formik.resetForm();
        setLoading(false)
        // Call the clearSelectedResults function in the child
        if (resultsCardRef.current) {
            resultsCardRef.current.clearSelectedResults();
        }

    }

    useEffect(() => {
        if (create && formik.values.dataset_id) {
            const fetchDataSet = async () => {
                try {
                    const url = `datasets/${formik.values.dataset_id}/with_results`;

                    const response = await api.get(url, {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`
                        }
                    });

                    const fetchedDataSet = response.data;
                    const results = fetchedDataSet.results;

                    console.log(results);

                    if (results.length > 0) {

                        // If normal results in the dataset then find the most common method, or if results are in DCA mode then create new method
                        if (!results[0].test_configuration.collection_mode) {
                            // Create a map to count occurrences of method_id
                            const methodCount: any = {};
                            results.forEach((item: any) => {
                                const methodId = item.method_id;
                                methodCount[methodId] = (methodCount[methodId] || 0) + 1;
                            });

                            // Find the most common method_id
                            let mostCommonMethodID = null;
                            let maxCount = 0;
                            for (const [methodId, count] of Object.entries(methodCount as number)) {
                                if (count > maxCount) {
                                    mostCommonMethodID = methodId;
                                    maxCount = count;
                                }
                            }

                            // If no duplicates, pick the first method_id
                            if (!mostCommonMethodID) {
                                mostCommonMethodID = results[0].method_id;
                            }

                            // Set the most common method_id
                            setMethodId(mostCommonMethodID);
                            setHousingIDforNonDCADataset(undefined)
                            console.log("Most common method_id:", mostCommonMethodID);
                        } else {
                            setHousingIDforNonDCADataset(results[0].housing_id)
                            setMethodId(undefined);
                        }
                    } else {
                        console.warn("No results found in dataset.");
                        setErrorQueue([`No results found for DataSet ID ${formik.values.dataset_id}`])
                    }
                } catch (fetchError: any) {
                    if (fetchError.response !== "Request cancelled... refreshing token") {
                        console.error('Error fetching dataset', fetchError);
                        setErrorQueue([`No DataSet found with ID ${formik.values.dataset_id}`])
                        // setErrors(`No DataSet found with ID ${formik.values.dataset_id}`);
                        setTimeout(() => {
                            handleBackButton();
                        }, 4000);
                    }
                }
            };

            fetchDataSet();
        }
    }, [formik.values.dataset_id])

    // Function to determine the current active step
    const getActiveStep = () => {
        if (!formik.values.name) return 0;
        if (!formik.values.description) return 1
        if (!formik.values.dataset_id) return 2;
        if (!datarunMethodStepsComplete) return 3;
        return 4; // All previous steps are complete, move to Save step
    };

    const activeStep = getActiveStep();
    const isSaveStep = activeStep === steps.length - 1;


    const handleSaveAndRun = () => {
        setTriggerSaveMethod(true)
        setLoading(true)
        setTriggerSaveDatarun(true)
    }
    useEffect(() => {
        if (
            methodData?.id &&
            datarunMethodStepsComplete &&
            triggerSaveMethod &&
            triggerSaveDatarun &&
            !methodCreationErrors
        ) {
            formik.submitForm();
        } else if (methodCreationErrors) {
            const errorDetails =
                methodCreationErrors.response && methodCreationErrors.response.data
                    ? JSON.stringify(methodCreationErrors.response.data, null, 2)
                    : JSON.stringify(methodCreationErrors, null, 2);
            console.log("Error Creating Datarun", errorDetails);
            setErrors(errorDetails)
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [
        methodData,
        datarunMethodStepsComplete,
        triggerSaveMethod,
        triggerSaveDatarun,
        methodCreationErrors,
    ]);

    return (
        <FormikProvider value={formik}>
            <form>

                {/* Outer Container */}
                <Grid
                    container
                    direction="column"
                    sx={{
                        m: 0,
                        minHeight: sizeConfigs.detailPage.dialogContent,
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around", // Ensures even vertical spacing
                        alignItems: "stretch", // Ensures grids stretch properly
                        overflowY: "auto",
                        px: { xs: 8, xl: 20 },
                    }}
                >
                    {/* item 1 */}
                    {create &&
                        <Grid item xs={12} md={2} sx={{ pt: { xs: 3, lg: 4, xl: 6 }, height: "auto", width: '100%' }}>
                            <Grid container sx={{ gap: 4, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Grid item xs={12} md={3}>
                                    <Typography data-testid="Typography-jjo0" sx={{ fontSize: 36, fontWeight: "bold" }}>Create Datarun</Typography>
                                </Grid>
                                <Grid item xs={12} md={8} sx={{ mr: { xs: -8, xl: -8 } }}>
                                    <Stepper data-testid="Stepper-o8fg" activeStep={activeStep} alternativeLabel>
                                        {steps.map((label, index) => (
                                            <Step data-testid="Step-k7q6" key={label} completed={index < activeStep}>
                                                <StepLabel data-testid="StepLabel-qamo"
                                                    sx={{
                                                        '& .MuiStepIcon-root': {
                                                            color: index < activeStep ? colorConfigs.buttons.active : undefined, // Green for completed
                                                        },
                                                        '& .MuiStepIcon-text': {
                                                            fill: '#fff', // Optional: Adjust text color for contrast
                                                        },
                                                    }}
                                                >
                                                    {label}
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {/* item 2  */}
                    <Grid item xs={12} md={2} sx={{ pt: { xs: 3, lg: 4, xl: 6 }, flexGrow: 1, height: "100%", width: '100%' }}>

                        <Grid container spacing={!datarun_id ? 5 : 0} direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }} sx={{ pr: { xs: 4, md: 0 }, gap: 0, display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={2.5} sx={{}}>
                                <Typography data-testid="Typography-yxao" sx={{ color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>Name</Typography>
                                <Box data-testid="Box-djgr" sx={{ pl: 1.5 }}>
                                    <Field data-testid="Field-4bfn" name="name" type="text" className="form-control" placeholder="Name Datarun" component={!datarun_id ? SmallTextEditField : SmallEditReadOnlyField} style={{ marginTop: 8 }} />
                                </Box>
                            </Grid>
                            <Grid item xs={!!datarun_id ? 6 : 9} sx={{}}>
                                <Typography data-testid="Typography-unlq" sx={{ color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>Description</Typography>
                                <Box data-testid="Box-x8fy" sx={{ pl: 1.5 }}>
                                    <Field data-testid="Field-d75x" name="description" type="text" className="form-control" placeholder="Describe Datarun" component={!datarun_id ? SmallTextEditField : SmallEditReadOnlyField} style={{ marginTop: 8 }} />
                                </Box>
                            </Grid>
                            {!!datarun_id &&
                                <Grid item xs={2.5} sx={{}}>
                                    <Typography data-testid="Typography-xlz1" sx={{ color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>ID</Typography>
                                    <Box data-testid="Box-m2b4" sx={{ pl: 1.5 }}>
                                        <Field data-testid="Field-yvh2" name="id" type="text" className="form-control" placeholder="ID" component={SmallEditReadOnlyField} style={{ marginTop: 8 }} />
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Grid>


                    {/* item 4  */}
                    <Grid item xs={12} sx={{ pt: { xs: 3, lg: 4, xl: 6 }, height: "auto", mt: "auto", width: '100%' }}>
                        <Box data-testid="Box-tphu" sx={{ display: 'flex' }}>
                            <Box data-testid="Box-sxhl" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography data-testid="Typography-83wf" sx={{ color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>Select Dataset</Typography>

                                <Tooltip data-testid="Tooltip-mp7j"
                                    arrow
                                    placement="top"
                                    title="View Dataset"
                                >
                                    <IconButton data-testid="IconButton-ffau"
                                        disabled={!formik.values.dataset_id}
                                        component={RouterLink}
                                        to={`/data_analysis/data_set_management/${formik.values.dataset_id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            transition: 'transform 0.3s ease',
                                            color: formik.values.dataset_id ? colorConfigs.sidebar.color : colorConfigs.sidebar.bg,
                                            opacity: formik.values.dataset_id ? 1 : 0.5, // Apply opacity for disabled state
                                            '&:disabled': {
                                                color: colorConfigs.sidebar.color,
                                                opacity: 0.5, // Ensure opacity remains for disabled state
                                            },
                                        }}
                                    >
                                        <VisibilityOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip data-testid="Tooltip-bxy6"
                                    arrow
                                    placement="top"
                                    title="Create New Dataset"
                                >
                                    <IconButton data-testid="IconButton-teif"
                                        component={RouterLink}
                                        to={`/data_analysis/data_set_management/create`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ transition: 'transform 0.3s ease' }}
                                    >
                                        <PlaylistAddIcon sx={{ color: colorConfigs.sidebar.color }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip data-testid="Tooltip-pjsr"
                                    arrow
                                    placement="top"
                                    title="Refresh Datasets"
                                >
                                    <IconButton data-testid="IconButton-lmkr"
                                        onClick={() => datasets.fetchDatasets()} // Wrap in an arrow function
                                        sx={{ transition: 'transform 0.3s ease' }}
                                    >
                                        <RefreshIcon sx={{ fontSize: '24.5px', color: colorConfigs.sidebar.color }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box data-testid="Box-zpyx" sx={{ pl: 1.5 }}>
                            <Field data-testid="Field-hkpw"
                                name="dataset_id"
                                component={DatasetSelectField}
                                options={datasetOptions}
                                placeholder="Select Dataset"
                            />
                        </Box>
                    </Grid>

                    <Collapse in={!!formik.values.dataset_id}>
                        <Grid item xs={12} sx={{ pt: { xs: 3, lg: 4, xl: 6 }, height: "auto", mt: "auto", width: '100%' }}>
                            <Box data-testid="Box-haz7" sx={{ display: 'flex', alignItems: 'center', maxWidth: `88vw`, minWidth: `88vw`, gap: 1, }}>
                                <Typography data-testid="Typography-emf5" sx={{ color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold', minWidth: '10em' }}>{create ? `Configure Method` : `Datarun Method`}</Typography>
                                {methodId && create && <Typography data-testid="Typography-qja9" sx={{ fontSize: 14, minWidth: '25em' }}> (This method is initially set to the most common method used in your Dataset (Method ID: {methodId}).  Add Name and Description of this new method and then configure your method for reprocessing.)</Typography>
                                }
                            </Box>
                            <Box data-testid="Box-d35c" sx={{ px: { xs: -1, xl: -1 }, ml: 1, mt: 1, border: 'solid 3px', borderColor: colorConfigs.primary, borderRadius: 3 }}>
                                <DataRunMethodCard
                                    handleBackButton={() => { }}
                                    methodId={methodId}
                                    isForDatasetCreation={true}
                                    setMethodId={setMethodId}
                                    setMethodData={setMethodData}
                                    triggerSaveMethod={triggerSaveMethod}
                                    housingIDforNonDCADataset={housingIDforNonDCADataset}
                                    createDatarun={create}
                                    dataset_id={formik.values.dataset_id}
                                    setMethodCreationErrors={setMethodCreationErrors}
                                    setDatarunMethodStepsComplete={setDatarunMethodStepsComplete}
                                />
                            </Box>
                        </Grid>
                    </Collapse>

                    <>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{
                                pt: 5,
                                pb: 10,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignSelf: "flex-end",
                                gap: 2 // Add gap between buttons
                            }}
                        >
                            {errors && (
                                <Alert data-testid="Alert-20wr" severity="error" style={{ width: "100%%" }}>
                                    <AlertTitle data-testid="AlertTitle-9dq1">Error</AlertTitle>
                                    {errors}
                                </Alert>
                            )}
                            {create &&
                                <RareButton data-testid="RareButton-pfxr"
                                    variant="outlined"
                                    onClick={handleReset} // Reset form values to initial
                                    sx={{
                                        mt: { xs: 3, sm: 3, md: 3, lg: 0 },
                                        minWidth: '140px',
                                        maxWidth: '140px',
                                    }}
                                >
                                    Reset
                                </RareButton>
                            }
                            {create &&
                                <Tooltip data-testid="Tooltip-0zkk" title={!isSaveStep ? "Complete all steps to Save" : ""}>
                                    <span data-testid="span-p1kz">
                                        <RareButton data-testid="RareButton-0lxv"
                                            onClick={() => handleSaveAndRun()}
                                            variant="success"
                                            disabled={!isSaveStep}
                                            icon={loading ? <CircularProgress data-testid="CircularProgress-57km" sx={{ maxHeight: '20px', maxWidth: '20px' }} /> : null}
                                            sx={{
                                                mt: { xs: 3, sm: 3, md: 3, lg: 0 },
                                                minWidth: '140px',
                                                maxWidth: '140px',
                                            }}
                                        >
                                            Save & Run
                                        </RareButton>
                                    </span>
                                </Tooltip>
                            }
                        </Grid>
                    </>
                </Grid>
                <NotificationSnackbarCard
                    errorQueue={errorQueue}
                    successMessage={successMessage}
                    setErrorQueue={setErrorQueue}
                    setSuccessMessage={setSuccessMessage}
                />
            </form>
        </FormikProvider>
    );
};

export default DataRunDetailCard;