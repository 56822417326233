import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import * as Yup from "yup";
import { Field, Form, Formik, FormikProvider, useFormik } from "formik";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { Button } from "@mui/material";
import { InvoiceSessionStorageKey, InvoiceSessionStorageModel, InvoiceTableParameterStorageService } from "../../../services/sessionStorage";
import RareButton from "../buttons/RareButton";

const UpdateMyPaymentMethodForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const authData = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const validationSchema = () => {
    return Yup.object().shape({});
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {},
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      InvoiceTableParameterStorageService.set(InvoiceSessionStorageKey.updatedCardDetails, true)

      if (!stripe || !elements) {
        console.error("Stripe has not loaded yet.");
        setLoading(false);
        return;
      }

      try {
        const response = await api.post(
          "payments/checkout-session-for-new-payment-method/",
          {},
          {
            headers: {
              Authorization: `Bearer ${authData.access}`,
            },
          }
        );

        const session = response.data;

        // Redirect to Stripe Checkout
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
        if (result.error) {
          console.error("ERROR:", result.error.message);
          setError(`An unexpected error occurred: ${result.error.message}`);
        }
      } catch (error: any) {
        console.error("Error during checkout:", error);
        const errorTxt = JSON.stringify(error?.response?.data);
        setError(`An unexpected error occurred: ${errorTxt || error}`);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
            <RareButton data-testid="Button-9kp9" 
                type='submit'
                disabled={authData.isFreeUser}
                variant="contained"
                sx={{textAlign: 'center', marginX: 'auto', textTransform: 'none', fontWeight: 'bold', mt: 2, mb:1, width: '100%'}}>
                  {loading ? "Processing..." : "Update Payment Method"}
            </RareButton>
        </form>
      </FormikProvider>
      {error && <div data-testid="div-z6rr" style={{ color: "red" }}>{error}</div>}
    </>
  );
};

export default UpdateMyPaymentMethodForm;
