import {
  Corners,
  CornerLabels,
  Direction,
  Matrix3x3,
  Point,
} from "../types/types";

// Reshape the homography list into a 3x3 matrix
const reshapeTo3x3 = (flatList: number[]): Matrix3x3 => {
  if (flatList.length !== 9) {
    throw new Error("Input list must contain exactly 9 elements.");
  }
  return [
    [flatList[0], flatList[1], flatList[2]],
    [flatList[3], flatList[4], flatList[5]],
    [flatList[6], flatList[7], flatList[8]],
  ];
};

// Calculate the inverse of a 3x3 matrix
const inverse3x3 = (matrix: Matrix3x3): Matrix3x3 => {
  const [[a, b, c], [d, e, f], [g, h, i]] = matrix;

  const det = a * (e * i - f * h) - b * (d * i - f * g) + c * (d * h - e * g);
  if (det === 0) {
    throw new Error("Matrix is singular and cannot be inverted.");
  }

  const invDet = 1 / det;

  return [
    [
      (e * i - f * h) * invDet,
      (c * h - b * i) * invDet,
      (b * f - c * e) * invDet,
    ],
    [
      (f * g - d * i) * invDet,
      (a * i - c * g) * invDet,
      (c * d - a * f) * invDet,
    ],
    [
      (d * h - e * g) * invDet,
      (b * g - a * h) * invDet,
      (a * e - b * d) * invDet,
    ],
  ];
};

// Combine both steps
export const getHomographyInverse = (homography: number[]): Matrix3x3 => {
  const h = reshapeTo3x3(homography);
  return inverse3x3(h);
};

export const getTransformedPoint = (
  point: Point,
  inverseHomography: Matrix3x3,
): Point => {
  const [px, py] = point;
  const denominator =
    inverseHomography[2][0] * px +
    inverseHomography[2][1] * py +
    inverseHomography[2][2];
  const transX =
    (inverseHomography[0][0] * px +
      inverseHomography[0][1] * py +
      inverseHomography[0][2]) /
    denominator;
  const transY =
    (inverseHomography[1][0] * px +
      inverseHomography[1][1] * py +
      inverseHomography[1][2]) /
    denominator;
  return [transX, transY];
};

export const arraysAreEqual = (arr1: number[][], arr2: number[][]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((subArr1, index) => {
    const subArr2 = arr2[index];

    // Check lengths and values of subarrays
    return (
      subArr1.length === subArr2.length &&
      subArr1.every((value, subIndex) => value === subArr2[subIndex])
    );
  });
};

/**
 * Computes the fourth corner of a parallelogram given three arbitrary corners.
 *
 * A parallelogram is defined by four points where opposite sides are equal in length and parallel.
 * Given three known corners, this function calculates the missing fourth corner by leveraging vector properties.
 *
 * @param {Point} p1 - The reference corner, which is opposite to the missing fourth corner.
 * @param {Point} p2 - The second known corner of the parallelogram.
 * @param {Point} p3 - The third known corner of the parallelogram.
 * @returns {Point} - The calculated fourth corner of the parallelogram as a tuple [x, y].
 *
 * @example
 * const p1: Point = [0, 0];
 * const p2: Point = [4, 1];
 * const p3: Point = [3, 3];
 * const p4 = findFourthCorner(p1, p2, p3); // Returns the missing fourth corner
 * console.log(p4);
 */
const findFourthCorner = (p1: Point, p2: Point, p3: Point): Point => {
  return [p2[0] + p3[0] - p1[0], p2[1] + p3[1] - p1[1]];
};

/**
 * Calculates the Manhattan distance between two points.
 *
 * The Manhattan distance (also known as Taxicab or L1 distance) is the sum
 * of the absolute differences of their Cartesian coordinates. It represents
 * the distance a path would take in a grid-like system.
 *
 * @param {Point} p1 - The first point as a tuple [x, y].
 * @param {Point} p2 - The second point as a tuple [x, y].
 * @returns {number} - The Manhattan distance between the two points.
 *
 * @example
 * const p1: Point = [1, 2];
 * const p2: Point = [4, 6];
 * console.log(manhattanDistance(p1, p2)); // Output: 7
 */
const manhattanDistance = (p1: Point, p2: Point): number => {
  // return Math.sqrt((p2[0] - p1[0]) ** 2 + (p2[1] - p1[1]) ** 2);
  return Math.abs(p2[0] - p1[0]) + Math.abs(p2[1] - p1[1]);
};

/**
 * Computes the manhathan distances of the sides of a triangle given
 * its three vertices.
 * Each length corresponds to the side opposite to the respective point in the input array.
 *
 * @param points An array of three points representing a triangle.
 * @returns An array where each element is the length of the side opposite to the respective point.
 */
export const triangleSideLengths = (points: Point[]): number[] => {
  const [a, b, c] = points;

  const sideOppositeA = manhattanDistance(b, c); // Side bc
  const sideOppositeB = manhattanDistance(a, c); // Side ac
  const sideOppositeC = manhattanDistance(a, b); // Side ab

  return [sideOppositeA, sideOppositeB, sideOppositeC];
};

export const maxWithIndex = (
  distances: number[],
): { maxValue: number; index: number } => {
  return distances.reduce(
    (acc, value, index) =>
      value > acc.maxValue ? { maxValue: value, index } : acc,
    { maxValue: -Infinity, index: -1 },
  );
};

/**
 * Assigns labels to the corners of a parallelogram based on their relative positions.
 * The function correctly determines the top-left, top-right, bottom-left, and bottom-right
 * corners regardless of their initial order in the input array.
 *
 * @param {Point[]} corners - An array of four points representing the corners of a parallelogram.
 * @returns {Record<CornerLabels, Point>} - An object mapping each corner label to its corresponding point.
 *
 * @example
 * const corners: Point[] = [[0, 0], [4, 1], [3, 3], [-1, 2]];
 * console.log(assignCornerLabels(corners));
 * { "BOTTOM_LEFT": [-1, 2], "BOTTOM_RIGHT": [4, 1], "TOP_RIGHT": [3, 3], "TOP_LEFT": [0, 0] }
 */
export const assignCornerLabels = (corners: Point[]): Corners => {
  if (corners.length !== 4) {
    throw new Error("Expected exactly 4 corners.");
  }

  const sortedByY = [...corners].sort((a, b) => a[1] - b[1]);

  const topTwo = sortedByY.slice(0, 2);
  const bottomTwo = sortedByY.slice(2, 4);

  const [topLeft, topRight] = topTwo.sort((a, b) => a[0] - b[0]);
  const [bottomLeft, bottomRight] = bottomTwo.sort((a, b) => a[0] - b[0]);

  return {
    TOP_LEFT: topLeft,
    TOP_RIGHT: topRight,
    BOTTOM_LEFT: bottomLeft,
    BOTTOM_RIGHT: bottomRight,
  };
};

/**
 * Computes the missing fourth corner of a parallelogram given three known corners.
 *
 * This function first identifies the longest side of the triangle formed by
 * the three given points. The corner opposite to this longest side is used as a reference
 * to compute the fourth corner, ensuring the resulting shape is a parallelogram.
 *
 * @param {Point[]} corners - An array of three known points forming part of a parallelogram.
 * @returns {Point[]} - An array containing the original three points plus the computed fourth corner.
 *
 * @example
 * const corners: Point[] = [[0, 0], [4, 1], [3, 3]];
 * const completeCorners = computeFourthCorner(corners);
 * console.log(completeCorners); // Returns an array of four points forming a parallelogram
 */
export const addFourthCorner = (corners: Point[]): Point[] => {
  const distances = triangleSideLengths(corners);
  const { index } = maxWithIndex(distances);
  const firstCorner = corners[index];
  const [a, b] = corners.filter((_, i) => i !== index);
  const fourthCorner = findFourthCorner(firstCorner, a, b);
  return [firstCorner, a, b, fourthCorner];
};

/**
 * Compares two Corners objects to check if they contain the same points.
 *
 * @param {Corners | undefined} cornersA - The first Corners object to compare.
 * @param {Corners | undefined} cornersB - The second Corners object to compare.
 * @returns {boolean} - Returns `true` if both objects are equal or both are `undefined`, otherwise `false`.
 */
export const areCornersEqual = (
  cornersA: Corners | undefined,
  cornersB: Corners | undefined,
): boolean => {
  if (!cornersA || !cornersB) {
    return cornersA === cornersB; // Returns true if both are undefined, false otherwise
  }

  const labels: CornerLabels[] = [
    "TOP_LEFT",
    "TOP_RIGHT",
    "BOTTOM_RIGHT",
    "BOTTOM_LEFT",
  ];

  return labels.every(
    (label) =>
      cornersA[label][0] === cornersB[label][0] &&
      cornersA[label][1] === cornersB[label][1],
  );
};
