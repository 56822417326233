import React from "react";
import HomePage from "../pages/home/HomePage";
import ResultPage from "../pages/results/ResultsPage";
import { RouteType } from "./config";
import FeedbackPage from "../pages/feedback/FeedbackPage";
import UsersPage from "../pages/users/UsersPage";
import AccountPage from "../pages/account/AccountPage";
import PaymentPage from "../pages/payments/PaymentPage";
import PaymentSuccessPage from "../pages/payments/PaymentSuccessPage";
import PaymentCancelPage from "../pages/payments/PaymentCancelPage";
import SubscriptionPage from "../pages/account/SubscriptionPage";
import OrganisationsPage from "../pages/organisations/OrganisaitonsPage";
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import Crop169Icon from '@mui/icons-material/Crop169';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import PhoneSetupPage from "../pages/phone_setup/PhoneSetupPage";
import AboutPage from "../pages/about/AboutPage";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DataAnalysisPage from "../pages/data_analysis/DataAnalysisPage";
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';
import InvoicesPage from "../pages/account/InvoicePage";
import OrganisationPage from "../pages/account/MyOrganisationPage";
import WhitelistPage from "../pages/phone_setup/WhitelistPage";
import HFactorPage from "../pages/phone_setup/HFactorPage";
import PhoneSensitivityPage from "../pages/phone_setup/PhoneSensitivityPage";
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import BiotechIcon from '@mui/icons-material/Biotech';
import CalibrationManagementPage from "../pages/data_analysis/CalibrationManagementPage";
import InterpreterManagementPage from "../pages/data_analysis/InterpreterManagementPage";
import PerformanceReportsPage from "../pages/data_analysis/PerformanceReportsPage";
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import PermDataSettingOutlinedIcon from '@mui/icons-material/PermDataSettingOutlined';
import SubscriptionManagementPage from "../pages/account/SubscriptionManagementPage";
import LFConfigurationPage from "../pages/method/LFConfigurationPage";
import HousingPage from "../pages/method/HousingPage";
import MethodsPage from "../pages/method/MethodsPage";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PlaylistPlayOutlinedIcon from '@mui/icons-material/PlaylistPlayOutlined'; // runs
import DatasetManagmentPage from "../pages/data_analysis/DatasetManagmentPage";
import DataRunManagmentPage from "../pages/data_analysis/DataRunManagmentPage";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import SwaggerPage from "../pages/api/SwaggerPage";
import APIIcon from '@mui/icons-material/ApiRounded';

export const appRoutes: RouteType[] = [
    {
        index: true,
        element: <HomePage />,
        state: "dashboard"
    },
    {
        path: "/dashboard",
        element: <HomePage />,
        state: "dashboard",
        sidebarProps: {
            displayText: "Dashboard",
            icon: <GridViewOutlinedIcon fontSize="large" />,
        },
    },
    {
        path: "/results",
        element: <ResultPage />,
        state: "results",
        sidebarProps: {
            displayText: "Results",
            icon: <InsertChartOutlinedIcon fontSize="large" />,
        },
    },
    {
        path: "/results/:paramID",
        element: <ResultPage />,
        state: "results",
    },
    {
        path: "/feedback",
        element: <FeedbackPage />,
        state: "feedback",
        sidebarProps: {
            displayText: "Feedback",
            icon: <FeedbackOutlinedIcon fontSize="large" />,
        },
    },
    {
        path: "/feedback/:paramID",
        element: <FeedbackPage />,
        state: "feedback",
    },
    {
        path: "/account/", // Only For Org Admins
        element: <AccountPage />,
        state: "account",
        sidebarProps: {
            displayText: "Account\u00A0Management",
            icon: <ManageAccountsOutlinedIcon fontSize="large" />,
        },
        child: [
            {
                path: "/account/organisation",
                element: <OrganisationPage />,
                state: "account.organisation",
                sidebarProps: {
                    displayText: "Organisation",
                    icon: <HomeWorkOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/account/organisation/:paramID",
                element: <OrganisationPage />,
                state: "account.organisation",
            },
            {
                path: "/account/subscription",
                element: <SubscriptionPage />,
                state: "account.subscription",
                sidebarProps: {
                    displayText: "Subscription",
                    icon: <ShoppingCartCheckoutOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/account/subscription/:paramID",
                element: <SubscriptionPage />,
                state: "account.subscription",
            },
            {
                path: "/account/users",
                element: <UsersPage />,
                state: "account.users",
                sidebarProps: {
                    displayText: "Users",
                    icon: <PeopleAltOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/account/users/:paramID",
                element: <UsersPage />,
                state: "account.users",
            },
            {
                path: "/account/invoices",
                element: <InvoicesPage />,
                state: "account.invoices",
                sidebarProps: {
                    displayText: "Billing",
                    icon: <PeopleAltOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/account/invoices/:paramID",
                element: <InvoicesPage />,
                state: "account.invoices",
            },
        ]
    },
    {
        path: "/rare-admin/", // Only For Admins
        element: <AccountPage />,
        state: "admin",
        sidebarProps: {
            displayText: "Admin\u00A0Panel",
            icon: <ManageAccountsOutlinedIcon fontSize="large" />,
        },
        child: [
            {
                path: "/rare-admin/organisations",
                element: <OrganisationsPage />,
                state: "admin.organisations",
                sidebarProps: {
                    displayText: "Organisations",
                    icon: <HomeWorkOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/rare-admin/organisations/:paramID",
                element: <OrganisationsPage />,
                state: "admin.organisations",
            },
            {
                path: "/rare-admin/subscriptions",
                element: <SubscriptionManagementPage />,
                state: "admin.subscriptions",
                sidebarProps: {
                    displayText: "Subscriptions",
                    icon: <ShoppingCartCheckoutOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/rare-admin/subscriptions/:paramID",
                element: <SubscriptionManagementPage />,
                state: "admin.subscriptions",
            },
            {
                path: "/rare-admin/users",
                element: <UsersPage />,
                state: "admin.users",
                sidebarProps: {
                    displayText: "Users",
                    icon: <PeopleAltOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/rare-admin/users/:paramID",
                element: <UsersPage />,
                state: "admin.users",
            },
            {
                path: "/rare-admin/invoices",
                element: <InvoicesPage />,
                state: "admin.invoices",
                sidebarProps: {
                    displayText: "Invoices",
                    icon: <PeopleAltOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/rare-admin/invoices/:paramID",
                element: <InvoicesPage />,
                state: "admin.invoices",
            },
        ]
    },
    {
        path: "/phone_management/",
        element: <PhoneSetupPage />,
        state: "phone_management",
        sidebarProps: {
            displayText: "Phone\u00A0Management",
            icon: <PhoneAndroidOutlinedIcon fontSize="large" />,
        },
        child: [
            {
                path: "/phone_management/whitelist",
                element: <WhitelistPage />,
                state: "phone_management.whitelist",
                sidebarProps: {
                    displayText: "WhiteList",
                    icon: <CheckCircleOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/phone_management/whitelist/:paramID",
                element: <WhitelistPage />,
                state: "phone_management.whitelist",
            },
            {
                path: "/phone_management/h_factor",
                element: <HFactorPage />,
                state: "phone_management.hfactor",
                sidebarProps: {
                    displayText: "H\u00A0Factor",
                    icon: <SignalCellularAltOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/phone_management/h_factor/:paramID",
                element: <HFactorPage />,
                state: "phone_management.hfactor",
            },
            {
                path: "/phone_management/phone_sensitivity",
                element: <PhoneSensitivityPage />,
                state: "phone_management.phonesensitivity",
                sidebarProps: {
                    displayText: "Phone\u00A0Sensitivity",
                    icon: <TouchAppOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/phone_management/phone_sensitivity/:paramID",
                element: <PhoneSensitivityPage />,
                state: "phone_management.phonesensitivity",
            },
        ]
    },
    {
        path: "/lf_configuration",
        element: <LFConfigurationPage />,
        state: "lf_configuration",
        sidebarProps: {
            displayText: "LF\u00A0Configuration",
            icon: <DataObjectOutlinedIcon fontSize="large" />,
        },
        child: [
            {
                path: "/lf_configuration/methods",
                element: <MethodsPage />,
                state: "lf_configuration.method",
                sidebarProps: {
                    displayText: "Method Management",
                    icon: <BiotechIcon fontSize="large" />,
                },
            },
            {
                path: "/lf_configuration/methods/:paramID",
                element: <MethodsPage />,
                state: "lf_configuration.methods",
            },
            {
                path: "/lf_configuration/housing",
                element: <HousingPage />,
                state: "lf_configuration.housing",
                sidebarProps: {
                    displayText: "Housing Management",
                    icon: <Crop169Icon fontSize="large" />,
                },
            },
            {
                path: "/lf_configuration/housing/:paramID",
                element: <HousingPage />,
                state: "lf_configuration.housing",
            },
        ]
    },
    {
        path: "/data_analysis/",
        element: <DataAnalysisPage />,
        state: "data_analysis",
        sidebarProps: {
            displayText: "Data\u00A0Analysis",
            icon: <DataThresholdingOutlinedIcon fontSize="large" />,
        },
        child: [
            {
                path: "/data_analysis/data_set_management",
                element: <DatasetManagmentPage />, // create
                state: "data_analysis.data_set_management",
                sidebarProps: {
                    displayText: "Dataset Management",
                    icon: <MenuOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/data_analysis/data_set_management/:paramID",
                element: <DatasetManagmentPage />,
                state: "data_analysis.data_set_management",
            },
            {
                path: "/data_analysis/data_run_management",
                element: <DataRunManagmentPage />,
                state: "data_analysis.data_run_management",
                sidebarProps: {
                    displayText: "Data Run Management",
                    icon: <PlaylistPlayOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/data_analysis/data_run_management/:paramID",
                element: <DataRunManagmentPage />,
                state: "data_analysis.data_run_management",
            },
            {
                path: "/data_analysis/calibration_management",
                element: <CalibrationManagementPage />,
                state: "data_analysis.calibration_management",
                sidebarProps: {
                    displayText: "Calibration Management",
                    icon: <PermDataSettingOutlinedIcon fontSize="large" />,
                },
            },
            {
                path: "/data_analysis/interpreter_management",
                element: <InterpreterManagementPage />,
                state: "data_analysis.interpreter_management",
                sidebarProps: {
                    displayText: "Interpreter Management",
                    icon: <ChromeReaderModeOutlinedIcon fontSize="large" />,
                },
            },
            // {
            //     path: "/data_analysis/reprocess_data",
            //     element: <ReprocessDataPage />,
            //     state: "data_analysis.reprocess_data",
            //     sidebarProps: {
            //         displayText: "Reprocess Data",
            //         icon: <RotateLeftOutlinedIcon fontSize="large"/>,
            //       },
            // },
            {
                path: "/data_analysis/performance_reports",
                element: <PerformanceReportsPage />,
                state: "data_analysis.performance_reports",
                sidebarProps: {
                    displayText: "Performance reports",
                    icon: <SsidChartOutlinedIcon fontSize="large" />,
                },
            },
        ]
    },
    {
        path: "/api-doc",
        element: <SwaggerPage />,
        state: "api-doc",
        sidebarProps: {
            displayText: "API",
            icon: <APIIcon fontSize="large" />
        }
    },
    {
        path: "/about",
        element: <AboutPage />,
        state: "about",
        sidebarProps: {
            displayText: "About",
            icon: <InfoOutlinedIcon fontSize="large" />,
        },
    },
    {
        path: "/payment",
        element: <PaymentPage />,
        state: "payment",
    },
    {
        path: "/payment/success",
        element: <PaymentSuccessPage />,
        state: "payment-success",
    },
    {
        path: "/payment/cancel",
        element: <PaymentCancelPage />,
        state: "payment-cancel",
    },
]


// Creating mobile routes by extending the desktop routes
export const mobileAppRoutes: RouteType[] = [
    {
        // This route will trigger a profile popup when clicked.
        // You can either render a component that handles this or simply use onClick.
        path: '/profile',
        state: 'profile',
        sidebarProps: {
            displayText: 'Profile',
            icon: <AccountBoxIcon fontSize="large" />,
        },
        onClick: () => {
            // Logic to open your profile popup
            console.log('Profile popup opened');
            // For example, if using a state hook: setIsProfilePopupOpen(true);
        },
    },
    {
        // This route will trigger the logout action when clicked.
        path: '/logout',
        state: 'logout',
        sidebarProps: {
            displayText: 'Logout',
            icon: <LogoutIcon fontSize="large" />,
        },
        onClick: () => {
            console.log('Logout popup opened');
        }
    },
];
