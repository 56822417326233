import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { refreshAccessToken } from '../../../redux/features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../redux/store'
import api from '../../../redux/features/auth/axiosAuthHelper'
import { Box, Paper, Typography } from '@mui/material'  // Example MUI components
import colorConfigs from '../../../configs/colorConfigs'

const TestsGraph: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [xaxis, setXAxis] = useState<string[]>([])
  const [yaxis, setYAxis] = useState<number[]>([])
  const [granular, setGranular] = useState<boolean>(false)
  const [backButton, setBackButton] = useState<boolean>(false)
  const [bucket, setBucket] = useState<string>("0.00-1.00")

  const authData = useSelector((state: RootState) => state.auth)

  const fetchData = async (bucket = "") => {
    try {
      const url = bucket ? `results/dashboard/histogram?range=${bucket}` : 'results/dashboard/histogram'
      const res = await api.get(url, {
        headers: {
          Authorization: `Bearer ${authData.access}`,
        },
      })
      // Expecting res.data to be an array of objects { x: string, y: number }
      console.log('test insight data: ', res.data)
      if (res.data.length > 0) {
        const xaxisValues: string[] = res.data.map((item: { x: string; y: number }) => item.x)
        const yaxisValues: number[] = res.data.map((item: { x: string; y: number }) => item.y)
        setXAxis(xaxisValues)
        setYAxis(yaxisValues)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {

    fetchData()
  }, [authData.access, dispatch])

  const handleChange = (xaxis: [], dpindex: number) => {
    let bucket = xaxis[dpindex]
    console.log(bucket)
    fetchData(bucket)
  }

  const series = [{ name: '', data: yaxis }]

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 250,
      stacked: true,
      events: {
        dataPointSelection: (event, chartContext, config) => {
          // console.log(chartContext, config);
          // console.log(config.w.config.xaxis.categories, config.dataPointIndex)
          if (backButton) {
            fetchData()
            setBackButton(false)
            setBucket("0.00-1.00")
          } else {
            handleChange(config.w.config.xaxis.categories, config.dataPointIndex)
            setBackButton(true)
            let bucket = config.w.config.xaxis.categories[config.dataPointIndex]
            setBucket(bucket)
          }

        }
      }
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    colors: [
      // colorConfigs.dashboard.dark_red,
      // colorConfigs.dashboard.light_grey,
      colorConfigs.buttons.success
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: xaxis,
      title: {
        offsetY: -20,
        text: bucket ? `Range: ${bucket}` : "",
      },
    },
    yaxis: {
      title: {
        text: 'Frequency',
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return `${val}`
        },
      },
    },
  }

  return (
    <Box data-testid="Box-i1fc">
      <ReactApexChart
        key={xaxis.join(',')}
        type="bar"
        options={options}
        series={series}
        height={300}
      />
    </Box>
  )
}

export default TestsGraph
