import React, { useEffect } from 'react'
import { FieldProps, getIn } from 'formik'
import { Box, Badge, IconButton, Checkbox, InputAdornment, MenuItem, Select, TextField, TextFieldProps, Typography, Tooltip } from '@mui/material'
import colorConfigs from '../../../configs/colorConfigs';
import { useRareOrganisations } from '../CustomHooks';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import ResultClassLabel from '../buttons/resultClassLabel';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Switch, FormControlLabel } from '@mui/material';
import fontConfigs from '../../../configs/fontConfigs';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import CloseIcon from "@mui/icons-material/Close";
import 'dayjs/locale/en-gb';

export const TScoreField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-bi4h"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="standard"
      label="TScore"
      margin="normal"
      autoFocus
      InputProps={{
        readOnly: true,
        disableUnderline: true,

      }}
      sx={{
        fontSize: 18
      }}
      InputLabelProps={{
        style: {
          fontSize: 16,
          fontWeight: 1000
        },
        shrink: true
      }}
    />
  )
}

export const ResultDetailTableField: React.FC<FieldProps & TextFieldProps & { color: string }> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, color, ...rest } = props

  return (
    <TextField data-testid="TextField-z1i5"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="standard"
      margin="normal"
      autoFocus
      InputProps={{
        readOnly: true,
        disableUnderline: true,
        sx: {
          fontSize: fontConfigs.detailPage.headings.title,
          minWidth: '100%',
          textAlign: 'center'
        },
      }}
      sx={{
        p: 0.5,
        minWidth: '100%',
        mt: 2.5,
        mb: 0,
      }}
      InputLabelProps={{
        sx: {
          fontSize: fontConfigs.detailPage.headings.tableLabels,
          fontWeight: 1000,
          paddingTop: 1,
          color: '#fff',
          background: color,
          display: 'block',
          width: '100%',
          padding: '4px 12px',
          borderRadius: '4px',
          '&.Mui-focused': {
            color: '#fff'
          },
          '&.MuiFormLabel-root.MuiInputLabel-shrink': {
            color: '#fff'
          }
        },
        shrink: true
      }}
    />
  )
}

export const ResultDetailTableDashboardField: React.FC<FieldProps & TextFieldProps & { color: string }> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, color, ...rest } = props

  return (
    <TextField data-testid="TextField-4i1c"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="standard"
      margin="normal"
      autoFocus
      InputProps={{
        readOnly: true,
        disableUnderline: true,
        sx: {
          fontSize: fontConfigs.detailPage.headings.title,
          minWidth: '100%',
          textAlign: 'center'
        },
      }}
      sx={{
        p: 0.5,
        minWidth: '100%',
        mt: 2.5,
        mb: 0,
      }}
      InputLabelProps={{
        sx: {
          fontSize: fontConfigs.detailPage.headings.tableLabels,
          fontWeight: 1000,
          paddingTop: 1,
          color: color,
          display: 'block',
          width: '100%',
          padding: '4px 12px',
          borderRadius: '4px',
          '&.Mui-focused': {
            color: '#000'
          },
          '&.MuiFormLabel-root.MuiInputLabel-shrink': {
            color: '#000'
          }
        },
        shrink: true
      }}
    />
  )
}

export const TPeakField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-16wy"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="standard"
      label="TPeak"
      margin="normal"
      autoFocus
      InputProps={{
        readOnly: true,
        disableUnderline: true,

      }}
      sx={{
        fontSize: 18
      }}
      InputLabelProps={{
        style: {
          fontSize: 16,
          fontWeight: 1000
        },
        shrink: true
      }}
    />
  )
}
export const TCRatioField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-39rh"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="standard"
      label="TCRatio"
      margin="normal"
      autoFocus
      InputProps={{
        readOnly: true,
        disableUnderline: true,

      }}
      sx={{
        fontSize: 18
      }}
      InputLabelProps={{
        style: {
          fontSize: 16,
          fontWeight: 1000
        },
        shrink: true
      }}
    />
  )
}
export const CScoreField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-r1st"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="standard"
      label="CScore"
      margin="normal"
      autoFocus
      InputProps={{
        readOnly: true,
        disableUnderline: true,

      }}
      sx={{
        fontSize: 18
      }}
      InputLabelProps={{
        style: {
          fontSize: 16,
          fontWeight: 1000
        },
        shrink: true
      }}
    />
  )
}
export const CPeakField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-0ot7"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="standard"
      label="CPeak"
      margin="normal"
      autoFocus
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      sx={{
        fontSize: 18
      }}
      InputLabelProps={{
        style: {
          fontSize: 16,
          fontWeight: 1000
        },
        shrink: true
      }}

    />
  )
}


export const ResultDetailReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-2fdc"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      autoFocus

      InputProps={{
        readOnly: true,
      }}
      sx={{
      }}
      InputLabelProps={{
        style: {
          fontSize: "18px",
          marginLeft: "-4px"
        },
        shrink: true
      }}
    />
  )
}


interface FormVisualResultsFieldProps extends FieldProps {
  visualResult: string;
  resultColor: string;
}

export const FormVisualResultsField: React.FC<FormVisualResultsFieldProps & TextFieldProps> = ({
  visualResult,
  resultColor,
  ...props
}) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, ...rest } = props;
  return (
    <TextField data-testid="TextField-hef0"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      margin="normal"
      autoFocus
      sx={{
        fontSize: 18,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&:hover fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&.Mui-focused fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '& legend': {
            width: 'auto',
          },
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 22,
          fontWeight: 1000,
          top: '-3px',
        },
        shrink: true
      }}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
        sx: {
          padding: 1.5,
          pl: 3,
          fontSize: 18,
        },
        startAdornment: (
          <InputAdornment position="start" style={{ width: "100%", marginRight: 'auto', marginLeft: 'auto', height: '100%' }}>
            <Box data-testid="Box-tyjj" sx={{ minWidth: "100%", background: resultColor, p: 0.5, fontWeight: "bold", display: "flex", justifyContent: "center", borderRadius: 2, overflow: "auto" }}>
              <Typography data-testid="Typography-syo2" variant="h6" sx={{ color: '#fff', minWidth: "5em", textAlign: "center", fontWeight: "normal" }}>{visualResult}</Typography>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};


interface TextAdornmentReadOnlyFieldProps extends FieldProps {
  status: boolean;
  id: string;
}
export const TextAdornmentReadOnlyField: React.FC<TextAdornmentReadOnlyFieldProps & TextFieldProps> = ({
  status,
  id,
  ...props
}) => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-qtr4"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      autoFocus

      InputProps={{
        readOnly: true,
        disableUnderline: true,
        sx: {
          fontSize: 14,
          '& input': {
            padding: 1,
            paddingX: 2,
          },
        },
        startAdornment: (
          <InputAdornment position="start">
            <Typography data-testid="Typography-tcyb" sx={{ p: 0, m: 0, pl: 2, minWidth: "2em", fontSize: 14, fontWeight: 'bold' }}>
              {id}
            </Typography>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment data-testid="InputAdornment-k1ok" position="end" style={{}}>
            {status ? <Typography data-testid="Typography-1bil" sx={{ fontSize: "10px", marginX: 0.5, paddingX: 0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography> : <Typography data-testid="Typography-m70h" sx={{ fontSize: "10px", marginX: 0.5, paddingX: 0.7, border: `1.5px solid ${colorConfigs.buttons.inactive}`, borderRadius: 2, color: colorConfigs.buttons.inactive }} >Inactive</Typography>}
          </InputAdornment>
        )
      }}
      sx={{
        fontSize: 14,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&:hover fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&.Mui-focused fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '& legend': {
            width: 'auto',
          },
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 16,
          opacity: 0.8,
          fontWeight: 1000,
          top: '-2px',
        },
        shrink: true
      }}
    />
  )
}

interface TextAdornmentNoStatusReadOnlyFieldProps extends FieldProps {
  id: string;
  tier: string;
}
export const TextAdornmentNoStatusReadOnlyField: React.FC<TextAdornmentNoStatusReadOnlyFieldProps & TextFieldProps> = ({
  id,
  tier,
  ...props
}) => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-mrbh"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      autoFocus

      InputProps={{
        readOnly: true,
        disableUnderline: true,
        sx: {
          fontSize: 14,
          '& input': {
            padding: 1,
            paddingX: 2,
          },
        },
        startAdornment: (
          <InputAdornment position="start">
            <Typography data-testid="Typography-d348" sx={{ p: 0, m: 0, pl: 2, minWidth: "2em", fontSize: 14, fontWeight: 'bold' }}>
              {id}
            </Typography>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment data-testid="InputAdornment-7dmz" position="end" style={{}}>
            {tier && <Typography data-testid="Typography-noty" sx={{ fontSize: 14, marginX: 0.5, paddingX: 0.7, }} >{tier}</Typography>}
          </InputAdornment>
        )
      }}
      sx={{
        fontSize: 14,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&:hover fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&.Mui-focused fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '& legend': {
            width: 'auto',
          },
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 16,
          opacity: 0.8,
          fontWeight: 1000,
          top: '-2px',
        },
        shrink: true
      }}
    />
  )
}

interface FeedbackReadOnlyFieldProps extends FieldProps {
  status: boolean;
  id: string;
}
export const FeedbackReadOnlyField: React.FC<FeedbackReadOnlyFieldProps & TextFieldProps> = ({
  status,
  id,
  ...props
}) => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-7c9i"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      autoFocus

      InputProps={{
        readOnly: true,
        disableUnderline: true,
        sx: {
          padding: '2px',
          fontSize: 18,
        },
        startAdornment: (
          <InputAdornment position="start">
            {field.value === 'good' ?
              <SentimentSatisfiedAltIcon sx={{ color: colorConfigs.result.green, p: 0, m: 0, pl: 1, minWidth: "2em", fontWeight: 'bold' }} />
              : field.value === 'bad' ?
                <SentimentVeryDissatisfiedIcon sx={{ color: colorConfigs.result.red, p: 0, m: 0, pl: 1, minWidth: "2em", fontWeight: 'bold' }} />
                : (null)
            }

          </InputAdornment>
        ),

      }}
      sx={{
        fontSize: 18,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&:hover fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&.Mui-focused fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '& legend': {
            width: 'auto',
          },
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 22,
          fontWeight: 1000,
          top: '-3px',
        },
        shrink: true
      }}
    />
  )
}


interface ImageFieldProps extends FieldProps {
  image: string;
}

export const ImageField: React.FC<ImageFieldProps & TextFieldProps> = ({
  image,
  ...props
}) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, ...rest } = props;
  return (
    <TextField data-testid="TextField-bpqq"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      margin="normal"
      autoFocus
      multiline
      rows={1}
      sx={{
        fontSize: 18,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&:hover fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&.Mui-focused fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '& legend': {
            width: 'auto',
          },
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 22,
          fontWeight: 1000,
          top: '-3px',
        },
        shrink: true
      }}

      InputProps={{
        readOnly: true,
        disableUnderline: true,
        sx: {
          padding: 3,
          fontSize: 18,
        },
        startAdornment: (
          <InputAdornment position="start" style={{ marginRight: 'auto', marginLeft: 'auto', height: '100%' }}>
            <img data-testid="img-divt" title="test" src={image} alt="test image" width="180px" height="60px" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export const NotesField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-8ig4"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      autoFocus
      multiline
      // rows={3}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
        sx: {
          fontSize: 14,
          '& input': {
            padding: 1,
            paddingX: 2,
          },
        },
      }}
      sx={{
        fontSize: 14,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&:hover fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&.Mui-focused fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '& legend': {
            width: 'auto',
          },
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 16,
          opacity: 0.8,
          fontWeight: 1000,
          top: '-2px',
        },
        shrink: true
      }}
    />
  )
}

export const TextClassificationReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-h2lu"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      autoFocus

      InputProps={{
        readOnly: true,
        sx: {
          p: "5px"
        },
      }}
      sx={{
      }}
      InputLabelProps={{
        style: {
          fontSize: "18px",
          marginLeft: "-4px"
        },
        shrink: true
      }}
    />
  )
}

export const OrganisationSelectField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const { error, helperText, field, form, type, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);
  const authData = useSelector((state: RootState) => state.auth);
  const options = useRareOrganisations();

  return (
    <TextField data-testid="TextField-qlv6"
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      {...rest}
      {...field}
      fullWidth
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    >
      <MenuItem data-testid="MenuItem-akim" key={0} value="">
        <em>select organisation</em>
      </MenuItem>

      {options.map((option) => {
        return <MenuItem data-testid="MenuItem-7baz" key={option.id} value={option.id}>
          {option.id} - {option.name}
        </MenuItem>
      })}
    </TextField>
  );
};

export const ResolutionSelectField: React.FC<
  FieldProps & TextFieldProps & { resolutionOptions: { label: string; value: number[] }[] }
> = (props) => {
  const { error, helperText, field, form, resolutionOptions, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);

  const handleChange = (event: any) => {
    const selectedValue = JSON.parse(event.target.value); // parse the selected value
    form.setFieldValue(field.name, selectedValue);  // Store the selected [width, height] array directly
  };

  return (
    <TextField data-testid="TextField-0pu7"
      {...rest}
      {...field}
      fullWidth
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      onChange={handleChange}
      value={JSON.stringify(field.value)} // ensure it matches the serialized form
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    // InputLabelProps={{
    //     sx: { fontSize: '0.9rem' },
    // }}
    // sx={{
    //     margin: '16px 0',
    //     '& .MuiInputBase-root': { fontSize: '0.9rem' },
    // }}
    >
      {resolutionOptions.map((option) => (
        <MenuItem data-testid="MenuItem-w2l3" key={option.label} value={JSON.stringify(option.value)}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};






export const SubscriptionSelectField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const { error, helperText, field, form, type, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);
  const authData = useSelector((state: RootState) => state.auth);
  const options = [
    { id: 0, name: "Free" },
    { id: 1, name: "Personal" },
    { id: 2, name: "Pro" },
    { id: 3, name: "Enterprise" },
  ]

  return (
    <TextField data-testid="TextField-c4ra"
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      {...rest}
      {...field}
      fullWidth
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    >
      {options.map((option) => {
        return <MenuItem data-testid="MenuItem-n136" key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      })}
    </TextField>
  );
};



export const OranisationStatusSelectField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const { error, helperText, field, form, type, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);
  const authData = useSelector((state: RootState) => state.auth);
  const options = [
    { name: "ACTIVE", label: "Active", color: colorConfigs.buttons.active },
    { name: "NOT_ACTIVE", label: "Inactive", color: colorConfigs.buttons.inactive },
    { name: "NOT_VALIDATED", label: "Not Validated", color: colorConfigs.buttons.in_progress },
  ]

  return (
    <TextField data-testid="TextField-1n6a"
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      {...rest}
      {...field}
      fullWidth
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    >
      {options.map((option) => (
        <MenuItem data-testid="MenuItem-w9xk"
          key={option.name}
          value={option.name}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '8px 16px',
          }}
        >
          <Typography data-testid="Typography-fhup"
            fontSize="smaller"
            sx={{
              display: 'inline-block',
              maxWidth: 'fit-content',
              paddingX: 0.7,
              border: `1.5px solid ${option.color}`,
              borderRadius: 2,
              color: option.color,
              whiteSpace: 'nowrap',
            }}
          >
            {option.label}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  );
};

export const UserTypeSelectField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const { error, helperText, field, form, type, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);
  const authData = useSelector((state: RootState) => state.auth);
  const options: string[] = authData.isAdmin ? ["ADMIN_USER", "ORGANISATION_ADMIN", "STANDARD_USER"] : ["ORGANISATION_ADMIN", "STANDARD_USER"]


  return (
    <TextField data-testid="TextField-y4ic"
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      {...rest}
      {...field}
      fullWidth
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    >
      <MenuItem data-testid="MenuItem-0u58" key={0} value="">
        <em>select user type</em>
      </MenuItem>

      {options.map((option) => (
        <MenuItem data-testid="MenuItem-csxl" key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const SupportLevelSelectField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const { error, helperText, field, form, type, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);
  const options: any[] = [
    { id: 'BASIC', value: 0, label: "Basic Support", },
    { id: 'LIMITED', value: 1, label: "Limited Support", },
    { id: 'COMPREHENSIVE', value: 2, label: "Comprehensive Support", }
  ]

  return (
    <TextField data-testid="TextField-pc3n"
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      {...rest}
      {...field}
      fullWidth
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    >
      <MenuItem data-testid="MenuItem-n2ki" key={0} value="">
        <em>select support level</em>
      </MenuItem>

      {options.map((option) => (
        <MenuItem data-testid="MenuItem-yqzm" key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};


interface FilterCriteriaSelectFieldProps extends FieldProps {
  options: { name: string, type: string, value: any, label: string }[];
}

export const FilterCriteriaSelectField: React.FC<FilterCriteriaSelectFieldProps & TextFieldProps> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, options, type, ...rest } = props;

  const handleChange = (event: any) => {
    console.log("FilterCriteriaSelectField value:", event.target.value);
    field.onChange(event);
    if (props.onChange) props.onChange(event);
  };

  return (
    <TextField data-testid="TextField-y3oq"
      error={Boolean(isTouched && errorMessage)}
      helperText={(isTouched && errorMessage) ? errorMessage : undefined}
      {...rest}
      {...field}
      fullWidth
      label={props.label}
      onChange={handleChange}
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    >
      <MenuItem data-testid="MenuItem-3sqg" key={0} value="">
        <em>&nbsp;</em>
      </MenuItem>

      {options.map((option, index) => (
        <MenuItem data-testid="MenuItem-rhb6" key={option.name} value={option.name}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const TextEditField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-b6x0"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2
          }
        },
      }}

      sx={{
        m: 0,
        fontSize: '16px',
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
        },
        shrink: true
      }}
    />
  )
}

export const TextEditReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-91h1"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"

      InputProps={{
        readOnly: true,
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2
          }
        },
      }}

      sx={{
        m: 0,
        fontSize: '16px',
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
        },
        shrink: true
      }}
    />
  )
}

interface SelectEditFieldProps extends FieldProps {
  options: { value: string | number, label: string }[];
}

export const SelectEditField: React.FC<SelectEditFieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, options, type, ...rest } = props

  return (
    <TextField data-testid="TextField-ee60"
      error={Boolean(isTouched && errorMessage)}
      helperText={(isTouched && errorMessage) ? errorMessage : undefined}
      {...rest}
      {...field}
      fullWidth
      select
      InputLabelProps={{
        sx: {
          // fontSize: '0.9rem',
        },
      }}
      sx={{
        //   margin: '7px 0',
        '& .MuiInputBase-root': {
          // fontSize: '0.9rem',
        },
      }}
    >
      <MenuItem data-testid="MenuItem-thrr" key={0} value={""}>
        <em>&nbsp;</em>
      </MenuItem>

      {options.map((option, index) => (
        <MenuItem data-testid="MenuItem-2orf" key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}

    </TextField>
  )
}

//   export const FormDatePicker: React.FC<FieldProps & TextFieldProps> = props => {
//     const isTouched = getIn(props.form.touched, props.field.name)
//     const errorMessage = getIn(props.form.errors, props.field.name)

//     const { error, helperText, field, form, ...rest } = props

//       return (

//           <LocalizationProvider dateAdapter={AdapterDayjs}>
//           <DemoContainer components={['DatePicker']}>
//           <DatePicker data-testid="DatePicker-t5ml"
//               value={dayjs(field.value)}
//               sx={{
//                 margin: '20px',
//                 '& .MuiInputBase-root': {
//                   fontSize: '0.9rem',
//                 }}}
//               onChange={(newValue) => {
//                   if (newValue) {
//                     form.setFieldValue(field.name, newValue ? dayjs(newValue).toISOString() : "");
//                   }
//               }}
//           />
//           </DemoContainer>
//           </LocalizationProvider>
//       )
//     }


export const FormDatePicker: React.FC<FieldProps & TextFieldProps> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);
  const { error, helperText, field, form, ...rest } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DatePicker data-testid="DatePicker-vz3g"
        label={rest.label}
        value={field.value ? dayjs(field.value) : null}
        onChange={(newValue) => {
          if (newValue && dayjs(newValue).isValid()) {
            form.setFieldValue(field.name, dayjs(newValue).toISOString());
          } else {
            form.setFieldValue(field.name, "");
          }
        }}
        // Use the TextField as the default slot
        slots={{ textField: TextField }}
        slotProps={{
          textField: (params) => ({
            ...params,
            error: error ?? Boolean(isTouched && errorMessage),
            helperText:
              helperText ??
              (isTouched && errorMessage ? errorMessage : undefined),
            fullWidth: true,
            variant: "outlined",
            margin: "normal",
            autoFocus: true,
            sx: {
              m: 0,
              fontSize: "16px",
            },
            InputLabelProps: {
              style: { fontSize: "16px", color: colorConfigs.text },
              shrink: true,
            },
            // Merge the default InputProps with our custom clear icon
            InputProps: {
              ...params.InputProps,
              sx: {
                fontSize: "16px",
                "& input": {
                  padding: 1,
                  paddingX: 2,
                },
                ...params.InputProps?.sx,
              },
              endAdornment: (
                <>
                  {/* Preserve the default calendar icon */}
                  {params.InputProps?.endAdornment}
                  {/* Show clear icon only if there is a value */}
                  {field.value ? (
                    <InputAdornment data-testid="InputAdornment-cimq" position="end">
                      <IconButton data-testid="IconButton-qx69"
                        onClick={() => form.setFieldValue(field.name, "")}
                        sx={{ padding: 0 }}
                      >
                        <CloseIcon
                          fontSize="small"
                          sx={{
                            fontSize: "12px",
                            "&:hover": { color: "red" },
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ) : null}
                </>
              ),
            },
          }),
        }}
      />
    </LocalizationProvider>
  );
};

export const ResultDetailEditField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-af2p"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      autoFocus

      InputProps={{
        readOnly: true,
        disableUnderline: true,
        sx: {
          m: 0,
          fontSize: 14,
          '& input': {
            padding: 1,
            paddingX: 2,
            m: 0
          },
        },
      }}
      sx={{
        fontSize: 14,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&:hover fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '&.Mui-focused fieldset': {
            borderColor: "rgba(0, 0, 0, 0.1)",
          },
          '& legend': {
            width: 'auto',
          },
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 16,
          opacity: 0.8,
          fontWeight: 1000,
          top: '-2px',
        },
        shrink: true
      }}
    />
  )
}

export const ReadOnlySwitch: React.FC<FieldProps & { label: string, colorConfigs: any }> = ({ field, label, colorConfigs }) => {
  const isChecked = Boolean(field.value);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isChecked}
          readOnly
          sx={{
            // marginLeft: 1,
            '& .MuiSwitch-switchBase': {
              color: isChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
            },
            '& .MuiSwitch-track': {
              backgroundColor: isChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
            },
          }}
        />
      }
      label={label}
      sx={{
        '.MuiFormControlLabel-label': {
          fontSize: '14px',
        },
      }}
    />
  );
};

export const ReadOnlyCheckbox: React.FC<FieldProps & { label: string, colorConfigs: any }> = ({ field, label, colorConfigs }) => {
  const isChecked = Boolean(field.value);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          readOnly
          sx={{
            color: isChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
            '&.Mui-checked': {
              color: isChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
            },
          }}
        />
      }
      label={label}
      sx={{
        '.MuiFormControlLabel-label': {
          fontSize: '14px',
        },
      }}
    />
  );
};


export const SmallTextEditField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-bazw"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      autoFocus
      sx={{
        my: 0,
      }}
      InputProps={{
        sx: {
          '& input': {
            padding: 1,
            paddingX: 2,
          },
        }
      }}
    />
  )
}

export const SmallEditReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField data-testid="TextField-prpr"
      //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      autoFocus

      InputProps={{
        readOnly: true,
        sx: {
          '& input': {
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      sx={{
        my: 0,
      }}
      InputLabelProps={{
        style: {
        },
        shrink: true
      }}
    />
  )
}

export const HousingResolutionSelectField: React.FC<
  FieldProps & TextFieldProps & { resolutionOptions: { label: string; value: number[] }[] }
> = (props) => {
  const { error, helperText, field, form, resolutionOptions, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);

  const handleChange = (event: any) => {
    const selectedValue = JSON.parse(event.target.value); // parse the selected value
    form.setFieldValue(field.name, selectedValue);  // Store the selected [width, height] array directly
  };

  return (
    <TextField data-testid="TextField-yzvp"
      {...rest}
      {...field}
      fullWidth
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      onChange={handleChange}
      value={JSON.stringify(field.value)} // ensure it matches the serialized form
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    >
      {resolutionOptions.map((option) => (
        <MenuItem data-testid="MenuItem-9ckw" key={option.label} value={JSON.stringify(option.value)}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};


export const HousingResolutionReadOnlySelectField: React.FC<
  FieldProps & TextFieldProps & { resolutionOptions: { label: string; value: number[] }[] }
> = (props) => {
  const { error, helperText, field, form, resolutionOptions, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);

  const handleChange = (event: any) => {
    const selectedValue = JSON.parse(event.target.value);
    form.setFieldValue(field.name, selectedValue);
  };

  return (
    <TextField data-testid="TextField-v25t"
      {...rest}
      {...field}
      fullWidth
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      onChange={handleChange}
      value={JSON.stringify(field.value)}
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    >
      {resolutionOptions.map((option) => (
        <MenuItem data-testid="MenuItem-oohw" key={option.label} value={JSON.stringify(option.value)}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const OrganisationUsersReadOnlyField: React.FC<FieldProps & TextFieldProps & { canCreateNewUsers: boolean; activeUsersCount: number; permittedUsersCount: number }> = props => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);
  const {
    error,
    helperText,
    field,
    form,
    activeUsersCount,
    permittedUsersCount,
    ...rest
  } = props;

  const canCreateNewUsers = form.values.can_create_new_users;

  return (
    <TextField data-testid="TextField-p74m"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      sx={{
        m: 0,
        fontSize: '16px',
      }}
      InputLabelProps={{
        shrink: true,
        style: {
          fontSize: '16px',
        },
      }}
      InputProps={{
        readOnly: true,
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2
          },
        },
        endAdornment: !canCreateNewUsers ? (
          <Typography
            fontSize="smaller"
            sx={{
              paddingX: 0.7,
              border: `1.5px solid ${colorConfigs.buttons.in_progress}`,
              borderRadius: 2,
              color: colorConfigs.buttons.in_progress,
            }}
          >
            Max
          </Typography>
        ) : (
          <Tooltip data-testid="Tooltip-u9xa" title={"Create New User"}>
            <IconButton data-testid="IconButton-g0hh"
              href="/rare-admin/users/create"
              sx={{
                backgroundColor: 'transparent',
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <GroupAddOutlinedIcon />
            </IconButton>
          </Tooltip>
        ),
      }
      }
      value={`${activeUsersCount} / ${permittedUsersCount}`} // Render the count values
    />
  );
};

export const OrganisationAdminUsersReadOnlyField: React.FC<FieldProps & TextFieldProps & { canCreateNewUsers: boolean; activeUsersCount: number; permittedUsersCount: number }> = props => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);
  const {
    error,
    helperText,
    field,
    form,
    activeUsersCount,
    permittedUsersCount,
    ...rest
  } = props;

  const canCreateNewUsers = form.values.can_create_new_users;

  return (
    <TextField data-testid="TextField-8s66"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      variant="outlined"
      margin="normal"
      sx={{
        m: 0,
        fontSize: '16px',
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
        },
        shrink: true
      }}
      InputProps={{
        readOnly: true,
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2
          }
        },
        endAdornment: !canCreateNewUsers ? (
          <Typography
            fontSize="smaller"
            sx={{
              paddingX: 0.7,
              border: `1.5px solid ${colorConfigs.buttons.in_progress}`,
              borderRadius: 2,
              color: colorConfigs.buttons.in_progress,
            }}
          >
            Max
          </Typography>
        ) : (
          <Tooltip data-testid="Tooltip-p870" title={"Create New User"}>

            <IconButton data-testid="IconButton-ctml"
              href="/account/users/create"
              sx={{
                backgroundColor: 'transparent',
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <GroupAddOutlinedIcon />
            </IconButton>
          </Tooltip>
        ),
      }}
      value={`${activeUsersCount} / ${permittedUsersCount}`} // Render the count values
    />
  );
};

export const StatusSelectField: React.FC<
  FieldProps & TextFieldProps & { statusOptions: { label: string; value: string }[] }
> = (props) => {
  const { field, form, statusOptions, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value; // Get the selected value directly
    form.setFieldValue(field.name, selectedValue); // Update the form value directly
  };

  return (
    <TextField data-testid="TextField-vgkz"
      {...rest}
      {...field}
      fullWidth
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      onChange={handleChange}
      value={field.value} // Use the value directly
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    >
      {statusOptions.map((option) => (
        <MenuItem data-testid="MenuItem-6isj" key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};



export const StatusReadOnlySelectField: React.FC<
  FieldProps & TextFieldProps & { statusOptions: { label: string; value: string }[] }
> = (props) => {
  const { error, helperText, field, form, statusOptions, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);

  const handleChange = (event: any) => {
    const selectedValue = event.target.value; // Get the selected value directly
    form.setFieldValue(field.name, selectedValue); // Update the form value directly
  };

  return (
    <TextField data-testid="TextField-9mss"
      {...rest}
      {...field}
      fullWidth
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      onChange={handleChange}
      value={field.value} // Use the value directly
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    >
      {statusOptions.map((option) => (
        <MenuItem data-testid="MenuItem-70xi" key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const DatasetSelectField: React.FC<
  FieldProps & TextFieldProps & { options: { label: string; value: string, description: string }[] }
> = (props) => {
  const { field, form, options, ...rest } = props;
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value; // Get the selected value directly
    form.setFieldValue(field.name, selectedValue); // Update the form value directly
  };

  return (
    <TextField data-testid="TextField-cbe1"
      {...rest}
      {...field}
      fullWidth
      error={Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : undefined}
      onChange={handleChange}
      value={field.value} // Use the value directly
      select
      sx={{
        m: 0,
        fontSize: '16px',
        '& Mui.select': {
          padding: 1,
          paddingX: 2,
          p: 0,
        },
      }}
      InputProps={{
        sx: {
          fontSize: '16px',
          '& input': {
            padding: 1,
            paddingX: 2,
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: 1,
            paddingX: 2,
          },
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          color: colorConfigs.text,
        },
        shrink: true
      }}
    >
      {options.map((option: any) => (
        <MenuItem data-testid="MenuItem-vfzb" key={option.value} value={option.value}>
          {option.label} {option.description}
        </MenuItem>
      ))}
    </TextField>
  );
};

