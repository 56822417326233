import React, { useRef, useState } from "react";
import {
  CircularProgress,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import Popup from "./Popup";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { ImageItem } from "../../../types/types";
import RareButton from "../buttons/RareButton";

interface ImageSelectorCardProps {
  images: any[];
  setSelectedImage: (selectedImage: ImageItem | undefined) => void;
  selectedImage?: ImageItem;
  onDoubleClick?: () => void;
}

const ImageSelectorCard: React.FC<ImageSelectorCardProps> = ({
  images,
  selectedImage,
  setSelectedImage,
  onDoubleClick,
}) => {
  // State to control the expanded image popup
  const [expandedImage, setExpandedImage] = useState<ImageItem | null>(null);

  const handleImageSelect = (image: ImageItem) => {
    setSelectedImage(image);
  };

  const handleImageDoubleClick = (image: ImageItem) => {
    setSelectedImage(image);
    if (onDoubleClick) onDoubleClick();
  };

  return (
    <>
      <Box data-testid="Box-n8q5">
        <Box
          data-testid="Box-633a"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(200px, 1fr))",
            gap: 2,
            p: 1,
          }}
        >
          {images.map((item, index) => {
            const isSelected =
              selectedImage?.image_url === item.image_url;
            return (
              <Box
                data-testid={`Box-svb4-${index}`}
                key={item.image_url}
                sx={{
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <img
                  data-testid={`Image-${index}-${item.image_url}`}
                  src={item.image_url}
                  alt={item.image_url}
                  width="100%"
                  style={{
                    cursor: "pointer",
                    border: isSelected
                      ? "5px solid blue"
                      : "5px solid transparent",
                  }}
                  onClick={() => handleImageSelect(item)}
                  onDoubleClick={() => handleImageDoubleClick(item)}
                />
                {/* Only show the expand icon if the image is selected */}
                {isSelected && (
                  <IconButton
                    // className="expandButton"
                    sx={{
                      position: "absolute",
                      top: 3,
                      right: 3,
                      display: "block",
                      p: 0.3,
                      pb: 0,
                      backgroundColor: "rgba(255,255,255,0.8)",
                      "&:hover": { backgroundColor: "rgba(255,255,255,0.9)" },
                    }}
                    onClick={() => setExpandedImage(item)}
                  >
                    <OpenInFullIcon sx={{ p: 0.2, pb: 0, m: 0 }} />
                  </IconButton>
                )}
              </Box>
            );
          })}
        </Box>
        {!images?.length && (
          <div
            data-testid="div-ww8r"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <CircularProgress data-testid="CircularProgress-a9sf" />
          </div>
        )}
      </Box>

      {/* Popup for expanded full-size image */}
      {expandedImage && (
        <Popup
          isOpen={true}
          closeDialog={() => setExpandedImage(null)}
          width="md"
          title=""
          actions={
            <RareButton
              data-testid="RareButton-cancel-expand"
              onClick={() => setExpandedImage(null)}
              variant="outlined"
              sx={{ margin: "10px" }}
            >
              Back
            </RareButton>
          }
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              src={expandedImage.image_url}
              alt={expandedImage.image_url}
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
            />
          </Box>
        </Popup>
      )}
    </>
  );
};

export default ImageSelectorCard;
