import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, Typography, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled, Theme, Switch, Tooltip, IconButton, Divider, useMediaQuery } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { ResultSessionStorageKey, ResultSessionStorageModel, ResultTableParameterStorageService } from "../../../services/sessionStorage";
import colorConfigs from "../../../configs/colorConfigs";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { tsXLXS } from 'ts-xlsx-export';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { MRT_FilterFns } from 'material-react-table';
import RareButton from "../buttons/RareButton";
import api from "../../../redux/features/auth/axiosAuthHelper";

const StyledMenu = styled((props: any) => (
  <Menu data-testid="Menu-gy06"
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


interface ResultsTableProps {
  authData: any;
  rows: any[];
  isLoading: boolean;
  sorting: any;
  columnVisibility: any;
  columnFilters: any;
  pagination: any;
  globalFilter: any;
  showGlobalFilter: any;
  setShowGlobalFilter: (value: any) => void;
  handleRemoveAllFilters: () => void;
  setGlobalFilter: (value: string) => void;
  setSorting: (sorting: any[]) => void;
  setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
  setColumnFilters: (filters: any[]) => void;
  setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
  setResult: (detail: any) => void;
  setPopup: (detail: any) => void;
  handleRefreshButton: () => void;
  handleAddSelectedResults: (rowsToAdd: any[]) => void;
  handleRemoveSelectedResults: (rowsToRemove: any[]) => void;
  handleShowAllSelectedResults: () => void;
  selectedResults: any;
  setRows: (detail: any) => void;
  setUniqueHousingError: (error: any) => void;
  handleClearAllSelectedResults: () => void
  create: boolean;
}

const ResultsTable: React.FC<ResultsTableProps> = ({
  authData,
  rows,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setResult,
  setPopup,
  handleRefreshButton,
  handleAddSelectedResults,
  handleRemoveSelectedResults,
  handleShowAllSelectedResults,
  selectedResults,
  setRows,
  setUniqueHousingError,
  handleClearAllSelectedResults,
  create
}) => {

  console.log("Results", rows)
  const isMobile = useMediaQuery('(max-width:768px)');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const customResultTypeFilter = (row: any, columnId: any, filterValue: string) => {
    const { datarun_id, test_configuration } = row.original;
    const isDCMAppResult = !datarun_id && test_configuration?.collection_mode;
    const isNormalAppResult = !datarun_id && !test_configuration?.collection_mode;
    const isDatarunResult = !!datarun_id;

    const datarunText = `Datarun: ${datarun_id}`;
    const searchValue = filterValue.toLowerCase();

    return (
      (isDCMAppResult && "data collection mode result".includes(searchValue)) ||
      (isNormalAppResult && "normal app result".includes(searchValue)) ||
      (isDatarunResult && "reprocessed result".includes(searchValue)) ||
      (isDatarunResult && datarunText.toLowerCase().includes(searchValue))
    );
  };

  const filterFns = {
    ...MRT_FilterFns,
    customResultTypeFilter,
  };


  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      Header: () => <div data-testid="div-m13z" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Result<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'sample_id',
      header: 'Sample ID',
      Header: () => <div data-testid="div-l63s" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Sample<br />ID
      </div>,
    },
    {
      accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      header: 'Timestamp',
      Header: () => <div data-testid="div-0ii2" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Timestamp<br />&nbsp;
      </div>,
      Cell: ({ cell }) => (
        <div data-testid="div-0sg1" style={{ whiteSpace: 'pre-wrap' }}>
          {cell.getValue()?.toString().split(' ').join('\n')}
        </div>
      ),
    },
    // {
    //   accessorKey: 'test_configuration.collection_mode',
    //   header: 'Data Collector Mode',
    //   Header: () => (
    //     <div data-testid="div-sq0q" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
    //       Data Collector<br />Mode
    //     </div>
    //   ),
    //   Cell: ({ cell }) => (
    //     <div data-testid="div-amie" style={{ display: "flex", justifyContent: "center" }}>
    //       <Switch data-testid="Switch-77iz"
    //         checked={cell.getValue()?.toString() === "true"}
    //         color="primary"
    //         inputProps={{ 'aria-label': 'Data Collector Mode' }}
    //       />
    //     </div>
    //   ),
    //   Filter: ({ column }) => (
    //     <div data-testid="div-4yfo" style={{ display: "flex", justifyContent: "center" }}>
    //       <Switch data-testid="Switch-cnv3"
    //         checked={column.getFilterValue() === "true"}
    //         onChange={(e) => column.setFilterValue(e.target.checked ? "true" : "false")}
    //         color="primary"
    //         inputProps={{ 'aria-label': 'Filter Data Collector Mode' }}
    //       />
    //     </div>
    //   ),
    //   filterFn: 'equals', // or you can create a custom filter function if needed
    // },
    {
      accessorKey: 'datarun_id',
      header: 'Source',
      Header: () => (
        <div data-testid="div-9je6" style={{ textAlign: 'center', height: '100%', minHeight: '50px' }}>
          Source<br />&nbsp;
        </div>
      ),
      Cell: ({ cell, row }) => {
        const navigate = useNavigate();
        const { datarun_id, test_configuration } = row.original;

        const isDCMAppResult = !datarun_id && test_configuration?.collection_mode;
        const isNormalAppResult = !datarun_id && !test_configuration?.collection_mode;
        const isDatarunResult = !!datarun_id;

        return (
          <div data-testid="div-bq4u" style={{}}>
            {isDCMAppResult ? (
              <Typography data-testid="Typography-0xe0">Data Collection Mode Result</Typography>
            ) : isNormalAppResult ? (
              <Typography data-testid="Typography-rjkb">Normal App Result</Typography>
            ) : isDatarunResult ? (
              <div data-testid="div-514g">
                <Typography data-testid="Typography-94p2">Reprocessed Result</Typography>
                <Link data-testid="Link-8w0o"
                  onClick={() => navigate(`/data_analysis/data_run_management/${datarun_id}`)}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  Datarun: {datarun_id}
                </Link>
              </div>
            ) : null}
          </div>
        );
      },
      enableColumnFilter: true,
      filterFn: 'customResultTypeFilter',
    },
    {
      accessorKey: 'method_id',
      header: "Method",
      Header: () => <div data-testid="div-r7xg" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Method<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'housing_id',
      header: "Housing",
      Header: () => <div data-testid="div-f0ml" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Housing<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'organisation_id',
      header: 'Organisation ID',
      Header: () => <div data-testid="div-6qcd" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Organisation<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'user_id',
      header: 'User ID',
      Header: () => <div data-testid="div-z8ho" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        User<br />ID
      </div>,
      filterFn: (row, id, filterValue) => {
        const idValue = row.getValue(id);
        // Ensure both values are numbers before comparison
        return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
      },
    },
    {
      accessorKey: 'selected',
      header: 'selected',
      Header: () => <div data-testid="div-vhqa" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Selected<br />&nbsp;
      </div>,
      Cell: ({ cell }) => {
        const isSelected = cell.getValue() || false
        return isSelected ? (
          <Box data-testid="Box-cqtz" onClick={(e) => e.stopPropagation()} sx={{ gap: '0.5rem', display: 'flex', justifyContent: 'center', cursor: 'default' }}>
            <Tooltip data-testid="Tooltip-3xzn" arrow placement="left" title="Result is in this Dataset">
              <IconButton data-testid="IconButton-rq5r"
                aria-label="result in dataset"
                disabled
                sx={{ cursor: 'default' }}
              >
                <CheckCircleOutlineOutlinedIcon sx={{ color: '#fff', cursor: 'default' }} />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null
      },
      filterVariant: 'checkbox',
    },
  ]


  // Define the type for the keys for handling persistance of table settings in session storage
  type ResultSessionStorageKeyType = keyof ResultSessionStorageModel;

  // Generic handler function for persisting table parameters
  const handleParameterChange = <T,>(
    key: ResultSessionStorageKeyType,
    currentValue: T,
    setValueFunction: (value: T) => void,
    newValue: T | ((prevValue: T) => T)
  ) => {
    const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
    ResultTableParameterStorageService.set(key, updatedValue);
    setValueFunction(updatedValue);
  };

  // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
  const setSortingFunction = (newSortingValue: any) =>
    handleParameterChange(ResultSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
  const setPaginationChangeFunction = (newPaginationValue: any) =>
    handleParameterChange(ResultSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
  const setFilterChangeFunction = (newFilterChangeValue: any) =>
    handleParameterChange(ResultSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
  const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
    handleParameterChange(ResultSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
  const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
    handleParameterChange(ResultSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);



  return <MaterialReactTable
    columns={columns}
    data={rows}
    filterFns={filterFns}
    state={{
      isLoading,
      sorting,
      columnFilters,
      pagination,
      globalFilter,
      showGlobalFilter
    }}
    onSortingChange={setSortingFunction}
    onColumnFiltersChange={setFilterChangeFunction}
    onPaginationChange={setPaginationChangeFunction}
    onGlobalFilterChange={setGlobalFilterChangeFunction}
    onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
    defaultColumn={{
      minSize: 10,
      maxSize: 160,
      size: 10,
    }}
    positionActionsColumn="last"
    enableRowSelection
    selectAllMode="all"
    enableRowActions={create}
    initialState={{
      sorting,
      columnVisibility,
      columnFilters,
      pagination,
      globalFilter,
      density: 'compact',
      columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions'] },
    }}

    // TABLE STYLING IS IN THE THEME
    displayColumnDefOptions={{
      'mrt-row-actions': {
        header: "Add / Remove Data", // Renames Actions Column
        Header: () => (
          <div data-testid="div-sign" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Add or<br />Remove
          </div>
        ),
      },
    }}

    muiTopToolbarProps={({ table }) => ({
      sx: (theme: any) => ({
        ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
      }),
    })}

    muiTableHeadProps={{
      sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
    }}

    muiTableHeadCellProps={({ column }) => ({
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
        ...(column.id === 'mrt-row-select' && {
          maxWidth: '50px',
          width: '50px',
        }),
        ...(column.id === 'mrt-row-actions' && {
          // maxWidth: '50px',
          // width: '50px',
          // background: colorConfigs.buttons.bgActive,
          // borderColor: colorConfigs.buttons.active,
        }),
      }),
    })}

    muiFilterTextFieldProps={{
      sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
    }}

    muiTableBodyRowProps={({ row }) => ({
      onClick: (event) => {
        console.log(row.original);
        setResult(row.original);
        setPopup('Edit');
      },
      sx: (theme: any) => ({
        ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
        backgroundColor: row.original.selected
          ? colorConfigs.buttons.active // Highlight if selected
          : row.index % 2 === 0
            ? colorConfigs.tables.firstRow // Alternate row colors
            : colorConfigs.tables.secondRow,

      }),
    })}


    muiTableBodyProps={{
      sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
    }}

    muiPaginationProps={{
      rowsPerPageOptions: [250, 500, 1000],
      sx: {
        padding: 0,
        margin: 0,
        '& .MuiTablePagination-root': {
          padding: 0,
          margin: 0,
        },
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          minHeight: '0px',
        },
        '& .MuiTablePagination-selectLabel': {
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          margin: 0,
        },
      },
    }}

    muiTableBodyCellProps={({ row }) => ({
      // TABLE BODY CELLS
      sx: {
        // color: row.original.selected ? '#fff': 'inherit',
        // fontWeight: row.original.selected ? 'bold': 'selected',
      },
    })}

    muiBottomToolbarProps={{
      // BOTTOM TOOLBAR (Pagination)
      sx: {
        height: '41px',
        maxHeight: '41px',
        minHeight: '41px',
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: '#fff',
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
        margin: 0,
        padding: 0,
        '& .MuiToolbar-root': {
          padding: 0,
          margin: 0,
          paddingBottom: 0,
          paddingTop: 0,
        },
        '& .MuiBox-root': {
          p: 0,
        },
        '& .MuiIconButton-root': {
          color: colorConfigs.tables.headBg,
        },
        '& .MuiTablePagination-root': {
          margin: 0,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          color: colorConfigs.buttons.bg,
        },
        '& .MuiTablePagination-selectLabel': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          color: colorConfigs.buttons.bg,
          padding: 0,
          margin: 0,
        },
        '& .MuiTablePagination-actions': {
          color: colorConfigs.tables.headBg,
          padding: 0,
          margin: 0,
        },
      },
    }}


    renderTopToolbarCustomActions={({ table }) => {

      const handleArchiveResults = () => {
        table.getSelectedRowModel().flatRows.map(row => {
          //Add archive logic
        });
      };



      const handleBulkAddToDataset = async (rowsToAdd: MRT_Row<any>[]) => {

        const allHousingIds = [
          ...rowsToAdd.map((row) => row.original.housing_id),
          ...selectedResults.map((row: any) => row.housing_id),
        ];

        if (new Set(allHousingIds).size > 1) {
          // Set an error if housing IDs are not the same
          setUniqueHousingError("All Results in a dataset must share a common housing");
          setTimeout(() => setUniqueHousingError(""), 3000);
          return; // Exit early without updating results
        }

        const allCollectionModes = [
          ...rowsToAdd.map((row) => row.original.test_configuration.collection_mode),
          ...selectedResults.map((row: any) => row.test_configuration.collection_mode),
        ];

        if (new Set(allCollectionModes).size > 1) {
          // Set an error if housing IDs are not the same
          setUniqueHousingError("All Results in a dataset must share a common source, Normal App result or Data Collection Mode");
          setTimeout(() => setUniqueHousingError(""), 3000);
          return; // Exit early without updating results
        }


        const rowsToAddOriginals = rowsToAdd.map((row) => row.original); // Extract original row data
        handleAddSelectedResults(rowsToAddOriginals); // Add to selected results

        // Update the rows state to set selected = true
        setRows((prevRows: any[]) =>
          prevRows.map((row) =>
            rowsToAddOriginals.some((toAdd) => toAdd.id === row.id)
              ? { ...row, selected: true }
              : row
          )
        );
      };

      const handleBulkRemoveFromDataset = async (rowsToRemove: MRT_Row<any>[]) => {
        const rowsToRemoveOriginals = rowsToRemove.map((row) => row.original); // Extract original row data
        handleRemoveSelectedResults(rowsToRemoveOriginals); // Remove from selected results

        // Update the rows state to set selected = false
        setRows((prevRows: any[]) =>
          prevRows.map((row) =>
            rowsToRemoveOriginals.some((toRemove) => toRemove.id === row.id)
              ? { ...row, selected: false }
              : row
          )
        );
      };


      const handleExportRows = async (rows: MRT_Row<any>[]) => {
        const resultIds = rows.map((row) => row.original.id);
        const payload = {
          result_ids: resultIds,
        };
        const data: any[] = [];

        try {
          const url = `results/batch`;
          const initialResponse = await api.post(url, payload, {
            headers: {
              Authorization: `Bearer ${authData.access}`,
            },
          });

          if (initialResponse.data.results.length < 1) {
            console.log("No results for this query");
            return;
          } else {
            data.push(...initialResponse.data.results);
          }

          console.log("Downloaded all results for export:", data);

          // Prepare and download Excel
          const excelRows: any[] = [];
          const now = dayjs().format("YYYY-MM-DD HH:mm:ss");
          const filename = `${now}_ResultsList`;

          for (let i = 0; i < data.length; i++) {
            const result = data[i];
            const formattedTimestamp = dayjs(result.timestamp).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            const isDCMAppResult =
              !result.datarun_id && result.test_configuration?.collection_mode;
            const isNormalAppResult =
              !result.datarun_id && !result.test_configuration?.collection_mode;
            const isDatarunResult = !!result.datarun_id;

            // Add the main result row
            const baseRow = {
              ID: result.id,
              "Sample ID": result.sample_id || "",
              Timestamp: formattedTimestamp,
              Longitude: result.longitude || "",
              Latitude: result.latitude || "",
              Notes: result.notes || "",
              "Method ID": result.method_id,
              "Housing ID": result.housing_id,
              "Organisation ID": result.organisation_id,
              "User ID": result.user_id,
              Type: result.type,
              Source: isDCMAppResult
                ? "Data Collection Mode Result"
                : isNormalAppResult
                  ? "Normal App Result"
                  : isDatarunResult
                    ? `Reprocessed Result`
                    : "",
              "REPROCESSED RESULT INFO": "",
              "Datarun ID": result.datarun_id || "",
              "Images Used": result.source_result?.images[0] || "[]",
              "Images Rejected": result.source_result?.rejected_images[0] || "[]",
              "TEST CONFIGURATION INFO": "",
              "Flash Required": result.test_configuration?.flash_required ? "TRUE" : "FALSE",
              "Reader Resolution": result.test_configuration?.reader_resolution
                ? `${result.test_configuration.reader_resolution[0]}x${result.test_configuration.reader_resolution[1]}`
                : "",
              "Ground Truth": 'Not setup yet',
              "DEVICE INFO": "",
              "Device Model": result.device_model,
              "Device UUID": result.device_uuid,
              "OS Version": result.os_version,
              "App Version": result.app_version,
              "App Build": result.app_build,
              "TEST STRIPS INFO": result.test_strips?.[0] ? `Test Strip 1` : "",
              "C Line Score": result.test_strips?.[0]?.c_line?.score || "",
              "C Line Area": result.test_strips?.[0]?.c_line?.area || "",
              "C Line Position": result.test_strips?.[0]?.c_line?.peak_position || "",
              Baseline: result.test_strips?.[0]?.baseline
                ? `[${result.test_strips[0].baseline.join(", ")}]`
                : "",
              "Mean Profile": result.test_strips?.[0]?.mean_profile
                ? `[${result.test_strips[0].mean_profile.join(", ")}]`
                : "",
              "TEST LINES INFO": result.test_strips?.[0]?.t_lines?.[0]
                ? `T Line 1`
                : "",
              Score: result.test_strips?.[0]?.t_lines?.[0]?.score || "",
              Area: result.test_strips?.[0]?.t_lines?.[0]?.area || "",
              Position: result.test_strips?.[0]?.t_lines?.[0]?.peak_position || "",
              "TC Ratio": result.test_strips?.[0]?.t_lines?.[0]?.tc_ratio || "",
            };

            excelRows.push(baseRow);

            // Add additional test lines for the first strip
            result.test_strips?.[0]?.t_lines?.slice(1).forEach((tLine: any, tLineIndex: number) => {
              const tLineRow = {
                ID: "",
                "Sample ID": "",
                Timestamp: "",
                Longitude: "",
                Latitude: "",
                Notes: "",
                "Method ID": "",
                "Housing ID": "",
                "Organisation ID": "",
                "User ID": "",
                Type: "",
                Source: "",
                "REPROCESSED RESULT INFO": "",
                "Datarun ID": "",
                "Images Used": "",
                "Images Rejected": "",
                "TEST CONFIGURATION INFO": "",
                "Flash Required": "",
                "Reader Resolution": "",
                "Ground Truth": "",
                "DEVICE INFO": "",
                "Device Model": "",
                "Device UUID": "",
                "OS Version": "",
                "App Version": "",
                "App Build": "",
                "TEST STRIPS INFO": "",
                "C Line Score": "",
                "C Line Area": "",
                "C Line Position": "",
                Baseline: "",
                "Mean Profile": "",
                "TEST LINES INFO": `T Line ${tLineIndex + 2}`,
                Score: tLine.score,
                Area: tLine.area,
                Position: tLine.peak_position,
                "TC Ratio": tLine.tc_ratio,
              };

              excelRows.push(tLineRow);
            });

            // Add subsequent test strips
            result.test_strips?.slice(1).forEach((strip: any, stripIndex: number) => {
              let isFirstRowForStrip = true;
              strip.t_lines?.forEach((tLine: any, tLineIndex: number) => {
                const tLineRow = {
                  ID: "",
                  "Sample ID": "",
                  Timestamp: "",
                  Longitude: "",
                  Latitude: "",
                  Notes: "",
                  "Method ID": "",
                  "Housing ID": "",
                  "Organisation ID": "",
                  "User ID": "",
                  Type: "",
                  Source: "",
                  "REPROCESSED RESULT INFO": "",
                  "Datarun ID": "",
                  "Images Used": "",
                  "Images Rejected": "",
                  "TEST CONFIGURATION INFO": "",
                  "Flash Required": "",
                  "Reader Resolution": "",
                  "Ground Truth": "",
                  "DEVICE INFO": "",
                  "Device Model": "",
                  "Device UUID": "",
                  "OS Version": "",
                  "App Version": "",
                  "App Build": "",
                  "TEST STRIPS INFO": isFirstRowForStrip
                    ? `Test Strip ${stripIndex + 2}`
                    : "",
                  "C Line Score": isFirstRowForStrip ? strip.c_line.score : "",
                  "C Line Area": isFirstRowForStrip ? strip.c_line.area : "",
                  "C Line Position": isFirstRowForStrip
                    ? strip.c_line.peak_position
                    : "",
                  Baseline: isFirstRowForStrip ? strip.baseline.join(", ") : "",
                  "Mean Profile": isFirstRowForStrip
                    ? strip.mean_profile.join(", ")
                    : "",
                  "TEST LINES INFO": `T Line ${tLineIndex + 1}`,
                  Score: tLine.score,
                  Area: tLine.area,
                  Position: tLine.peak_position,
                  "TC Ratio": tLine.tc_ratio,
                };

                excelRows.push(tLineRow);

                // Mark that the first row for this strip has been added
                isFirstRowForStrip = false;
              });
            });
          }

          tsXLXS().exportAsExcelFile(excelRows).saveAsExcelFile(filename);
        } catch (error) {
          console.error("Error exporting rows:", error);
          alert("Failed to export results. Please try again.");
        }
      };


      return (
        <Box data-testid="Box-s9b7" sx={{ display: 'flex', gap: '0.3rem', height: "40px", alignItems: 'flex-end' }}>
          <Tooltip data-testid="Tooltip-usl8" title="View action on selected data" placement="top" arrow>
            <Box data-testid="Box-n4qm">
              <RareButton data-testid="RareButton-bnkn"
                variant="outlined"
                onClick={handleClick}
                icon={<KeyboardArrowDownIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: isMobile ? 0 : 1, px: isMobile ? 1 : 2, fontSize: '14px' }}
              >
                {isMobile ? "" : "Bulk Actions"}
              </RareButton>
            </Box>
          </Tooltip>

          {create && (
            <Tooltip data-testid="Tooltip-zb2q" title="Refresh Data" placement="top" arrow>
              <Box data-testid="Box-6sfj">
                <RareButton data-testid="RareButton-z8kd"
                  variant="outlined"
                  onClick={() => handleRefreshButton()}
                  center={true}
                  icon={<RefreshIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                  sx={{ gap: 0, px: isMobile ? 1 : 2 }}
                >
                </RareButton>
              </Box>
            </Tooltip>
          )}
          <Tooltip data-testid="Tooltip-t0n8" title="Clear All Table Filters" placement="top" arrow>
            <Box data-testid="Box-jsup">
              <RareButton data-testid="RareButton-2dua"
                variant="outlined"
                onClick={() => handleRemoveAllFilters()}
                icon={<FilterAltOffOutlinedIcon sx={{ fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }} />}
                sx={{ gap: 0, px: isMobile ? 1 : 2 }}
              >
              </RareButton>
            </Box>
          </Tooltip>
          {(!create && !isMobile) && (
            <Tooltip data-testid="Tooltip-aaee" title="View results in current Dataset" placement="top" arrow>
              <Box data-testid="Box-e914">
                <RareButton data-testid="RareButton-b4w6"
                  variant="contained"
                  icon={<CheckCircleOutlineOutlinedIcon />}
                  disabled={!selectedResults.length} // Disable button if no selected results
                  sx={{
                    // minWidth: "5em",
                    // minHeight: "100%",
                    background: selectedResults.length ? colorConfigs.buttons.success : 'inherit',
                    // '&:hover': {
                    //     background: selectedResults.length 
                    //         ? colorConfigs.buttons.success 
                    //         : 'inherit', 
                    // },
                  }}
                >
                  {rows.length}&nbsp; Results in Dataset
                </RareButton>
              </Box>
            </Tooltip>
          )}
          {!isMobile && (
            <Tooltip data-testid="Tooltip-0c6d" title="View results in current Dataset" placement="top" arrow>
              <Box data-testid="Box-lbqh">
                <RareButton data-testid="RareButton-c99i"
                  variant="success"
                  onClick={() => handleShowAllSelectedResults()}
                  icon={<CheckCircleOutlineOutlinedIcon />}
                  disabled={!selectedResults.length} // Disable button if no selected results
                >
                  {selectedResults.length}&nbsp; Results Selected
                </RareButton>
              </Box>
            </Tooltip>

          )}

          {!isMobile && (
            <Tooltip data-testid="Tooltip-q2w7" title="Remove all results from current Dataset" placement="top" arrow>
              <Box data-testid="Box-j2c8">
                <RareButton data-testid="RareButton-zrda"
                  variant="cancel"
                  onClick={() => handleClearAllSelectedResults()}
                  // icon={<CheckCircleOutlineOutlinedIcon />}
                  disabled={!selectedResults.length} // Disable button if no selected results
                >
                  Deselect All
                </RareButton>
              </Box>
            </Tooltip>
          )}

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {create && (
              <MenuItem data-testid="MenuItem-y4v1" disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              } onClick={() => handleBulkAddToDataset(table.getSelectedRowModel().rows)} disableRipple>
                <Box data-testid="Box-q4tn" sx={{ display: 'flex', alignItems: 'center' }}>
                  <AddCircleOutlineIcon sx={{ fill: (theme) => theme.palette.primary.main }} />
                  <Typography data-testid="Typography-qzo9">Add Selection to Dataset</Typography>
                </Box>
              </MenuItem>
            )}
            {create && (
              <MenuItem data-testid="MenuItem-6ev4" disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              } onClick={() => handleBulkRemoveFromDataset(table.getSelectedRowModel().rows)} disableRipple>
                <Box data-testid="Box-jf1k" sx={{ display: 'flex', alignItems: 'center' }}>
                  <CancelOutlinedIcon sx={{ fill: (theme) => theme.palette.secondary.main }} />
                  <Typography data-testid="Typography-sbuu">Remove Selection from Dataset</Typography>
                </Box>
              </MenuItem>
            )}
            {create && (<Divider sx={{ mx: 1, p: 0 }} />)}
            <MenuItem data-testid="MenuItem-i8uh" disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
              <Box data-testid="Box-x6rt" sx={{ display: 'flex', alignItems: 'center' }}>
                <FileDownloadOutlinedIcon />
                <Typography data-testid="Typography-zfs0">Export to Excel</Typography>
              </Box>
            </MenuItem>
          </StyledMenu>
        </Box>
      );
    }}

    renderRowActions={({ row, table }) => {

      const handleAddToDataset = async (row: MRT_Row<any>) => {
        handleAddSelectedResults([row.original]); // Pass the single row as an array
      };

      const handleRemoveFromDataset = async (row: MRT_Row<any>) => {
        handleRemoveSelectedResults([row.original]); // Pass the single row as an array
      };

      return (
        <Box data-testid="Box-maza" onClick={(e) => e.stopPropagation()} sx={{ gap: '0.5rem', display: 'flex', justifyContent: 'center', cursor: 'default' }}>
          {row.original.selected ?
            (
              <Box data-testid="Box-i0vj">

                <Tooltip data-testid="Tooltip-i6x5" arrow placement="left" title="Remove From Dataset">
                  <IconButton data-testid="IconButton-0gwu"
                    onClick={() => handleRemoveFromDataset(row)}
                    aria-label="remove from dataset"
                    sx={{ cursor: 'pointer', color: colorConfigs.buttons.bgInactiveSolid, '&:hover': { color: colorConfigs.buttons.bgInactiveHoverSolid } }}
                  >
                    <CancelOutlinedIcon fontSize={'large'} sx={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>

                {/* <Tooltip data-testid="Tooltip-mo17" arrow placement="left" title="Result is in this Dataset">
                    <IconButton data-testid="IconButton-o95q"
                       aria-label="result in dataset"
                       disabled
                      sx={{cursor: 'default'}}
                    >
                      <CheckCircleOutlineOutlinedIcon  sx={{color: '#fff', cursor: 'default'}}/>
                    </IconButton>
                   </Tooltip> */}

              </Box>
            ) : !row.original.selected ? (

              <Tooltip data-testid="Tooltip-8u9c" arrow placement="left" title="Add to Dataset">
                <IconButton data-testid="IconButton-ajdr"
                  onClick={() => handleAddToDataset(row)}
                  aria-label="add to dataset"
                  // color="primary"
                  sx={{ cursor: 'pointer', color: colorConfigs.buttons.bgActiveSolid, '&:hover': { color: colorConfigs.buttons.bgActiveHoverSolid } }}
                >
                  <AddCircleOutlineIcon fontSize={'large'} sx={{ cursor: 'pointer' }} />
                </IconButton>
              </Tooltip>

            ) : null}
        </Box>
      );
    }}

  />
}

export default ResultsTable;

