import { Card, Grid, Typography, Button, FormControlLabel, Checkbox, TextField, Box, Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import {  SupportLevelSelectField, TextEditField, TextEditReadOnlyField, UserTypeSelectField } from '../fields/fields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import colorConfigs from '../../../configs/colorConfigs';
import { SubscriptionSessionStorageKey, SubscriptionTableParameterStorageService } from '../../../services/sessionStorage';
import RareButton from '../buttons/RareButton';

interface SubscriptionDetailProps {
  subscription: any;
  create: boolean;
  handleSaveButton: () => void;
  handleBackButton: () => void;
}

const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SubscriptionDetailCard: React.FC<SubscriptionDetailProps> = ({subscription, create, handleSaveButton, handleBackButton}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState<any>({})
    const [updatedValues, setUpdatedValues] = useState<any>({})
    const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false)
    const [isPortalAccess, setIsPortalAccess] = useState(false);
    const [isUnlimitedResults, setIsUnlimitedResults] = useState(false);
    const [isAppAccess, setIsAppAccess] = useState(false);

    const validationSchema = () => {
        return Yup.object().shape({
        });
    }


    const formik = useFormik({
        enableReinitialize: !create,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues:
            (!create && subscription) ? {
                id: subscription.id || '',
                name: subscription.name || '',
                description: subscription.description || '',
                price_per_month: subscription.price_per_month || '',
                tier: subscription.tier || '',
                
                // benefits
                number_of_users: subscription.number_of_users || '',
                portal_access: subscription.portal_access || false,
                unlimited_results: subscription.unlimited_results || false,
                support: subscription.support || '',
                support_level: subscription.support_level || '',
                app_access: subscription.app_access || false,
            }
            : {
                id: '',
                name: '',
                description: '',
                price_per_month: '',
                tier: '',
                
                // benefits
                number_of_users: '',
                portal_access: false,
                unlimited_results: false,
                support: subscription.support || '',
                support_level: subscription.support_level || '',
                app_access: false,
            },
        validationSchema,
        onSubmit: async (values) => {

            const data = authData.isAdmin ? {
                name: subscription.name,
                description: subscription.description,
                // price_per_month: subscription.price_per_month,
                tier: subscription.tier,
                
                // benefits
                number_of_users: subscription.number_of_users,
                portal_access: subscription.portal_access,
                unlimited_results: subscription.unlimited_results,
                support: subscription.support,
                // support_level: subscription.support_level,
                app_access: subscription.app_access,
            } : {
                
            }

            const url = subscription ? `subscription-plans/${subscription.id}/` : `subscription-plans/`
            const method = subscription ? 'patch' : 'post'

            try {
                console.log("data", data)
                await api ({
                    method,
                    url,
                    data,
                    headers: {
                        'Authorization': `Bearer ${authData.access}`,
                    },
                });
                setErrors(null);
                console.log("Subscription successfully updated")
                handleSaveButton() // reset states and close popup
                const count = SubscriptionTableParameterStorageService.get(SubscriptionSessionStorageKey.count)
                SubscriptionTableParameterStorageService.set(SubscriptionSessionStorageKey.count, count + 1) // Updated count to not trigger refresh data button
            } catch(error: any) {
                if (error?.response?.status === 400) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
                } else if (error?.response?.status === 403) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${error.response.data[firstErrorItem]}`);
                } else{
                    const errorText = `Unknown error updating lot`;
                    setErrors(errorText);
                    console.error(errorText, error);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    });

    //  Set checkbox state
    useEffect(() => {
        setIsPortalAccess(formik.values.portal_access)
        setIsUnlimitedResults(formik.values.unlimited_results)
        setIsAppAccess(formik.values.app_access)
    }, [subscription, formik.values.portal_access, formik.values.unlimited_results, formik.values.app_access]);

  

    // Effect to set initial form values from subscription to check for form changes
    useEffect(() => {
        setTimeout(() => {
            setInitialValues(formik.initialValues);
            setUpdatedValues(formik.initialValues);
        }, 500);
        setHasMadeChanges(false);
    }, [subscription]);

    // Update the updatedValues state when formik values change
    useEffect(() => {
        setUpdatedValues(formik.values);
    }, [formik.values]);

    // Function to check if form has changed so the save button becomes active on edit
    const hasFormChanged = (flatModel: Record<string, any>, formValues: Record<string, any>): boolean => {
        for (const key in formValues) {
            if (key === "expiry_date") {
                const formDate = dayjs(formValues[key]).format('YYYY-MM-DD');
                const initialDate = create ? dayjs(flatModel[key]).format('YYYY-MM-DD') : dayjs(formValues[key]).format('YYYY-MM-DD');
                if (formDate !== initialDate) {
                    console.log("Changed Date", formDate, initialDate)
                    return true;
                }
            } else {
                if (formValues[key]!= flatModel[key]) {
                    console.log("Changed Data", formValues[key], flatModel[key])
                    return true;
                }
            }
        }
        return false;
    };
            
    // Use Effect to check if any changes ahve been made to the form on Edit
    useEffect(() => {
        if (!create && subscription) {
            if (Object.keys(initialValues).length > 0 && Object.keys(updatedValues).length > 0) {
                const formChanged = hasFormChanged(initialValues, updatedValues);
                setHasMadeChanges(formChanged);
            }
        }
    }, [updatedValues, initialValues, subscription]);
    
    // Use Effect set save button active on load if Create and if changes are made on Edit
    useEffect(() => {
        if (create) {
            setHasMadeChanges(true);
        } else {
            setHasMadeChanges(false);
        }
    }, [create]);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{overflow: 'auto'}}>
            <Box data-testid="Box-upsd" sx={{ height: "100%", p:2, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {errors && (
                <Alert data-testid="Alert-gjj2" severity="error" style={{ width: "59.5%" }}>
                    <AlertTitle data-testid="AlertTitle-4n7m">Error</AlertTitle>
                    {errors}
                </Alert>
            )}
            <div data-testid="div-6bz9" style={{width: "100%", height: "100%", margin: "auto", padding: "1em", display: "flex", alignItems: "center"}}>
                <Grid container spacing={5} alignItems="top" justifyContent="center" style={{ minWidth: "100%", padding: 5 }}>
                            <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                                <Card data-testid="Card-qe1m" sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                    <div data-testid="div-m5be" className="form-group" style={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <Typography data-testid="Typography-wzqu" variant="h6" gutterBottom sx={{ fontWeight: 'bold'}}>                                            
                                            Subscription
                                        </Typography> 
                                        {!create && <Field data-testid="Field-imkn" name="id" disableds type="text" className="form-control" label="Subscription ID" component={TextEditReadOnlyField}  />}
                                        {!create && <Field data-testid="Field-ox5w" name="date_created" type="text" className="form-control" label="Date Created" component={TextEditReadOnlyField} />}
                                        <Field data-testid="Field-j4g0" name="name" type="text" className="form-control" label="Subscription Name" component={TextEditField} />
                                        </div>
                                </Card>
                            </Grid>
                            {!create && 

                            <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                            <Card data-testid="Card-kn3u" sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                <div data-testid="div-w5zc" className="form-group" style={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Typography data-testid="Typography-337x" variant="h6"  sx={{ fontWeight: 'bold'}} gutterBottom>
                                    Subscription Benefits
                                </Typography> 
                                <FormControlLabel sx={{mt: 0.5}} control={<Checkbox checked={isPortalAccess} name="portal_access"
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: colorConfigs.buttons.active,
                                                },
                                            }}
                                            onChange={(e) => {
                                                setIsPortalAccess(!isPortalAccess)
                                                    formik.handleChange(e);
                                                }} className="form-control" />} 
                                                label={
                                                    <Box data-testid="Box-p9r6" display="flex" alignItems="center">
                                                      Has Portal Access?
                                                      <Typography data-testid="Typography-qlgw" 
                                                        fontSize="smaller" 
                                                        sx={{ 
                                                          paddingX: 0.7, 
                                                          border: isPortalAccess ? `2px solid ${colorConfigs.buttons.active}` : `1px solid ${colorConfigs.buttons.inactive}`, 
                                                          borderRadius: 2, 
                                                          color: isPortalAccess ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                                                          marginLeft: 1
                                                        }}
                                                      >
                                                        {isPortalAccess ? 'Yes' : 'No'}
                                                      </Typography>
                                                    </Box>
                                                  } 
                                                />
                            
                                
                                <FormControlLabel sx={{mt: 0.5}} control={<Checkbox checked={isAppAccess} name="app_access"
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: colorConfigs.buttons.active,
                                                },
                                            }}
                                            onChange={(e) => {
                                                setIsAppAccess(!isAppAccess)
                                                    formik.handleChange(e);
                                                }} className="form-control" />} 
                                                label={
                                                    <Box data-testid="Box-dpfr" display="flex" alignItems="center">
                                                      Has App Access ?
                                                      <Typography data-testid="Typography-8080" 
                                                        fontSize="smaller" 
                                                        sx={{ 
                                                          paddingX: 0.7, 
                                                          border: isAppAccess ? `2px solid ${colorConfigs.buttons.active}` : `1px solid ${colorConfigs.buttons.inactive}`, 
                                                          borderRadius: 2, 
                                                          color: isAppAccess ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                                                          marginLeft: 1
                                                        }}
                                                      >
                                                        {isAppAccess ? 'Yes' : 'No'}
                                                      </Typography>
                                                    </Box>
                                                  } 
                                                />


                                <FormControlLabel sx={{mt: 0.5}} control={<Checkbox checked={isUnlimitedResults} name="unlimited_results"
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: colorConfigs.buttons.active,
                                                },
                                            }}
                                            onChange={(e) => {
                                                    setIsUnlimitedResults(!isUnlimitedResults)
                                                    formik.handleChange(e);
                                                }} className="form-control" />} 
                                                label={
                                                    <Box data-testid="Box-v8qk" display="flex" alignItems="center">
                                                      Has Unlimited Results ?
                                                      <Typography data-testid="Typography-lewq" 
                                                        fontSize="smaller" 
                                                        sx={{ 
                                                          paddingX: 0.7, 
                                                          border: isUnlimitedResults ? `2px solid ${colorConfigs.buttons.active}` : `1px solid ${colorConfigs.buttons.inactive}`, 
                                                          borderRadius: 2, 
                                                          color: isUnlimitedResults ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                                                          marginLeft: 1
                                                        }}
                                                      >
                                                        {isUnlimitedResults ? 'Yes' : 'No'}
                                                      </Typography>
                                                    </Box>
                                                  } 
                                                />
                                <Field data-testid="Field-r1wh" name="number_of_users" type="number" placeholder="unlimited = 999999" className="form-control" label="Number of Users" component={TextEditField} />
                                <Field data-testid="Field-bzoz" name="support" type="text" className="form-control" label="Support Level" component={SupportLevelSelectField} />

                                {/* <Field data-testid="Field-rqx5" name="last_login" type="text" className="form-control" label="Last Login" component={TextEditReadOnlyField} /> */}
                                {/* <Box data-testid="Box-w81e" sx={{display: "flex", justifyContent: "space-between", gap: 2}}>
                                    <Field data-testid="Field-03yv" name="total_active_dogs" type="text" className="form-control" label="Total Active Dogs" component={TextEditReadOnlyField} />
                                    <Field data-testid="Field-qo0y" name="total_dogs" type="text" className="form-control" label="Total Dogs" component={TextEditReadOnlyField} />
                                </Box>
                                <Box data-testid="Box-5hkf" sx={{display: "flex", justifyContent: "space-between", gap: 2}}>
                                    <Field data-testid="Field-63wm" name="total_cycles" type="text" className="form-control" label="Total Cycles" component={TextEditField} />
                                    <Field data-testid="Field-rytz" name="total_results" type="text" className="form-control" label="Total Results" component={TextEditReadOnlyField} />
                                </Box> */}
                                </div>
                                </Card>
                            </Grid>
                                }
                            </Grid>
                    </div>
                    <div data-testid="div-i86i" className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    <RareButton data-testid="RareButton-gzt2"
                            onClick={() => handleBackButton()}
                            variant="outlined"
                            sx={{
                                position: "relative",
                                m: 1,
                            }}
                        >
                            Back
                        </RareButton>
                        <RareButton data-testid="RareButton-cnt6"
                            type="submit"
                            variant="success"
                            disabled={!hasMadeChanges}
                            sx={{
                                position: "relative",
                                m: 1,
                            }}
                        >
                            Save Changes
                        </RareButton>
                    </div>
                </Box>
            </form>
        </FormikProvider>
    )
}

export default SubscriptionDetailCard;