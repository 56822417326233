import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from 'material-react-table'
import React, { useMemo } from 'react'
import colorConfigs from '../../../configs/colorConfigs';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

interface RecentDCMResultsTableProps {
  authData: any;
  rows: any[];
}

//example data type
interface LatestDCM {
  id: string;
  organisation_id: number | null;
  user_id: number | null;
  timestamp: string;
  sample_id: string;
  method_id: number | null;
  housing_id: number | null;
  test_configuration: {
    collection_mode: boolean | null;
  }
}

//nested data is ok, see accessorKeys in ColumnDef below


const RecentDCMResultsTable: React.FC<RecentDCMResultsTableProps> = ({
  authData,
  rows,
}) => {
  const navigate = useNavigate()
  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: 'sample_id', //normal accessorKey
      header: 'Sample ID',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'number_of_frames', //normal accessorKey
      header: 'Frames',
      enableColumnFilter: false,
    },
    // {
    //   accessorKey: 'method_id', //normal accessorKey
    //   header: 'Method',
    // },
    {
      accessorKey: 'timestamp', //access nested data with dot notation
      accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      header: 'Timestamp',
      Cell: ({ cell }) => (
        <div data-testid="div-7em7" style={{ whiteSpace: 'pre-wrap' }}>
          {cell.getValue()?.toString().split(' ').join('\n')}
        </div>
      ),
      enableColumnFilter: false,
    },
  ]

  return (
    <div data-testid="div-sms0" style={{ width: 580 }}>
      <MaterialReactTable
        data={rows}
        columns={columns}
        // state={{
        //   isLoading,
        // }}
        defaultColumn={{
          minSize: 10,
          maxSize: 160,
          size: 10,
        }}
        enableColumnActions={false}
        enableGlobalFilter={false}
        enableGlobalFilterModes={false}
        enableColumnFilterModes={false}
        enableFilters={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableTopToolbar={false}
        enableSorting={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableBottomToolbar={false}
        initialState={{
          density: 'compact',
          // columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions'] },
        }}

        muiTableContainerProps={{
          sx: {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            boxShadow: 0,
            border: 0,
            background: '#fff',
            minHeight: '100%',
            maxHeight: '100%',
            flex: 1,
            overflowY: 'auto',
            paddingBottom: '1px',
            '&::-webkit-scrollbar': { display: 'none' },
            msOverflowStyle: 'none', // Internet Explorer 10+
            scrollbarWidth: 'none', // Firefox
          },
        }}

        // TABLE STYLING IS IN THE THEME
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: "Actions", // Renames Actions Column
          },
        }}

        muiTopToolbarProps={({ table }) => ({
          sx: (theme: any) => ({
            ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
          }),
        })}

        muiTableHeadProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)', // light grey background
            color: colorConfigs.text
          },
        }}

        muiTableHeadCellProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)', // same grey background for header cells
            color: colorConfigs.text,
            '&:first-of-type': {
              maxWidth: '50px',
              width: '50px',
            },
          },
        }}

        muiFilterTextFieldProps={{
          sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
        }}

        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            console.log(row.original);
            navigate(`/results/${row.original.id}`)
          },
          sx: (theme: any) => ({
            ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
            backgroundColor:
              row.index % 2 === 0
                ? colorConfigs.tables.firstRow
                : colorConfigs.tables.secondRow,
          }),
        })}

        muiTableBodyProps={{
          sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
        }}

        muiPaginationProps={{
          rowsPerPageOptions: [250, 500, 1000],
          sx: {
            padding: 0,
            margin: 0,
            '& .MuiTablePagination-root': {
              padding: 0,
              margin: 0,
            },
            '& .MuiToolbar-root': {
              padding: 0,
              margin: 0,
              minHeight: '0px',
            },
            '& .MuiTablePagination-selectLabel': {
              margin: 0,
            },
            '& .MuiTablePagination-displayedRows': {
              margin: 0,
            },
            '& .MuiTablePagination-actions': {
              margin: 0,
            },
          },
        }}

        muiTableBodyCellProps={({ cell }) => ({
          // TABLE BODY CELLS
          sx: {
          },
        })}

        muiBottomToolbarProps={{
          // BOTTOM TOOLBAR (Pagination)
          sx: {
            height: '41px',
            maxHeight: '41px',
            minHeight: '41px',
            position: 'sticky',
            bottom: 0,
            zIndex: 1,
            backgroundColor: '#fff',
            boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
            margin: 0,
            padding: 0,
            '& .MuiToolbar-root': {
              padding: 0,
              margin: 0,
              paddingBottom: 0,
              paddingTop: 0,
            },
            '& .MuiBox-root': {
              p: 0,
            },
            '& .MuiIconButton-root': {
              color: colorConfigs.tables.headBg,
            },
            '& .MuiTablePagination-root': {
              margin: 0,
              paddingTop: 0.5,
              paddingBottom: 0.5,
              color: colorConfigs.buttons.bg,
            },
            '& .MuiTablePagination-selectLabel': {
              color: colorConfigs.tables.headBg,
              padding: 0,
              margin: 0,
            },
            '& .MuiTablePagination-displayedRows': {
              color: colorConfigs.buttons.bg,
              padding: 0,
              margin: 0,
            },
            '& .MuiTablePagination-actions': {
              color: colorConfigs.tables.headBg,
              padding: 0,
              margin: 0,
            },
          },
        }}

      />
    </div>
  )
}

export default RecentDCMResultsTable