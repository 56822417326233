import { AppBar, Box, Button, Divider, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Grid2, IconButton, List, ListItem, ListItemText, Paper, Slide, Table, TableCell, TableRow, Toolbar, Typography, tableCellClasses } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import SignInDialog from '../../components/common/SignInDialog';
import api from '../../redux/features/auth/axiosAuthHelper';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import colorConfigs from '../../configs/colorConfigs';
import { TransitionProps } from '@mui/material/transitions';
import assets from '../../assets';
import RareButton from '../../components/common/buttons/RareButton';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FactoryIcon from '@mui/icons-material/Factory';
import EmailIcon from '@mui/icons-material/Email';
import versionConfig from '../../configs/versionConfig';

type Props = {}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AboutPage = (props: Props) => {
  const version = versionConfig.version
  const manufacturedate = versionConfig.manufactureDate
  const address = "Novarum DX Ltd"
  const address2 = "www.novarumdx.com"

  const authData = useSelector((state: RootState) => state.auth);


  return authData.isAuthenticated == false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
  ) : authData.isAuthenticated == true && authData.isFreeUser ? (
    <Navigate to="/account/subscription" />
  ) : authData.isAuthenticated == true && authData.isFreeUser === false ? (
    <div data-testid="div-ng31" style={{ overflowX: "hidden", maxHeight: `100%`, minHeight: `100%`, maxWidth: `100%`, minWidth: `100%`, margin: "auto" }}>

      <div data-testid="div-vnlc" style={{ display: "flex", height: "100%", width: "100%", maxWidth: `100%`, minWidth: `100%`, }}>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ m: 0, height: "100%", width: "100%", maxWidth: `100%`, minWidth: `100%`, }}
        >
          <Grid item xs={3} sx={{ m: 0, p: 0, maxWidth: `100%`, minWidth: `100%` }}>
            <Box data-testid="Box-vudf" sx={{ m: 0, p: 3, height: "100%", overflowY: "auto", marginRight: 0, display: 'flex', flexDirection: 'column', overflowX: "hidden", maxWidth: `93vw`, minWidth: `100%` }}>

              <h1>About Page</h1>

              <div data-testid="div-gsca" style={{ display: "flex", alignItems: "center", height: "100%" }}>

                <Grid2
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ ml: 15, pl: 15 }}
                >
                  <Grid2 size={{ xs: 12 }}>
                    <Box data-testid="Box-9wck" sx={{ width: '100%', maxWidth: 1500 }}>
                      <Table data-testid="Table-tvbe" sx={{ maxWidth: 800, align: "left", ml: -8, [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }} border-collapse="collapse">
                        <TableRow data-testid="TableRow-pnh2" sx={{ borderBottom: 'none' }}>
                          <TableCell data-testid="TableCell-19z7"><Typography data-testid="Typography-yrqq" sx={{ fontWeight: 1000, color: colorConfigs.dashboard.dark_red }}>Version:</Typography></TableCell>
                          <TableCell data-testid="TableCell-9adx"><Typography data-testid="Typography-vmxo" variant="body1" gutterBottom>{version}</Typography></TableCell>
                        </TableRow>
                      </Table>
                      <Box data-testid="Box-4xpd" sx={{ maxWidth: 600, ml: -6, pt: 2 }}>
                        <Typography data-testid="Typography-nddq" variant="h5" gutterBottom align="left" sx={{ fontWeight: 'bold', color: colorConfigs.dashboard.dark_red }} >
                          Intended purpose
                        </Typography>
                        <Typography data-testid="Typography-iwtn" variant="body1" gutterBottom>
                          The Rapid Assay Result Ecosystem (RARE) is designed to provide a comprehensive solution for analysing lateral flow test
                          results through an integrated platform comprising a mobile app and web portal.
                        </Typography>
                        <Typography data-testid="Typography-by10" variant="body1" gutterBottom>
                          The mobile app captures images of a
                          lateral flow test assay using a smartphone camera and measures the output from the assay to extract peak intensities.
                          Method configurations can be delivered ‘over the air’ from the web service to the mobile app, enabling use with
                          different assay formats.
                        </Typography>
                        <Typography data-testid="Typography-pjm0" variant="body1" gutterBottom sx={{ pt: 3 }}>
                          RARE does not provide interpretations or medical diagnoses but acts as a virtual instrument
                          for research and development purposes. It is intended to be used in a manner akin to a laboratory reflectometer,
                          densitometer, or immunoassay analyser.
                        </Typography>
                        <br />
                      </Box>
                      <Table data-testid="Table-x76z" sx={{ maxWidth: 400, align: "left", ml: -8, [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }}>
                        <TableRow data-testid="TableRow-8nka" sx={{ borderBottom: 'none' }}>
                          <TableCell data-testid="TableCell-rcte"><img data-testid="img-uzza" src={assets.images.ISOManufactureDate} alt="reficon" style={{ aspectRatio: 1 / 1 }} /></TableCell>
                          <TableCell data-testid="TableCell-4jah"><Typography data-testid="Typography-4clu" variant="body1" gutterBottom>{manufacturedate}</Typography></TableCell>
                        </TableRow>
                        <TableRow data-testid="TableRow-q1uy" sx={{ borderBottom: 'none' }}>
                          <TableCell data-testid="TableCell-iq7d"><img data-testid="img-wpyj" src={assets.images.ISOManufacturedBy} alt="reficon" style={{ aspectRatio: 1 / 1, width: "33px" }} /></TableCell>
                          <TableCell data-testid="TableCell-hsl8">
                            <Typography data-testid="Typography-6jxo" variant="body1" gutterBottom>{address}</Typography>
                            <Typography data-testid="Typography-b84n" variant="body1" gutterBottom>{address2}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow data-testid="TableRow-8kcx" sx={{ borderBottom: 'none' }}>
                          <TableCell data-testid="TableCell-cvm0">
                            <RareButton data-testid="RareButton-ntti"
                              variant="outlined"

                            >
                              Terms and Conditions
                            </RareButton>
                          </TableCell>
                          <TableCell data-testid="TableCell-isnq">
                            <RareButton data-testid="RareButton-ltsb"
                              variant="outlined"

                            >
                              Privacy Notice
                            </RareButton>
                          </TableCell>
                        </TableRow>
                      </Table>

                    </Box>
                  </Grid2>
                </Grid2>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  ) : (null);
};
export default AboutPage;

