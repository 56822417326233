import React, { useEffect, useRef, useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { Button, Box, CircularProgress } from "@mui/material";
import {
  addFourthCorner,
  areCornersEqual,
  arraysAreEqual,
  assignCornerLabels,
} from "../../../utils/math";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import api from "../../../redux/features/auth/axiosAuthHelper";
import JoystickIcon from "../icons/JoystickIcon";
import {
  ControlCorrection,
  Corners,
  Direction,
  Point,
} from "../../../types/types";

export interface ImageProfile {
  profile: number[];
  sample_rows: number;
  sample_cols: number;
}

type ImageProfileSetter = React.Dispatch<
  React.SetStateAction<ImageProfile | undefined>
>;

type StripCornersSetter = React.Dispatch<
  React.SetStateAction<Corners | undefined>
>;
type DirectionSetter = React.Dispatch<React.SetStateAction<Direction>>;

interface CornersSelectorCardProps {
  imageUrl: string;
  homography: number[];
  setImageProfile: ImageProfileSetter;
  setImageCorners: StripCornersSetter;
  imageCorners?: Corners;
  setDirection: DirectionSetter;
  direction: Direction;
  controlCorrection?: ControlCorrection;
  controlRegion: [number | undefined, number | undefined];
}

const CornersSelectorCard: React.FC<CornersSelectorCardProps> = ({
  imageUrl,
  homography,
  setImageProfile,
  setImageCorners,
  imageCorners,
  setDirection,
  direction,
  controlCorrection,
  controlRegion,
}) => {
  const authData = useSelector((state: RootState) => state.auth);
  const [corners, setCorners] = useState<Point[]>([]);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  console.log("CornersSelectorCard:", imageUrl, corners, direction);

  // Initialize internal corners
  useEffect(() => {
    if (!imageCorners) {
      console.log("Initialize corners with: []");
      setCorners([]);
    } else if (Object.keys(imageCorners).length === 4) {
      const newCorners = [
        imageCorners["TOP_LEFT"],
        imageCorners["TOP_RIGHT"],
        imageCorners["BOTTOM_LEFT"],
        imageCorners["BOTTOM_RIGHT"],
      ];
      if (!arraysAreEqual(newCorners, corners)) {
        console.log("Initialize corners with:", newCorners);
        setCorners(newCorners);
      }
    }
  }, [imageCorners]);

  // Calculate the fourth corner
  useEffect(() => {
    if (corners.length > 0) {
      console.log("New point:", corners[corners.length - 1]);
      if (corners.length === 3) {
        const newCorners: Point[] = addFourthCorner(corners);
        setCorners(newCorners);
      }
    }
  }, [corners]);

  // Assign the corner labels and set the object
  useEffect(() => {
    if (corners.length === 4) {
      const labeledCorners = assignCornerLabels(corners);
      if (!areCornersEqual(labeledCorners, imageCorners)) {
        setImageCorners(labeledCorners);
      }
    }
  }, [corners]);

  // fetch the profile
  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      const control_region =
        controlRegion[0] && controlRegion[1] ? controlRegion : undefined;
      const data = {
        image_url: imageUrl,
        image_corners: imageCorners,
        homography,
        direction,
        control_correction: controlCorrection,
        control_region,
      };
      try {
        const response = await api.post("calculate-image-profile/", data, {
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });
        setImageProfile(response.data);
      } catch (err: any) {
        console.error("Failed to fetch imageProfile", err);

        let message =
          "An unexpected error occurred while fetching the profile.";
        if (
          err.response &&
          err.response.status === 400 &&
          err.response?.data?.error
        ) {
          message = err.response.data.error;
        }
        setErrorMessage(message);
      } finally {
        setLoading(false);
      }
    };
    if (imageCorners && Object.keys(imageCorners).length === 4) {
      fetchProfile();
    }
  }, [imageCorners, direction, controlCorrection, controlRegion]);

  // Handle click to get corners in data units and mark the point
  const handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
    if (imageRef.current && corners.length < 4) {
      const rect = imageRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setCorners((prevCoords) => [...prevCoords, [x, y]]);
    }
  };

  // Clear all markers
  const clearMarkers = () => {
    setCorners([]);
  };

  return (
    <>
      {imageUrl && (
        <Box
          data-testid="Box-container"
          display="flex"
          justifyContent="center" // Center content inside the parent
          alignItems="center"
          width="100%"
        >
          {/* Container that adjusts to the image and joystick */}
          <Box
            data-testid="Box-k4jt"
            display="flex"
            alignItems="center"
            gap={2} // Adds spacing between image and joystick
          >
            {/* Image container */}
            <Box
              data-testid="Box-45su"
              position="relative"
              display="inline-block"
            >
              <img
                data-testid="img-saga"
                src={imageUrl}
                alt="Select region"
                ref={imageRef}
                onClick={handleImageClick}
                style={{
                  width: "auto",
                  height: "auto",
                  display: "block",
                  // maxWidth: "100%",
                  cursor: "crosshair",
                }}
              />

              {/* Markers */}
              {corners.map((marker, index) => (
                <Box
                  data-testid={`Box-marker-${index}`}
                  key={index}
                  sx={{
                    position: "absolute",
                    left: `${marker[0]}px`,
                    top: `${marker[1]}px`,
                    width: "5px",
                    height: "5px",
                    bgcolor: "red",
                    borderRadius: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              ))}

              {/* Loading Spinner */}
              {loading && (
                <Box
                  data-testid="Box-loading"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="50%"
                  left="50%"
                  sx={{ transform: "translate(-50%, -50%)" }}
                >
                  <CircularProgress
                    data-testid="CircularProgress-kcpz"
                    sx={{ color: "red" }}
                    thickness={6}
                    size={100}
                  />
                </Box>
              )}
            </Box>

            {/* Joystick next to the image */}
            <Box
              data-testid="Box-3pj6"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                gap: 6,
              }}
            >
              <JoystickIcon
                direction={direction}
                onDirectionClick={setDirection}
              />
              {imageUrl && (
                <Button
                  data-testid="Button-0zu4"
                  variant="contained"
                  color="secondary"
                  onClick={clearMarkers}
                  disabled={corners.length === 0}
                >
                  Reset Corners
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
      >
        <Alert severity="error" onClose={() => setErrorMessage(null)}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CornersSelectorCard;
