import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  LinearProgress,
  Tooltip,
  Grid,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { statusLabels, useHousing } from "../CustomHooks";
import SignInDialog from "../SignInDialog";
import ResultSelectorCard from "./ResultSelectorCard";
import TabbedStripsCard from "./TabbedStripsCard";
import NotificationSnackbarCard, {
  parseErrors,
} from "./NotificationSnackbarCard";
import StepperCard from "./StepperCard";
import ReaderConfiguration from "./ReaderConfiguration";
import MethodDescriptionCard from "./MethodDescriptionCard";
import RareButton from "../buttons/RareButton";
import colorConfigs from "../../../configs/colorConfigs";
import { ImageItem, Method, Strip } from "../../../types/types";

type MethodDataSetter = React.Dispatch<
  React.SetStateAction<Method | undefined>
>;


interface MethodCardProps {
  methodId?: number;
  isForDatasetCreation?: boolean;
  housingIDforNonDCADataset?: number;
  createDatarun?: boolean;
  dataset_id?: any;
  handleRefreshButton?: () => void;
  handleBackButton: () => void;
  setMethodId?: (id: number) => void;
  setDatarunMethodStepsComplete?: (bool: boolean) => void;
  setMethodCreationErrors?: (error: string) => void;
  setMethodData?: MethodDataSetter;
  triggerSaveMethod?: boolean
}

const DataRunMethodCard: React.FC<MethodCardProps> = ({
  methodId,
  isForDatasetCreation,
  housingIDforNonDCADataset,
  createDatarun,
  dataset_id,
  handleRefreshButton,
  handleBackButton,
  setMethodId,
  setMethodCreationErrors,
  setDatarunMethodStepsComplete,
  setMethodData,
  triggerSaveMethod

}) => {

  const navigate = useNavigate();

  const [errorRecord, setErrorRecord] = useState<Record<string, string>>({});
  const [errorQueue, setErrorQueue] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const authData = useSelector((state: RootState) => state.auth);
  const { housing, housingError } = useHousing();
  const [selectedImage, setSelectedImage] = useState<ImageItem | null>(null);
  const [strips, setStrips] = useState<Strip[]>([]);
  const [stripsAsRecord, setStripsAsRecord] = useState<Record<number, Strip>>(
    {}
  );
  const [controlLineCorrectionErrors, setControlLineCorrectionErrors] = useState<any>(null);
  const [controlLineCorrectionHasErrors, setControlLineCorrectionHasErrors] = useState<boolean>(false);

  // const create = !methodId
  const create = createDatarun

  const steps = [
    "Name Method",
    "Describe Method",
    "Select Housing",
    "Load Test Image",
    "Configure Strips",
    "Save",
  ];

  const updateStripState = useCallback(
    (strip: Strip) => {
      console.log("Saving strip state: ", strip.position, strip);
      setStripsAsRecord((prev) => ({ ...prev, [strip.position]: strip }));
    },
    [setStripsAsRecord]
  );

  const handleStripError = useCallback(
    (key: string, error?: string) => {
      console.log("Setting error: ", key, error);
      if (error) {
        setErrorRecord((prev) => ({ ...prev, [key]: error }));
      } else {
        setErrorRecord((prevState) => {
          const { [key]: _, ...newState } = prevState; // Exclude the attribute
          return newState;
        });
      }
    },
    [setErrorRecord]
  );

  const handleImageSelected = (selectedImage: ImageItem | null) => {
    setSelectedImage(selectedImage);
    setStrips((prevStrips) =>
      prevStrips.map((strip) => ({
        ...strip,
        image_corners: undefined,
        direction: "RIGHT",
        strip_regions: [],
        profile: [],
      })),
    );
  };

  const getStripsAsArray = (strips: Record<number, Strip>): Strip[] => {
    const orderedStrips = Object.values(strips).sort(
      (a, b) => a.position - b.position
    );
    return orderedStrips;
  };

  const getStripsAsRecord = (strips: Strip[]): Record<number, Strip> => {
    return strips.reduce((acc, strip) => {
      acc[strip.position] = strip;
      return acc;
    }, {} as Record<number, Strip>);
  };

  useEffect(() => {
    const fetchMethodData = async () => {
      if (!methodId) {
        formik.resetForm()
      }
      if (housingIDforNonDCADataset) {
        formik.setFieldValue("housing", housingIDforNonDCADataset);
      }
      if (!authData.isAuthenticated || housing.length === 0 || !methodId || housingIDforNonDCADataset) {
        return;
      }

      try {
        const response = await api.get(`methods/${methodId}`, {
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });

        const data: Method = response.data;
        if (data.status !== "DATARUN") {
          formik.setFieldValue("id", "");
          formik.setFieldValue("test_name", "");
          formik.setFieldValue("description", "");
          formik.setFieldValue("version", "");
          formik.setFieldValue("status", statusLabels["DATARUN"]);
        } else {
          formik.setFieldValue("id", methodId);
          formik.setFieldValue("test_name", data.test_name);
          formik.setFieldValue("description", data.description || "");
          formik.setFieldValue("version", data.version);
          formik.setFieldValue("status", statusLabels[data.status || ""]);
        }

        formik.setFieldValue("housing", data.housing);
        formik.setFieldValue("flash_required", data.flash_required);
        formik.setFieldValue("collection_mode", data.collection_mode);
        formik.setFieldValue("initial_h_factor", data.initial_h_factor);
        formik.setFieldValue(
          "control_line_correction",
          data.control_line_correction
        );
        formik.setFieldValue("profile_alignment", data.profile_alignment);
        formik.setFieldValue("control_exposure", data.control_exposure);
        formik.setFieldValue(
          "profile_control_levels",
          data.profile_control_levels
            ? JSON.stringify(data.profile_control_levels)
            : ""
        );
        formik.setFieldValue(
          "polynomial_baseline_order",
          data.polynomial_baseline_order
        );
        formik.setFieldValue("frames_to_capture", data.frames_to_capture);
        formik.setFieldValue("control_baseline", data.control_baseline);
        formik.setFieldValue(
          "control_baseline_threshold",
          data.control_baseline_threshold
        );
        setSelectedImage(data.strip_image);
        setStrips(data.strips);
        setStripsAsRecord(getStripsAsRecord(data.strips));
      } catch (fetchError: any) {
        if (fetchError.response !== "Request cancelled... refreshing token") {
          console.error("Error fetching method", fetchError);
          setErrorQueue([`No Method found with ID ${methodId}`]);
          setTimeout(() => {
            handleBackButton();
          }, 4000);
        }
      }
    };

    fetchMethodData();
  }, [housing, authData.isAuthenticated, methodId, housingIDforNonDCADataset]);

  const validationSchema = () => {
    return Yup.object().shape({
      test_name: Yup.string().required("This field is required"),
      description: Yup.string().required("This field is required"),
      housing: Yup.string().required("This field is required"),
      profile_control_levels: Yup.string()
        .test(
          "is-valid-integer-array",
          "Profile Control Levels must be a list of integers, e.g., [2, 3]",
          (value) => {
            if (!value) return true; // Optional field
            try {
              const parsed = JSON.parse(value);
              return (
                Array.isArray(parsed) &&
                parsed.length === 2 &&
                parsed.every((num) => Number.isInteger(num))
              );
            } catch {
              return false;
            }
          }
        )
        .optional(),
    });
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      id: "",
      housing: "",
      test_name: "",
      description: "",
      version: 1,
      status: isForDatasetCreation ? statusLabels["DATARUN"] : statusLabels["IN_PROGRESS"],
      flash_required: true,
      collection_mode: false,
      initial_h_factor: 1.5,
      control_line_correction: false,
      profile_alignment: true,
      control_exposure: true,
      minimumProfileControlLevel: 100,
      maximumProfileControlLevel: 250,
      polynomial_baseline_order: 3,
      frames_to_capture: 10,
      control_baseline: true,
      control_baseline_threshold: 1.5, // optional
    },
    validationSchema,
    onSubmit: async (values) => {

      if (Object.keys(errorRecord).length > 0) {
        console.log("Error count:", Object.keys(errorRecord).length);
        const errors = Object.values(errorRecord);
        setErrorQueue(errors);
        return;
      }

      const stripsData = getStripsAsArray(stripsAsRecord);
      let data: Method = {
        housing: values.housing,
        test_name: values.test_name,
        description: values.description,
        flash_required: values.flash_required,
        collection_mode: values.collection_mode,
        frames_to_capture: values.frames_to_capture,
        status: "DATARUN",
        strip_image: null,
        strips: [],
      };
      if (!values.collection_mode) {
        const collectionModeData = {
          initial_h_factor: values.initial_h_factor,
          control_line_correction: values.control_line_correction,
          profile_alignment: values.profile_alignment,
          control_exposure: values.control_exposure,
          profile_control_levels: values.control_exposure
            ? [
              values.minimumProfileControlLevel,
              values.maximumProfileControlLevel,
            ]
            : undefined,
          polynomial_baseline_order: values.polynomial_baseline_order,
          frames_to_capture: values.frames_to_capture,
          control_baseline: values.control_baseline,
          control_baseline_threshold: values.control_baseline
            ? values.control_baseline_threshold
            : undefined,
          strips: stripsData,
          strip_image: selectedImage,
        };
        data = { ...data, ...collectionModeData };
      }
      const update = methodId && !isForDatasetCreation && values.status === statusLabels["IN_PROGRESS"];
      const url = update ? `methods/${methodId}/` : "methods/";
      const method = update ? "patch" : "post";

      console.log("SEND DATA", data);

      setErrorQueue([]);
      setSuccessMessage("");

      try {
        const result = await api({
          method,
          url,
          data,
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });
        if (isForDatasetCreation) {
          setSuccessMessage(
            `Dataset Method successfully ${update ? "updated" : "created"}.`
          );
          if (setMethodId) {
            setMethodId(result.data.id)
          }
          if (setMethodData) {
            setMethodData(result.data as Method)
          }
          if (setMethodCreationErrors) {
            setMethodCreationErrors("")
          }
          console.log(`Dataset Method successfully ${update ? "updated" : "created"}.`);
          // Do not navigate away from page
        } else {
          if (handleRefreshButton) {
            handleRefreshButton();
          }
          setSuccessMessage(
            `Method successfully ${update ? "updated" : "created"}.`
          );
          console.log(`Method successfully ${update ? "updated" : "created"}.`);
        }
      } catch (error: any) {
        if (error?.response?.status === 400) {
          const errors = parseErrors(error.response.data);
          setErrorQueue(errors);
          if (isForDatasetCreation) {
            if (setMethodCreationErrors) {
              setMethodCreationErrors(error)
            }
          }
        } else {
          const errorText = `Unknown error ${update ? "updating" : "creating"} method`;
          console.error(errorText, error.response);
          setErrorQueue([errorText]);
          if (isForDatasetCreation) {
            if (setMethodCreationErrors) {
              setMethodCreationErrors(`${error.response}`)
            }
          }
        }
      }
    },
  });

  useEffect(() => {
    if (!methodId) {
      formik.setFieldValue(
        "frames_to_capture",
        formik.values.collection_mode ? 50 : 10
      );
    }
    if (formik.values.collection_mode) {
      setErrorRecord({});
    }
  }, [formik.values.collection_mode, methodId]);

  useEffect(() => {
    console.log("selectedImage changed:", selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    console.log("strips changed:", stripsAsRecord);
  }, [stripsAsRecord]);

  useEffect(() => {
    if (controlLineCorrectionErrors && formik.values.control_line_correction) {
      setControlLineCorrectionHasErrors(true)
    } else if (!formik.values.control_line_correction) {
      setControlLineCorrectionHasErrors(false)
    } else {
      setControlLineCorrectionHasErrors(false)
    }
  }, [formik.values.control_line_correction, controlLineCorrectionErrors])



  // Function to determine the current active step
  const getActiveStep = () => {
    if (!formik.values.test_name) return 0;
    if (isForDatasetCreation && setDatarunMethodStepsComplete) { setDatarunMethodStepsComplete(false) }
    if (!formik.values.description) return 1;
    if (isForDatasetCreation && setDatarunMethodStepsComplete) { setDatarunMethodStepsComplete(false) }
    if (!formik.values.housing) return 2;
    if (isForDatasetCreation && setDatarunMethodStepsComplete) { setDatarunMethodStepsComplete(false) }
    else if (formik.values.collection_mode) return 5;
    if (isForDatasetCreation && setDatarunMethodStepsComplete) { setDatarunMethodStepsComplete(false) }
    if (Object.keys(stripsAsRecord).length === 0 ||
      Object.values(errorRecord).length) return 3;
    if (isForDatasetCreation && setDatarunMethodStepsComplete) { setDatarunMethodStepsComplete(false) }
    if (Object.keys(stripsAsRecord).length === 0 ||
      Object.values(errorRecord).length || controlLineCorrectionHasErrors) return 4;
    if (isForDatasetCreation && setDatarunMethodStepsComplete) { setDatarunMethodStepsComplete(false) }
    if (isForDatasetCreation && setDatarunMethodStepsComplete) { setDatarunMethodStepsComplete(true) }
    return 5; // All previous steps are complete, move to Save step
  };

  const activeStep = getActiveStep();
  const isSaveStep = activeStep === steps.length - 1;

  const handleFormSubmit = () => {
    formik.submitForm()
  }

  // If Datarun Creation page and dataset_id changes reset values
  useEffect(() => {
    if (dataset_id) {
      formik.resetForm()
      setSelectedImage(null)
    }
  }, [dataset_id])

  // Effect to trigger the form submission when triggerSubmit becomes true
  useEffect(() => {
    if (triggerSaveMethod) {
      formik.submitForm();
    }
  }, [triggerSaveMethod]);

  return authData.isAuthenticated == undefined ? (
    // isAuthenticated is undefined or null
    <Box data-testid="Box-mdsf" sx={{ width: "100%" }}>
      <LinearProgress data-testid="LinearProgress-fqoa" sx={{ zIndex: 1001 }} />
    </Box>
  ) : authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={false} />
  ) : housing.length === 0 ? (
    // clinics not loaded yet
    <Box data-testid="Box-02hu" sx={{ width: "100%" }}>
      <LinearProgress data-testid="LinearProgress-gu73" sx={{ zIndex: 1001 }} />
    </Box>
  ) : authData.isAuthenticated ? (
    <Box data-testid="Box-x0ez" sx={{ display: "flex", flexDirection: "column" }}>


      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <MethodDescriptionCard
            formik={formik}
            housing={housing}
            housingError={housingError}
            isForDatasetCreation={isForDatasetCreation}
            create={create}
          />
          <div data-testid="div-gvjr" style={{ flexGrow: 0, width: "100%" }}>
            <div data-testid="div-i6x2"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                width: "100%",
              }}
            >
              <ReaderConfiguration formik={formik} create={create} />
              {!formik.values.collection_mode ? (
                <div data-testid="div-wb57"
                  style={{
                    flex: 5,
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography sx={{ color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>Assay&nbsp;Configuration</Typography>
                    <Box data-testid="Box-8985" sx={{ width: '100%', display: 'flex', gap: 5 }}>
                      <Box data-testid="Box-b4tu" sx={{ p: 1 }}>
                        <ResultSelectorCard
                          onAccept={handleImageSelected}
                          dataset_id={dataset_id}
                          housing_id={false}
                        />

                      </Box>
                    </Box>
                  </Box>
                  <Box data-testid="Box-qz1i"
                    sx={{
                      // p: 2,
                      ml: 1.5,
                      border: "1px solid",
                      borderColor: "grey.300",
                      borderRadius: 2,
                      mr: 2, // margin-right
                      mb: 3, // margin-bottom
                      boxShadow: 3,
                      display: "flex", // Enable flexbox
                      flexDirection: "column", // Arrange items in a row
                      gap: "1rem", // Space between items
                    }}
                  >
                    <TabbedStripsCard
                      dataset_id={dataset_id}
                      image={selectedImage || undefined}
                      onStateChange={updateStripState}
                      onError={handleStripError}
                      strips={strips}
                      controlLineCorrection={
                        formik.values.control_line_correction
                      }
                      setControlLineCorrectionErrors={setControlLineCorrectionErrors}
                    />
                  </Box>
                </div>
              ) : (
                <FormButtons
                  methodId={methodId}
                  methodStatus={formik.values.status}
                  isForDatasetCreation={isForDatasetCreation}
                  submitForm={() => { }}
                  create={create}
                  isSaveStep={isSaveStep}

                />
              )}
            </div>
          </div>
          {/* Keep the button at the bottom */}
          {!formik.values.collection_mode && (
            <FormButtons
              methodId={methodId}
              methodStatus={formik.values.status}
              isForDatasetCreation={isForDatasetCreation}
              submitForm={handleFormSubmit}
              create={create}
              isSaveStep={isSaveStep}
            />
          )}{" "}
          {/* Adjusted width */}
        </form>
      </FormikProvider>
      {/* </Card> */}
      <NotificationSnackbarCard
        errorQueue={errorQueue}
        successMessage={successMessage}
        setErrorQueue={setErrorQueue}
        setSuccessMessage={setSuccessMessage}
      />
    </Box>
  ) : null;
};

interface FormButtonsProps {
  methodId?: any | null;
  methodStatus: string;
  isForDatasetCreation: any | null;
  submitForm: () => void;
  create?: boolean;
  isSaveStep?: boolean;
}

const FormButtons: React.FC<FormButtonsProps> = ({
  methodId,
  methodStatus,
  isForDatasetCreation,
  submitForm,
  create,
  isSaveStep
}) => {
  return (
    <Box data-testid="Box-smp4"
      sx={{
        display: "flex", // Default to horizontal alignment
        flexDirection: "row", // Ensure buttons are in a row
        justifyContent: "flex-end", // Align buttons to the right
        alignItems: "flex-start", // Align buttons to the top
        width: "100%",
        paddingRight: "20px",
        paddingBottom: "20px",
      }}
    >
      {!create && !isForDatasetCreation &&
        <Tooltip data-testid="Tooltip-zca6" title={!isSaveStep ? "Complete all steps to Save" : ""}>
          <Box data-testid="Box-0xr2">
            <RareButton data-testid="RareButton-e8y1"
              onClick={submitForm}
              variant="success"
              disabled={create && !isSaveStep}
              sx={{
                m: 1, // Spacing between buttons
                color: "white",
              }}
            >
              Save Changes
            </RareButton>
          </Box>
        </Tooltip>
      }
    </Box>
  );
};

export default DataRunMethodCard;
