import React, { useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import api from "../../redux/features/auth/axiosAuthHelper";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import SignInDialog from "../../components/common/SignInDialog";

const filterData = (data: any, filterPaths: string[]) => {
  const pathsWithoutApi = removeApiPrefix(data.paths);
  data.paths = pathsWithoutApi;
  if (filterPaths.length === 0) {
    return data;
  }
  const filteredData: any = Object.fromEntries(
    Object.entries(data).filter(([key]) => key !== "paths")
  );
  const paths: any = Object.fromEntries(
    Object.entries(data.paths).filter(([key]) =>
      filterPaths.some((filterPath) => key.startsWith(filterPath))
    )
  );
  filteredData.paths = paths;
  return filteredData;
};

const removeApiPrefix = (objects: Record<string, any>): Record<string, any> =>
  Object.fromEntries(
    Object.entries(objects).map(([key, value]) => [
      key.replace(/^\/api/, ""),
      value,
    ])
  );

const fetchSwaggerJSON = async (
  urlPath: string,
  accessToken: string | null,
  filterPaths: string[]
) => {
  try {
    const response = await api.get(urlPath, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return filterData(response.data, filterPaths);
  } catch (error) {
    console.error("Error fetching swagger:", error);
    return null;
  }
};

interface SwaggerProps {}

const SwaggerPage: React.FC<SwaggerProps> = ({}) => {
  const [swaggerData, setSwaggerData] = useState(null);
  const authData = useSelector((state: RootState) => state.auth);
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      const djangoData = await fetchSwaggerJSON(
        "swagger.json",
        authData.access,
        []
      );
      if (!djangoData) {
        return;
      }
      const fastapiData = await fetchSwaggerJSON(
        "fastapi-swagger/openapi.json",
        authData.access,
        []
      );
      if (fastapiData) {
        djangoData.paths = { ...djangoData.paths, ...fastapiData.paths };
        setSwaggerData(djangoData);
      }
    };
    fetchData();
  }, [authData.isAuthenticated]);

  useEffect(() => {
    // Simulate fetching a token or getting it from localStorage
    if (authData.access) {
      // Replace with actual token logic
      setToken(authData.access);
    }
  }, [authData.isAuthenticated]);

  // Plugin to hide models section
  const HideModelsPlugin = () => ({
    wrapComponents: {
      Models: () => () => null,
    },
  });

  return authData.isAuthenticated == undefined ? (
    // isAuthenticated is undefined or null
    <Box data-testid="Box-36jz" sx={{ width: "100%" }}>
      <LinearProgress data-testid="LinearProgress-prpd" />
    </Box>
  ) : authData.isAuthenticated === false ? (
    // isAuthenticated is false
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
  ) : !swaggerData || !token ? (
    // From here we know that isAuthenticated is true
    <Box data-testid="Box-w1vy" sx={{ width: "100%" }}>
      <LinearProgress data-testid="LinearProgress-bj0c" />
    </Box>
  ) : authData.isAdmin ? (
    <>
      <Box p={10} paddingX={10} alignItems="center">
        <Typography
          data-testid="Typography-usiu"
          variant="h6"
          sx={{
            textAlign: "center",
            minWidth: "100%",
            pt: 1,
            paddingLeft: 1.5,
            paddingRight: 3,
            margin: "auto",
            borderRadius: 1,
          }}
        >
          API Documentation
        </Typography>
        <Box
          data-testid="Box-k0n2"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <SwaggerUI
            spec={swaggerData}
            plugins={[HideModelsPlugin]}
            requestInterceptor={(req) => {
              // Add Authorization header
              if (token) {
                req.headers["Authorization"] = `Bearer ${token}`;
              }
              return req;
            }}
            onComplete={(system: any) => {
              // Show closed lock
              if (token) {
                system.authActions.authorize({
                  BearerAuth: {
                    name: "Authorization",
                    schema: {
                      type: "apiKey",
                      in: "header",
                      name: "Authorization",
                    },
                    value: `Bearer ${token}`,
                  },
                });
              }
            }}
          />
        </Box>
      </Box>
    </>
  ) : null;
};

export default SwaggerPage;
