import { AppBar, Backdrop, Dialog, DialogContent, IconButton, Slide, SpeedDial, SpeedDialAction, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import { TransitionProps } from "@mui/material/transitions";
import EditProfileCard from "../cards/EditProfileCard";
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from "../../../configs/colorConfigs";
import sizeConfigs from "../../../configs/sizeConfigs";
import fontConfigs from "../../../configs/fontConfigs";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileButton = () => {
  const authData = useSelector((state: RootState) => state.auth);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [popup, setPopup] = useState<any>("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleBackButton = () => { setOpen(false); setPopup("") };


  const actions = [
    { icon: <LogoutIcon />, name: 'Logout' },
    { icon: <AccountBoxIcon />, name: 'Profile' },
    { icon: <Typography data-testid="Typography-m33m" sx={{ display: "flex", justifyContent: "baseline", alignItems: "center", width: "fit-contents" }}>{authData?.user?.first_name} {authData?.user?.last_name}</Typography>, name: 'Me' },
  ];

  return (
    <>
      {/* Adjust the Backdrop to cover the entire viewport */}
      <Backdrop data-testid="Backdrop-xiaa"
        open={open}
        onClick={handleClose}
        sx={{
          zIndex: 10,           // Ensure it appears above other content
          position: 'fixed',     // Fixed positioning to cover the entire viewport
          top: 0,
          left: 0,
          width: '100vw',        // Full width
          height: '100vh',       // Full height
          backgroundColor: 'rgba(0, 0, 0, 0.5)',  // Slight transparency for the backdrop effect
        }}
      />
      <SpeedDial data-testid="SpeedDial-nawz"
        ariaLabel="SpeedDial tooltip"
        sx={{
          position: 'fixed',
          top: 8,
          right: 12, // Adjust this value to keep it exactly in the same place
          zIndex: 11,  // Ensure the SpeedDial is above the backdrop
          '& .MuiFab-primary': {
            width: '50px',
            height: '50px',
            boxShadow: 4,
            borderRadius: 6,
            opacity: 0.9
          },
          '& .MuiSpeedDial-actions': {
            '& .MuiSpeedDialAction-fab': {
            }
          }
        }}
        icon={<AccountBoxIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction='left'
      >
        {actions.map((action, index) => (
          <SpeedDialAction
            data-testid={`Action-${action.name}-${index}`}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement="bottom"
            FabProps={{
              sx: {
                width: action.name === "Me" ? "auto" : "40px", // Auto width for "Me" and fixed width for others
                minWidth: action.name === "Me" ? "fit-content" : "40px", // Ensure minimum width fits content
                maxWidth: "200px", // Optional: Set a maximum width to handle very long names
                padding: action.name === "Me" ? "0 12px" : "0", // Add padding for dynamic width
                borderRadius: 2,
                justifyContent: action.name === "Me" ? 'flex-start' : 'center', // Align content properly
              },
            }}
            onClick={() => {
              if (action.name === 'Profile' || action.name === 'Me') {
                setPopup('Edit')
                handleClose();
              } else if (action.name === 'Logout') {
                dispatch(logout());
                navigate('/login');
                handleClose();
              }
            }}
          />
        ))}
      </SpeedDial>

      <Dialog data-testid="Dialog-6ab6"
        open={(popup === "Edit")}
        fullScreen
        onClose={() => { setPopup("") }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        sx={{
          height: '100vh',
          minHeight: '100vh',
          '& .MuiDialog-paper': {
            alignItems: 'center',
            justifyContent: 'center',
            p: 0,
            height: '100vh',
            minHeight: '100vh'
          }
        }}
      >
        <AppBar
          onClick={() => { setPopup(""); }}
          sx={{
            position: 'fixed',
            top: 0,
            height: sizeConfigs.detailPage.appbar.height,
            minHeight: sizeConfigs.detailPage.appbar.height,
            maxHeight: sizeConfigs.detailPage.appbar.height,
            zIndex: 1201,
          }}
        >
          <Toolbar
            sx={{
              height: sizeConfigs.detailPage.appbar.height,
              minHeight: sizeConfigs.detailPage.appbar.height,
              maxHeight: sizeConfigs.detailPage.appbar.height,
              padding: 0,
              backgroundColor: colorConfigs.tables.headBg,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
            }}
          >
            {popup &&
              <Typography data-testid="Typography-sktp"
                onClick={(e) => e.stopPropagation()}
                sx={{
                  color: '#fff',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: fontConfigs.detailPage.appbar.title,
                  fontWeight: "bold",
                  m: "auto",
                  mt: 0,
                  mb: 0,
                }}
              >
                Edit Profile
              </Typography>
            }
            <IconButton data-testid="IconButton-nxxw"
              edge="start"
              color="inherit"
              onClick={() => { setPopup("") }}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                padding: 0,
                fontSize: '18px',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent data-testid="DialogContent-bzvi"
          sx={{
            height: sizeConfigs.detailPage.dialogContent.height,
            minHeight: sizeConfigs.detailPage.dialogContent.height,
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
            msOverflowStyle: 'none', // Internet Explorer 10+
            scrollbarWidth: 'none', // Firefox
            minWidth: '100vw',
            p: 0,
            m: 0,
            backgroundColor: colorConfigs.sidebar.bg,
            mt: sizeConfigs.detailPage.appbar.height,
          }}
        >
          {(popup) &&
            <EditProfileCard setPopup={setPopup} handleBackButton={handleBackButton} />
          }
        </DialogContent>
      </Dialog>

    </>
  );
};

export default ProfileButton;
