import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Paper, Card } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from '../../../configs/colorConfigs';
import RareButton from '../buttons/RareButton';

interface SubscriptionCardProps {
  subscriptions: any[],
  myTierIndex: any,
  selectedUpgrade: any,
  handleUpgradeSelection: (tier: any) => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({subscriptions, myTierIndex, handleUpgradeSelection, selectedUpgrade}) => {

  return (
    <Card data-testid="Card-cava" variant="outlined"   
    sx={{ 
      width: '100%',
      height: '100%',
      boxShadow: 2
    }}>
      <Box data-testid="Box-blle" sx={{display: 'flex'}}>
        <Box data-testid="Box-j46i" sx={{height: '100%', background: colorConfigs.tables.headBg, display: 'flex', alignItems: 'center', borderBottomRightRadius: 3}}>
        <Typography data-testid="Typography-xn33" sx={{px: 2, py: 0.5, color: '#fff', fontWeight: 'bold', fontSize: '20px' }}>Upgrade Subscription</Typography>
        </Box>
    </Box>
    <TableContainer sx={{p: 0, m:0, height: '100%'}}>
      <Table data-testid="Table-qc2b" sx={{height: '100%'}}>
        <TableHead  sx={{height: '17%'}}>
          <TableRow data-testid="TableRow-hqwi">
            <TableCell data-testid="TableCell-qukh" sx={{background: 'inherit'}}></TableCell>
            {subscriptions.map((subscription) => (
              <TableCell data-testid="TableCell-usz1"
                key={subscription.id}
                align="center"
                sx={{
                  color: '#000',
                  fontSize: {sm: '14px', md: '16px', lg: '18px'},
                  fontWeight: 'bold',
                  background: subscription.backgroundColor,
                  border: `3px solid ${subscription.backgroundColor}`
                }}
              >
                {subscription.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody data-testid="TableBody-kxr4" sx={{height: '85%', minHeight: '85%', maxHeight: '85%'}}>
          <TableRow data-testid="TableRow-hyuk">
            <TableCell data-testid="TableCell-8z0k" sx={{ fontSize: {sm: '10px', md: '12px', lg: '14px'}, fontWeight: 'bold', height: "75px", minWidth: "100px" }}>Access to the App</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell data-testid="TableCell-nqqv" key={subscription.id} align="center" sx={{background: subscription.backgroundColor}}>
                {subscription.app_access ? (
                  <CheckIcon sx={{ color: colorConfigs.buttons.green, fontSize: 28 }} />
                ) : (
                  <CloseIcon sx={{ color: colorConfigs.buttons.red, fontSize: 28 }} />
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow data-testid="TableRow-pyvj">
            <TableCell data-testid="TableCell-fcla" sx={{ fontSize: {sm: '10px', md: '12px', lg: '14px'}, fontWeight: 'bold', height: "75px" }}>Access to the Portal</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell data-testid="TableCell-qjwe" key={subscription.id} align="center" sx={{background: subscription.backgroundColor}}>
                {subscription.portal_access ? (
                  <CheckIcon sx={{ color: colorConfigs.buttons.green, fontSize: 28 }} />
                ) : (
                  <CloseIcon sx={{ color: colorConfigs.buttons.red, fontSize: 28 }} />
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow data-testid="TableRow-b7yu">
            <TableCell data-testid="TableCell-faay" sx={{ fontSize: {sm: '10px', md: '12px', lg: '14px'}, fontWeight: 'bold', height: "75px" }}>Number of users</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell data-testid="TableCell-rcm5" key={subscription.id} align="center" sx={{background: subscription.backgroundColor}}>
                <Typography data-testid="Typography-f4rh" variant="body2" fontWeight="bold">
                  {subscription.number_of_users === 999999 ? 'Unlimited' : subscription.number_of_users}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          <TableRow data-testid="TableRow-1c1x">
            <TableCell data-testid="TableCell-gsdq" sx={{ fontSize: {sm: '10px', md: '12px', lg: '14px'}, fontWeight: 'bold', height: "75px" }}>Unlimited results</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell data-testid="TableCell-d3zb" key={subscription.id} align="center" sx={{background: subscription.backgroundColor}}>
                {subscription.unlimited_results ? (
                  <CheckIcon sx={{ color: colorConfigs.buttons.green, fontSize: 28 }} />
                ) : (
                  <CloseIcon sx={{ color: colorConfigs.buttons.red, fontSize: 28 }} />
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow data-testid="TableRow-rwa5">
            <TableCell data-testid="TableCell-lkv7" sx={{ fontSize: {sm: '10px', md: '12px', lg: '14px'}, fontWeight: 'bold', height: "75px" }}>
              Support Level
            </TableCell>
            {subscriptions.map((subscription) => (
              <TableCell data-testid="TableCell-i1ai" key={subscription.id} align="center" sx={{background: subscription.backgroundColor}}>
                <Typography data-testid="Typography-wldh" variant="body2" fontWeight="bold">
                  {subscription.support_level === "Limited Support" ? "Enhanced Support" : subscription.support_level}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          <TableRow data-testid="TableRow-ofpt">
            <TableCell data-testid="TableCell-qh7n" sx={{ fontWeight: 'bold', height: "65px",borderBottom: 'none' }}></TableCell>
            {subscriptions.map((subscription, index) => {
              return (
              <TableCell data-testid="TableCell-fiqw" key={subscription.id} align="center" sx={{borderBottom: 'none', background: subscription.backgroundColor}}>
                {subscription.my_tier ?
                <RareButton data-testid="RareButton-mq1c" 
                  onClick={() => handleUpgradeSelection(subscription.tier)}
                  variant="outlined" sx={{textTransform: 'none', height: "65px", width: "135px", fontWeight: 'bold'}}>
                  {subscription.freeTier ? `Current Plan £0 / month` : `Current Plan £${subscription.price_per_month} / month`}
                </RareButton>
                :
                <RareButton data-testid="RareButton-8ftu" 
                  onClick={() => handleUpgradeSelection(subscription.tier)}
                  center={true}
                  variant={subscription.upgrade ? "success" : "outlined"} sx={{textTransform: 'none', height: "65px", width: "135px", fontWeight: 'bold'}}>
                  {subscription.freeTier ? '£0 / month' : `${subscription.upgrade ? 'Upgrade' : ''} £${subscription.price_per_month} / month`}
                </RareButton>
                }
              </TableCell>
            )})}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </Card>
  );
};

export default SubscriptionCard;
