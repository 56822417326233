import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  TextField,
  MenuItem,
  Box,
  Card,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Field, Form, Formik, FormikErrors, useFormikContext } from "formik";

import { TextEditField } from "../fields/fields";
import RareButton from "../buttons/RareButton";
import colorConfigs from "../../../configs/colorConfigs";
import { ControlCorrection } from "../../../types/types";

// A component that listens to Formik errors and logs when they change.
const ValidationErrorListener: React.FC<{ setErrors: (errors: FormikErrors<ControlCorrection> | null) => void }> = ({ setErrors }) => {
  const { errors } = useFormikContext<ControlCorrection>();
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      setErrors(null);
    }
  }, [errors, setErrors]);
  return null;
};

// A component that displays error alerts from Formik context.
const ErrorAlerts: React.FC = () => {
  const { errors } = useFormikContext<ControlCorrection>();
  // List only specific fields or all if you like.
  const errorFields: (keyof ControlCorrection)[] = ["search_method", "search_max"];
  return (
    <>
      {errorFields.map(
        (field) =>
          errors[field] && (
            <Alert key={field} severity="error" style={{ marginBottom: "10px" }}>
              {errors[field]}
            </Alert>
          )
      )}
    </>
  );
};
interface CustomDialogProps {
  disabled?: boolean;
  setData: (value: ControlCorrection | undefined) => void;
  data: ControlCorrection | undefined;
  defaultSearchMin?: number;
  defaultSearchMax?: number;
  sampleCols?: number;
  setControlLineCorrectionErrors?: (errors: FormikErrors<ControlCorrection> | null) => void;
}

const ControlCorrectionPopup: React.FC<CustomDialogProps> = ({
  disabled = false,
  data,
  setData,
  defaultSearchMin,
  defaultSearchMax,
  sampleCols,
  setControlLineCorrectionErrors, // receive the setter function
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);


  let initialValues = data as ControlCorrection;

  const validationSchema = () =>
    Yup.object({ // THESE ARE THE VALIDATION ERRORS I NEED TO TRACK 
      extension: Yup.number().required("Extension is Required"),
      trim: Yup.number().required("Trim is Required"),
      div_profile: Yup.number().required("Div Profile is Required"),
      step_search: Yup.number().required("Step Search is Required"),
      search_min: Yup.number()
        .transform((value, originalValue) =>
          originalValue === "" ? undefined : value
        )
        .required("Search Min is Required, Ensure you have selected your control line region"),
      search_max: Yup.number()
        .transform((value, originalValue) =>
          originalValue === "" ? undefined : value
        )
        .required("Search Max is Required, Ensure you have selected your control line region"),

      min_fit_percentage: Yup.number().required("Min Fit Percentage is Required"),
      search_method: Yup.string().required("Search Method is Required"),
    })
      .test(
        "search-range",
        "Search Max must be greater than Search Min.",
        function ({ search_min, search_max }) {
          if (search_min >= search_max) {
            return new Yup.ValidationError(
              "Search Max must be greater than Search Min.",
              null,
              "search_max"
            );
          }
          return true;
        }
      )
      .test(
        "validate-control-correction",
        "Please, increment either the Div Profile, Trim, or the search width.",
        (values) => {
          if (values && sampleCols !== undefined) {
            const { extension, trim, div_profile, search_min, search_max } = values;
            const searchRange = search_max - search_min;

            if (searchRange <= 0) {
              console.log(`=== Validation Test Debug Info ===
        Search range is non-positive (search_max - search_min): ${searchRange}. Skipping further checks.`);
              return true;
            }

            // Calculate adjusted sample columns value
            const adjustedSampleCols = sampleCols + 2 * extension;
            // Subtract the impact of trim
            const finalAdjustedValue = adjustedSampleCols - 2 * trim;
            // Compute lenSearch using the div_profile
            const lenSearch = finalAdjustedValue / div_profile;

            // Consolidated log output
            console.log(`=== Validation Test Debug Info ===
        Profile Length (sampleCols): ${sampleCols}
        Extension: ${extension}
        Trim: ${trim}
        Div Profile: ${div_profile}
        Search Min: ${search_min}
        Search Max: ${search_max}

        Computed Search Range (search_max - search_min): ${searchRange}
        Adjusted Sample Columns (sampleCols + 2 * extension): ${adjustedSampleCols}
        Final Adjusted Value (Adjusted Sample Columns - 2 * trim): ${finalAdjustedValue}
        LenSearch computed (Final Adjusted Value ${finalAdjustedValue} / ${div_profile} div_profile): ${lenSearch} 
      `);

            if (lenSearch >= searchRange) {
              console.log(`Validation Failed: lenSearch ${lenSearch} is greater than or equal to search range ${searchRange}.`);
              return new Yup.ValidationError(
                "Please, increment either the Div Profile, Trim, or the search width.",
                null,
                "search_method"
              );
            }
            console.log(`Validation Passed: lenSearch ${lenSearch} is less than search range ${searchRange}.`);
          }
          return true;
        }
      )


  return (
    // Wrap the entire component in Formik so that its context is always available
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        search_min: initialValues?.search_min ?? defaultSearchMin,
        search_max: initialValues?.search_max ?? defaultSearchMax,
      }}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={(values: ControlCorrection) => {
        setData(values);
        closeDialog();
      }}
    >
      {({ errors, isValid }) => (
        <>

          {/* Trigger area */}
          <Box
            sx={{
              width: "100%",
              // height: "100px",
              m: 2,
              mt: 0,
              pl: 0.5,
              pb: 0.5,
              boxShadow: 3,
              borderRadius: 1,
            }}
          >
            <Typography
              sx={{
                p: 0,
                pt: 0.5,
                pl: 0.5,
                m: 0,
                color: colorConfigs.primary,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Control&nbsp;Line&nbsp;Corrections
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", p: 2, px: 2.5, gap: 2 }}>
              <RareButton
                data-testid="RareButton-gnn9"
                disabled={disabled}
                variant="contained"
                onClick={openDialog}
                sx={{}}
              >
                Control Correction
              </RareButton>
            </Box>
            {/* Error alerts above the button, always visible */}
            <Box sx={{ pl: 2, pr: 2 }}>
              {setControlLineCorrectionErrors && <ValidationErrorListener setErrors={setControlLineCorrectionErrors} />}
              <ErrorAlerts />
            </Box>
          </Box>

          {/* Dialog containing the Form fields */}
          <Dialog
            data-testid="Dialog-vbrm"
            open={isOpen}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                closeDialog();
              }
            }}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle data-testid="DialogTitle-p7lh">Control Correction</DialogTitle>
            <Form>
              <DialogContent data-testid="DialogContent-s38j">
                {/* Yellow warning box */}
                <Alert
                  data-testid="Alert-7y7d"
                  severity="warning"
                  style={{ marginBottom: "16px" }}
                >
                  This is an advanced feature recommended for use by Novarum DX
                </Alert>
                {/* You can also display errors inside the dialog */}
                <ErrorAlerts />
                <div data-testid="div-pznc" style={{ flexGrow: 0, width: "100%" }}>
                  <Card
                    data-testid="Card-btvj"
                    sx={{
                      py: 3,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "1rem",
                      width: "100%",
                      boxShadow: 10,
                      height: "100%",
                    }}
                  >
                    <Box
                      data-testid="Box-fskp"
                      sx={{
                        p: 3,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                      }}
                    >
                      {/* Extension field */}
                      <Field
                        data-testid="Field-0xfi"
                        name="extension"
                        type="number"
                        className="form-control"
                        label="Extension"
                        component={TextEditField}
                        required
                      />
                      <Field
                        data-testid="Field-spij"
                        name="trim"
                        type="number"
                        className="form-control"
                        label="Trim"
                        component={TextEditField}
                        required
                      />
                      <Field
                        data-testid="Field-5khc"
                        name="div_profile"
                        type="number"
                        className="form-control"
                        label="Div Profile"
                        component={TextEditField}
                        required
                      />
                      <Field
                        data-testid="Field-zhvd"
                        name="step_search"
                        type="number"
                        className="form-control"
                        label="Step Search"
                        component={TextEditField}
                        required
                      />
                    </Box>
                    <Box
                      data-testid="Box-vlq3"
                      sx={{
                        p: 2,
                        pt: 3,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                      }}
                    >
                      <Field
                        data-testid="Field-u5xp"
                        name="search_min"
                        type="number"
                        className="form-control"
                        label="Search Min"
                        component={TextEditField}
                        required
                      />
                      <Field
                        data-testid="Field-nf7i"
                        name="search_max"
                        type="number"
                        className="form-control"
                        label="Search Max"
                        component={TextEditField}
                        required
                      />
                      <Field
                        data-testid="Field-6dtg"
                        name="min_fit_percentage"
                        type="number"
                        className="form-control"
                        label="Minimum Fit Percentage"
                        component={TextEditField}
                        required
                      />
                      <TextField
                        data-testid="TextField-1yib"
                        fullWidth
                        select
                        label="Search Method"
                        margin="normal"
                        name="search_method"
                        value={data?.search_method || "window"}
                        required
                        sx={{
                          m: 0,
                          fontSize: "16px",
                          "& Mui.select": {
                            padding: 1,
                            paddingX: 2,
                            p: 0,
                          },
                        }}
                        InputProps={{
                          sx: {
                            fontSize: "16px",
                            "& input": {
                              padding: 1,
                              paddingX: 2,
                            },
                            "& .MuiOutlinedInput-input": {
                              fontSize: "14px",
                              padding: 1,
                              paddingX: 2,
                            },
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "16px",
                            color: colorConfigs.text,
                          },
                          shrink: true,
                        }}
                      >
                        <MenuItem data-testid="MenuItem-w0ot" key={0} value="window">
                          Window
                        </MenuItem>
                      </TextField>
                    </Box>
                  </Card>
                </div>
              </DialogContent>
              <DialogActions data-testid="DialogActions-hzpx">
                <RareButton
                  data-testid="RareButton-q6u8"
                  onClick={closeDialog}
                  sx={{ margin: "10px" }}
                  variant="outlined"
                >
                  Cancel
                </RareButton>
                <RareButton
                  data-testid="RareButton-ufda"
                  type="submit"
                  variant="success"
                  disabled={!isValid}
                  sx={{ margin: "10px" }}
                >
                  Confirm
                </RareButton>
              </DialogActions>
            </Form>
          </Dialog>
        </>
      )}
    </Formik>
  );
};

export default ControlCorrectionPopup;
