import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { Box } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';

interface ResultDeviceData {
  [key: string]: number
}

interface ResultSourceRadarGraphProps {
  data: ResultDeviceData
}

const ResultSourceRadarGraph: React.FC<ResultSourceRadarGraphProps> = ({ data }) => {
  // Separate out the reprocessed data (case-insensitive match)
  const reprocessedKey = Object.keys(data).find(
    (key) => key.toLowerCase() === 'reproccessed'
  )
  const reprocessedValue = reprocessedKey ? data[reprocessedKey] : 0

  // Get all other device keys and sort them alphabetically
  const deviceKeys = Object.keys(data)
    .filter((key) => key.toLowerCase() !== 'reproccessed')
    .sort((a, b) => a.localeCompare(b))

  // Build the final categories: device names then "Reprocessed"
  const categories = [...deviceKeys, 'Reprocessed']

  // Build the series data: counts for each device, then the reprocessed count
  const seriesData = [
    ...deviceKeys.map((key) => data[key]),
    reprocessedValue,
  ]

  const series = [
    {
      name: 'Results',
      data: seriesData,
    },
  ]

  const options: ApexOptions = {
    chart: {
      type: 'radar',
      height: 350,
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#7a150d'],
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        style: {
          // Provide a color for each label. If there are more categories,
          // you may want to expand this array.
          colors: categories.map(() => '#000'),
          fontSize: '14px',
          fontFamily: 'Arial',
          fontWeight: 'Bold',
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return `${val}`
        },
      },
    },
  }

  return (
    <Box data-testid="Box-yqe1" sx={{}}>
      <div data-testid="div-fkpz" id="chart">
        <ReactApexChart
          type="radar"
          options={options}
          series={series}
          height={options.chart?.height}
        />
      </div>
      <div data-testid="div-46vm" id="html-dist"></div>
    </Box>
  )
}

export default ResultSourceRadarGraph