import { Badge, ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../../configs/colorConfigs";
import { RootState } from "../../../redux/store";
import { RouteType } from "../../../routes/config";
import checkRoutePermissions from "../../../routes/appRoutePermissions";


type Props = {
  item: RouteType;
  handleDrawerClose: () => void;
};

const MobileMenuItem = ({ item, handleDrawerClose }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState);
  const authData = useSelector((state: RootState) => state.auth);
  const permission = checkRoutePermissions(authData, item);

  if (!permission) return null; // Do not render if the route is not visible

  return (
    item.sidebarProps && item.path ? (
      <ListItemButton data-testid="ListItemButton-xd9b"
        component={Link}
        onClick={handleDrawerClose}
        to={permission !== "Premium" && permission !== "Coming Soon" ? item.path : '#'} // Disable link for Premium routes
        disableRipple
        selected={appState === item.state}
        sx={{
          "&:hover": {
            color: permission === "Premium" || permission === "Coming Soon" ? 'grey' : colorConfigs.sidebar.activeBg,
            cursor: permission === "Premium" || permission === "Coming Soon" ? 'not-allowed' : 'pointer',
            backgroundColor: colorConfigs.sidebar.bg,
            // Apply hover effect to the icon inside ListItemButton
            "& .MuiListItemIcon-root": {
              color: permission === "Premium" || permission === "Coming Soon" ? "grey" : colorConfigs.sidebar.activeBg,
            },
          },
          "&.Mui-selected": {
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.activeBg,
            "&:hover": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:focus": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:active": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
          },
          "&.Mui-selected.Mui-focusVisible": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:active": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:focus": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          color: permission === "Premium" || permission === "Coming Soon" ? 'grey' : appState === item.state ? colorConfigs.sidebar.activeBg : item.state === "logout" ? colorConfigs.buttons.cancel : colorConfigs.sidebar.color,
          opacity: permission === "Premium" || permission === "Coming Soon" ? 0.65 : 1,
          position: 'relative', // Required for Badge
          py: 0.2,
        }}
      >
        <ListItemIcon data-testid="ListItemIcon-0d0y" sx={{
          minWidth: "unset",
          marginLeft: "7px",
          p: 0,
          marginRight: "20px",
          justifyContent: "center",
          transition: "margin-right 0.3s",
          color: permission === "Premium" || permission === "Coming Soon" ? 'grey' : appState === item.state ? colorConfigs.sidebar.activeBg : item.state === "logout" ? colorConfigs.buttons.cancel : colorConfigs.sidebar.color,  // Keep the displayText color as is
          "&:hover": {
            color: permission === "Premium" || permission === "Coming Soon"
              ? 'grey'
              : colorConfigs.sidebar.activeBg,
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected": {
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.activeBg,
            "&:hover": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:focus": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:active": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
          },
          "&.Mui-selected.Mui-focusVisible": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:active": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:focus": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
        }}>
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        <>
          <Badge data-testid="Badge-xo7p"
            sx={{
              '& .MuiBadge-badge': {
                color: 'unset',
                backgroundColor: 'transparent',
                borderRadius: '12px',
                fontWeight: 'bold',
                paddingX: 0.5,
                paddingY: 0,
                fontSize: '0.5rem',
                height: "55%",
                right: '5px',
                top: '-1.5px',
                cursor: 'pointer',
              },
              color: permission === "Premium" || permission === "Coming Soon" ? 'grey' : appState === item.state ? 'unset' : "unset",
            }}
            badgeContent={
              permission === "Premium" || permission === "Coming Soon" ? (
                <Link
                  to="/account/subscription"
                  style={{ textDecoration: 'none', color: '#111', padding: 0, margin: 0 }}
                >
                  {permission}
                </Link>
              ) : null
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {item.sidebarProps.displayText}
          </Badge>
        </>
      </ListItemButton>
    ) : null
  );
};

export default MobileMenuItem;