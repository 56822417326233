import { Box, Card, Collapse, Dialog, DialogContent } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface LineData {
  score: number | string;
  peak_position: number | string;
  tc_ratio: number | string;
  t_color?: string; // Color for each T-peak line
}

interface GraphData {
  test_lines: LineData[];        // Data for test lines with T-peaks
  cscore: number | string;       // Score for the C-line
  cpeak: number | string;        // Peak position for the C-line
  c_color?: string;               // Color for the C-peak annotation
  profile: any[];                // Mean profile data
  baseline: any[];               // Mean baseline data
  baseline_color: string;        // Color for the mean baseline series
  profile_color: string;         // Color for the mean profile series
  meanProfileVisible: boolean;   // Whether the mean profile/baseline is visible
  baselines: any[];           // Array of frame data (each frame's baseline)
  profiles: any[];         // Array of frame data (each frame's profile)
}


const ResultDetailGraph: React.FC<{ graphdata: GraphData, showGraph: any }> = ({ graphdata, showGraph }) => {
  const [apexChartData, setApexChartData] = useState<any[]>([]);
  const [annotations, setAnnotations] = useState<ApexOptions['annotations']>({
    xaxis: []
  });
  const [graphText, setGraphText] = useState<string>("Choose Data to Display");
  const [openGraphDialog, setOpenGraphDialog] = useState<boolean>(false);

  useEffect(() => {
    // Aggregate all series data (mean profile, mean baseline, and dynamic profiles/baselines)
    const allSeries: any[] = [
      ...graphdata.baselines.map((baseline, index) => ({
        ...baseline,
        tooltip: { enabled: false }, // Enable tooltip only for the first 2 series
      })),
      ...graphdata.profiles.map((profile, index) => ({
        ...profile,
        tooltip: { enabled: false }, // Enable tooltip only for the first 2 series
      }))
    ];

    setApexChartData(allSeries);

    // If No data then still display the graph
    // if (allSeries.length === 0) {
    //   setApexChartData([{ name: "No Data", data: [] }]);
    // }

    // Define annotations for C-Peak and T-Peaks
    const dynamicAnnotations: ApexOptions['annotations'] = { xaxis: [] };

    // Add C-Peak if mean profile is active
    if (graphdata.meanProfileVisible && graphdata.cpeak) {
      const intensity = typeof graphdata.cscore === "number"
        ? graphdata.cscore.toFixed(2)
        : parseFloat(graphdata.cscore).toFixed(2);
      dynamicAnnotations.xaxis!.push({
        x: graphdata.cpeak,
        borderColor: graphdata.c_color ? graphdata.c_color : "#000",
        label: {
          borderColor: graphdata.c_color ? graphdata.c_color : "#000",
          orientation: "vertical",
          style: {
            color: "#ffffff",
            fontWeight: "bold",
            fontSize: openGraphDialog ? "14px" : "10px",
            background: graphdata.c_color ? graphdata.c_color : "#000"
          },
          text: `${intensity}\u00A0\u00A0\u00A0Control`
        }
      });
    }

    // Add T-Peaks for each test line if mean profile is active
    if (graphdata.meanProfileVisible) {
      graphdata.test_lines.forEach((line, index) => {
        const intensity = typeof line.score === "number"
          ? line.score.toFixed(2)
          : parseFloat(line.score).toFixed(2);
        dynamicAnnotations.xaxis!.push({
          x: line.peak_position,
          borderColor: line.t_color ? line.t_color : "#000",
          label: {
            borderColor: line.t_color ? line.t_color : "#000",
            orientation: "vertical",
            style: {
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: openGraphDialog ? "14px" : "10px",
              background: line.t_color ? line.t_color : "#000"
            },
            text: `${intensity}\u00A0\u00A0\u00A0Line ${index + 1}`
          }
        });
      });
    }

    setAnnotations(dynamicAnnotations);
  }, [graphdata]);

  const tooltipOptions = {
    x: {
      formatter: (value: number, { series, dataPointIndex }: { series: unknown[]; dataPointIndex: number }) => {
        let comparisonIndex;
        const meanBaseline = (series[0] as number[])[dataPointIndex];

        if (meanBaseline === undefined) return "Intensity N/A";

        if (series.length === 2) {
          const meanProfile = (series[1] as number[])[dataPointIndex];
          if (meanProfile === undefined) return "Intensity N/A";
          const intensity = (meanBaseline - meanProfile).toFixed(2); // Fix to 2 decimal places
          const label = openGraphDialog ? `${value} (Intensity ${intensity})` : `${value} (${intensity})`;
          return label;
        } else {
          comparisonIndex = (series.length / 2) - 1;
          const comparisonValue = (series[comparisonIndex * 2 + 1] as number[])[dataPointIndex];
          if (comparisonValue === undefined) return "Intensity N/A";
          const intensity = (meanBaseline - comparisonValue).toFixed(2); // Fix to 2 decimal places
          const label = openGraphDialog ? `${value} (Intensity ${intensity})` : `${value} (${intensity})`;
          return label;
        }
      },
    },
    y: openGraphDialog ? {
      formatter: (value: number) => value.toFixed(2), // Fix to 2 decimal places
      title: {
        formatter: (seriesName: string) => seriesName,
      },
    } : {
      formatter: (value: number) => value.toFixed(2), // Fix to 2 decimal places
      title: {
        formatter: (seriesName: string) => '',
      },
    }
  };


  const chartData: ApexOptions = {
    noData: {
      text: graphText,
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "16px"
      }
    },
    tooltip: {
      shared: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        // Calculate intensity using the baseline (series[0]) and profile (series[1]) values
        const meanBaseline = series[0][dataPointIndex];
        const meanProfile = series[1][dataPointIndex];
        const intensity = meanBaseline !== undefined && meanProfile !== undefined
          ? (meanBaseline - meanProfile).toFixed(2)
          : "N/A";

        const value = series[seriesIndex][dataPointIndex];
        return `<div data-testid="div-zbrp" style="padding: 5px;">
                <strong>${w.globals.seriesNames[seriesIndex]}:</strong> ${value.toFixed(2)}
                <br><strong>Height:</strong> ${intensity}
              </div>`;
      },
    },
    annotations: annotations,
    colors: apexChartData.map(series => series.color),
    stroke: {
      width: 2,
      curve: "smooth"
    },
    chart: {
      id: "dynamic-area",
      type: "line",
      toolbar: {
        show: true,
        tools: {
          download: true
        }
      },
      zoom: {
        enabled: true,
        type: 'xy',
        // autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1
          }
        }
      },
      animations: {
        enabled: false // Disable line animation
      }
    },
    xaxis: {
      type: "numeric",
      title: {
        text: "Profile"
      }
    },
    yaxis: {
      min: 0,
      max: 255,
      title: {
        text: "Intensity"
      },
      labels: {
        formatter: function (value) {
          return Math.round(value).toString(); // Round to whole number and convert to string
        }
      }
    },
    legend: {
      show: false // Disable the legend
    },
    series: apexChartData
  };

  const handleDialogOpen = () => setOpenGraphDialog(true);
  const handleDialogClose = () => setOpenGraphDialog(false);

  return apexChartData.length > 0 ? (
    <>
      <Box data-testid="Box-ckjg"
        onClick={handleDialogOpen}
        sx={{
          width: "100%",
          // p: 1,
          height: "auto",
          position: "relative"
        }}
      >
        <Collapse in={showGraph && apexChartData.length > 0}>

          <Card data-testid="Card-47i6"
            sx={{
              border: "1px #ddd solid",
              borderRadius: 1,
              width: "100%",
              p: 2,
              pb: 0,
              position: "relative"
            }}
          >
            <ReactApexChart
              type="line"
              options={chartData}
              series={chartData.series}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
            />
          </Card>
        </Collapse>
      </Box>
      <Dialog data-testid="Dialog-5q6u"
        open={openGraphDialog}
        onClose={handleDialogClose}
        maxWidth="lg"
        fullWidth
        slotProps={{ backdrop: { onClick: handleDialogClose } }}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "transparent", // Semi-transparent background
            boxShadow: "none",
          },
        }}
      >
        <DialogContent data-testid="DialogContent-pmwv"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
          onClick={handleDialogClose}
        >
          <Box data-testid="Box-0z20"
            onClick={(e) => e.stopPropagation()} // Prevents click from closing the dialog when clicking on the graph
            sx={{
              width: "70%",
              p: 1,
              height: "auto",
              position: "relative",
              cursor: "default",
            }}
          >
            <Card data-testid="Card-jd2h"
              sx={{
                border: "1px #ddd solid",
                borderRadius: 1,
                width: "100%",
                pt: 5,
                pb: 4,
                paddingX: 7,
                position: "relative"
              }}
            >
              <ReactApexChart
                type="line"
                options={chartData}
                series={chartData.series}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
            </Card>
          </Box>
        </DialogContent>
      </Dialog>


    </>
  ) : null
};

export default ResultDetailGraph;